import { IntroSectionText, IUserRoleText } from '../../types/user';

export const startUpSectionContent = {
  founder: {
    mainHeroHeadline: 'Your one-stop shop for Founders',
    mainHeroDescription:
      'Whether you’re just getting started or already have revenue, Astronomic is your operating system for building every stage of your startup, from concept to revenue to acquisition.',
    blueBannerText: 'Startups as a Service',
    whiteBannerText: 'Hire us to build your entire startup for you, from idea to revenue to exit.',
    rocketLabel: 'Your startup',
    moonLabel: 'Exit',
    belowLaunchButtonTitle: 'Startups as a Service',
    belowLaunchButtonDescription: 'Hire us to build your entire startup for you, from idea to revenue to exit.',
    aboveLaunchScreenLabel: 'Startups as a Service',
    aboveLaunchScreenHeadline: "We'll build your startup for you",
    aboveLaunchScreenParagraph: 'Hire us to build your startup for you, from idea to revenue to exit.',
  },
  'business-owner': {
    mainHeroHeadline: 'Your one-stop shop for Business Owners',
    mainHeroDescription:
      'Whether you’re just getting started or already have revenue, Astronomic is your operating system for building every stage of your business, from concept to revenue to exit.',
    blueBannerText: 'Business as a Service',
    whiteBannerText: 'Hire us to build your business for you, from idea to revenue to exit.',
    rocketLabel: 'Your business',
    moonLabel: 'Exit',
    belowLaunchButtonTitle: 'Business as a Service',
    belowLaunchButtonDescription: 'Hire us to build your business for you, from idea to revenue to exit.',
    aboveLaunchScreenLabel: 'Business as a Service',
    aboveLaunchScreenHeadline: "We'll build your business for you",
    aboveLaunchScreenParagraph: 'Hire us to build your business for you, from idea to revenue to exit.',
  },
  'private-investor': {
    mainHeroHeadline: 'Your one-stop shop for Investors',
    mainHeroDescription:
      'Whether you’re just getting started or already have revenue, Astronomic is your operating system for building every stage of your fund, from deal sourcing to exit.',
    blueBannerText: 'Investing as a Service',
    whiteBannerText: 'Hire us to build your fund for you, from deal sourcing to exit.',
    rocketLabel: 'Your investments',
    moonLabel: 'Great returns',
    belowLaunchButtonTitle: 'Investing as a Service',
    belowLaunchButtonDescription: 'Hire us to build your fund for you, from deal sourcing to exit.',
    aboveLaunchScreenLabel: 'Investing as a Service',
    aboveLaunchScreenHeadline: "We'll build your fund for you",
    aboveLaunchScreenParagraph: 'Hire us to build your fund for you, from deal sourcing to exit.',
  },
  freelancer: {
    mainHeroHeadline: 'Your one-stop shop for Freelancers',
    mainHeroDescription:
      'Whether you’re just getting started or already have revenue, Astronomic is your operating system for expanding your freelance practice.',
    blueBannerText: 'Freelancing as a Service',
    whiteBannerText: 'Hire us to improve and expand your freelance offerings.',
    rocketLabel: 'Your services',
    moonLabel: 'Your goals',
    belowLaunchButtonTitle: 'Freelancing as a Service',
    belowLaunchButtonDescription: 'Hire us to improve and expand your freelance offerings.',
    aboveLaunchScreenLabel: 'Freelancing as a Service',
    aboveLaunchScreenHeadline: "We'll build your freelance practice for you",
    aboveLaunchScreenParagraph: 'Hire us to improve and expand your freelance offerings.',
  },
  consultant: {
    mainHeroHeadline: 'Your one-stop shop for Consultants',
    mainHeroDescription:
      'Whether you’re just getting started or already have revenue, Astronomic is your operating system for expanding your consulting practice.',
    blueBannerText: 'Consulting as a Service',
    whiteBannerText: 'Hire us to improve and expand your consulting offerings.',
    rocketLabel: 'Your services',
    moonLabel: 'Your goals',
    belowLaunchButtonTitle: 'Consulting as a Service',
    belowLaunchButtonDescription: 'Hire us to improve and expand your consulting offerings.',
    aboveLaunchScreenLabel: 'Consulting as a Service',
    aboveLaunchScreenHeadline: "We'll build your consultancy for you",
    aboveLaunchScreenParagraph: 'Hire us to improve and expand your consulting offerings.',
  },
  agency: {
    mainHeroHeadline: 'Your one-stop shop for Agencies',
    mainHeroDescription:
      'Whether you’re just getting started or already have clients, Astronomic is your operating system for building every stage of your agency, from branding to operations to team members to clients.',
    blueBannerText: 'Agencies as a Service',
    whiteBannerText: 'Hire us to improve and expand your agency operations, from clients to services to delegation.',
    rocketLabel: 'Your services',
    moonLabel: 'Your goals',
    belowLaunchButtonTitle: 'Agencies as a Service',
    belowLaunchButtonDescription:
      'Hire us to improve and expand your agency operations, from clients to services to delegation.',
    aboveLaunchScreenLabel: 'Agencies as a Service',
    aboveLaunchScreenHeadline: "We'll build your agency for you",
    aboveLaunchScreenParagraph:
      'Hire us to improve and expand your agency operations, from clients to services to delegation.',
  },
  incubator: {
    mainHeroHeadline: 'Your one-stop shop for Incubators',
    mainHeroDescription:
      'Whether you’re just getting started or already have a portfolio, Astronomic is your operating system for building every stage of your incubator, from branding to operations to program participants to mentors.',
    blueBannerText: 'Incubation as a Service',
    whiteBannerText:
      'Hire us to build and grow your incubator for you, from startup sourcing to mentor sourcing to operations.',
    rocketLabel: 'Your incubator',
    moonLabel: 'Your goals',
    belowLaunchButtonTitle: 'Incubation as a Service',
    belowLaunchButtonDescription:
      'Hire us to build and grow your incubator for you, from startup sourcing to mentor sourcing to operations.',
    aboveLaunchScreenLabel: 'Incubation as a Service',
    aboveLaunchScreenHeadline: "We'll build your incubator for you",
    aboveLaunchScreenParagraph:
      'Hire us to build and grow your incubator for you, from startup sourcing to mentor sourcing to operations.',
  },
  accelerator: {
    mainHeroHeadline: 'Your one-stop shop for Accelerators',
    mainHeroDescription:
      'Whether you’re just getting started or already have a portfolio, Astronomic is your operating system for building every stage of your accelerator from branding to operations to program participants to LPs.',
    blueBannerText: 'Acceleration as a Service',
    whiteBannerText:
      'Hire us to build and grow your accelerator for you, from startup sourcing to LP sourcing to operations.',
    rocketLabel: 'Your accelerator',
    moonLabel: 'Great returns',
    belowLaunchButtonTitle: 'Acceleration as a Service',
    belowLaunchButtonDescription:
      'Hire us to build and grow your accelerator for you, from startup sourcing to LP sourcing to operations.',
    aboveLaunchScreenLabel: 'Acceleration as a Service',
    aboveLaunchScreenHeadline: "We'll build your accelerator for you",
    aboveLaunchScreenParagraph:
      'Hire us to build and grow your accelerator for you, from startup sourcing to LP sourcing to operations.',
  },
  'venture-studio': {
    mainHeroHeadline: 'Your one-stop shop for Venture Studios',
    mainHeroDescription:
      'Whether you’re just getting started or already have a portfolio, Astronomic is your operating system for building every stage of your venture studio, from branding to operations to deal flow to LPs.',
    blueBannerText: 'Venture Studios as a Service',
    whiteBannerText:
      'Hire us to build and grow your venture studio for you, from startup sourcing to LP sourcing to operations.',
    rocketLabel: 'Your venture studio',
    moonLabel: 'Great returns',
    belowLaunchButtonTitle: 'Venture Studios as a Service',
    belowLaunchButtonDescription:
      'Hire us to build and grow your venture studio for you, from startup sourcing to LP sourcing to operations.',
    aboveLaunchScreenLabel: 'Venture Studios as a Service',
    aboveLaunchScreenHeadline: "We'll build your venture studio for you",
    aboveLaunchScreenParagraph:
      'Hire us to build and grow your venture studio for you, from startup sourcing to LP sourcing to operations.',
  },
  'coworking-space': {
    mainHeroHeadline: 'Your one-stop shop for Coworking Spaces',
    mainHeroDescription:
      'Whether you’re just getting started or already have members, Astronomic is your operating system for building every stage of your coworking space, from branding to operations to members to mentors.',
    blueBannerText: 'Coworking Spaces as a Service',
    whiteBannerText:
      'Hire us to build and grow your coworking space for you, from tenant sourcing to services to operations.',
    rocketLabel: 'Your coworking space',
    moonLabel: 'Your goals',
    belowLaunchButtonTitle: 'Coworking Spaces as a Service',
    belowLaunchButtonDescription:
      'Hire us to build and grow your coworking space for you, from tenant sourcing to services to operations.',
    aboveLaunchScreenLabel: 'Coworking Spaces as a Service',
    aboveLaunchScreenHeadline: "We'll build your coworking space for you",
    aboveLaunchScreenParagraph:
      'Hire us to build and grow your coworking space for you, from tenant sourcing to services to operations.',
  },
  employee: {
    mainHeroHeadline: 'Your one-stop shop for Companies',
    mainHeroDescription:
      'Whether you’re just getting started or already have revenue, Astronomic is your operating system for building every stage of your startup, from services to operations to growth.',
    blueBannerText: 'Business as a Service',
    whiteBannerText: 'Hire us to enable your business success, from services to operations to growth.',
    rocketLabel: 'Your company',
    moonLabel: 'Your goals',
    belowLaunchButtonTitle: 'Business as a Service',
    belowLaunchButtonDescription: 'Hire us to enable your business success, from services to operations to growth.',
    aboveLaunchScreenLabel: 'Business as a Service',
    aboveLaunchScreenHeadline: "We'll help you grow your business",
    aboveLaunchScreenParagraph: 'Hire us to enable your business success, from services to operations to growth.',
  },
  fund: {
    mainHeroHeadline: 'Fund Management as a Service',
    mainHeroDescription: 'Hire us to build your fund for you, from deal sourcing to LP sourcing to operations.',
    blueBannerText: 'Business as a Service',
    whiteBannerText: 'Hire us to enable your business success, from services to operations to growth.',
    rocketLabel: 'Your fund',
    moonLabel: 'Great returns',
    belowLaunchButtonTitle: 'Fund Management as a Service',
    belowLaunchButtonDescription:
      'Hire us to build your fund for you, from deal sourcing to LP sourcing to operations.',
    aboveLaunchScreenLabel: 'Fund Management as a Service',
    aboveLaunchScreenHeadline: "We'll build your fund for you",
    aboveLaunchScreenParagraph: 'Hire us to build your fund for you, from deal sourcing to LP sourcing to operations.',
  },
};
