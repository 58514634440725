import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import { ReactComponent as AstronomicLogo } from '../../asset/images/astronomic_logo_2.svg';
import styles from './CreateAccountNameAndRoles.module.scss';
import classNames from 'classnames';
import MainButton from '../../components/buttons/MainButton/MainButton';
import Input from '../../components/inputs/Input/Input';
import CheckboxTiledList, { CheckboxData } from '../../components/inputs/CheckboxTiledList/CheckboxTiledList';
import { useNavigate } from 'react-router-dom';
import InputMessage from '../../components/texts/InputMessage/InputMessage';
import api from '../../api';
import { ApiResponseDTO } from '../../dto/api';
import { AxiosError } from 'axios';
import { hasOnlyEnglishCharacters, isLinkedInURL, isPostalCode } from '../../utils/regex';
import PlacesAutocompleteInput from '../../components/inputs/PlacesAutocompleteInput/PlacesAutocompleteInput';
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from '../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../enums/localStorageKeys';
import RoutesEnum from '../../enums/routes';
import BackgroundSectionTemplate from '../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import { getArticle } from '../../utils/stringFormatMethods';
import callToast from '../../components/blocks/ToastMessage/_parts/callToast/callToast';

interface CreateAccountNameAndRolesProps {}

const CreateAccountNameAndRoles: FunctionComponent<CreateAccountNameAndRolesProps> = () => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState<Array<any>>([]);

  useEffect(() => {
    if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP) !== '4') {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }
  }, []);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Auth.getRoles();

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          const formattedRoles = responseData.data.map((item) => {
            return { label: `I'm ${getArticle(item.roleName)} ${item.roleName}`, disabled: false, value: item.roleId };
          });

          setRoles(formattedRoles);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          alert(errorData.errorMessage);
        }

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [city, setCity] = useState('');
  const [locationId, setLocationId] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [linkedInErrorMessage, setLinkedInErrorMessage] = useState('');
  const [cityErrorMessage, setCityErrorMessage] = useState('');
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);

  const [checkboxFilterData, setCheckboxFilterData] = useState<string[]>([]);
  const [checkboxFilterDataErrorMessage, setCheckboxFilterDataErrorMessage] = useState('');
  const [checkboxFilterDataId, setCheckboxFilterDataId] = useState<string[]>([]);

  const handleFirstNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNameErrorMessage('');
    setFirstNameError(false);
    setFirstName(e.target.value);
  };

  const handleLastNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastNameErrorMessage('');
    setLastNameError(false);
    setLastName(e.target.value);
  };

  const handleLinkedInOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkedInErrorMessage('');
    setLinkedInError(false);
    setLinkedIn(e.target.value);
  };

  const handleCityOnChange = (value, locationId, error, errorMessage) => {
    // console.log('AUTOPLACES response: ', value, locationId, error, errorMessage);
    setCity(value);
    setLocationId(locationId);
    setCityError(error);
    setCityErrorMessage(errorMessage);
  };

  const handlePostalCodeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostalCodeErrorMessage('');
    setPostalCodeError(false);
    setPostalCode(e.target.value);
  };

  const handleRoleOnChange = (data) => {
    setCheckboxFilterData(data);
    setCheckboxFilterDataErrorMessage('');
    setCheckboxFilterDataId(data);
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let formIsValidated = true;

    setFirstNameErrorMessage('');
    setLastNameErrorMessage('');
    setLinkedInErrorMessage('');
    setPostalCodeErrorMessage('');
    setCheckboxFilterDataErrorMessage('');
    setFirstNameError(false);
    setLastNameError(false);
    setLinkedInError(false);
    setPostalCodeError(false);

    if (firstName === '') {
      setFirstNameErrorMessage('Enter your first name');
      setFirstNameError(true);
      formIsValidated = false;
    } else if (firstName.length >= 20) {
      setFirstNameErrorMessage('Maximum 20 characters');
      setFirstNameError(true);
      formIsValidated = false;
    } else if (firstName.length <= 1) {
      setFirstNameErrorMessage('Minimum 2 characters');
      setFirstNameError(true);
      formIsValidated = false;
    } else if (!hasOnlyEnglishCharacters(firstName)) {
      setFirstNameErrorMessage('Should contain only english characters');
      setFirstNameError(true);
      formIsValidated = false;
    }

    if (lastName === '') {
      setLastNameErrorMessage('Enter your last name');
      setLastNameError(true);
      formIsValidated = false;
    } else if (lastName.length >= 20) {
      setLastNameErrorMessage('Maximum 20 characters');
      setLastNameError(true);
      formIsValidated = false;
    } else if (lastName.length <= 1) {
      setLastNameErrorMessage('Minimum 2 characters');
      setLastNameError(true);
      formIsValidated = false;
    } else if (!hasOnlyEnglishCharacters(lastName)) {
      setLastNameErrorMessage('Should contain only english characters');
      setLastNameError(true);
      formIsValidated = false;
    }

    if (!isLinkedInURL(linkedIn) && linkedIn !== '') {
      setLinkedInErrorMessage('Please, enter correct LinkedIn URL');
      setLinkedInError(true);
      formIsValidated = false;
    }

    if (postalCode === '') {
      setPostalCodeErrorMessage('Enter your postal code');
      setPostalCodeError(true);
      formIsValidated = false;
    } else if (!isPostalCode(postalCode)) {
      setPostalCodeErrorMessage('Enter valid postal code');
      setPostalCodeError(true);
      formIsValidated = false;
    }

    if (city === '') {
      setCityErrorMessage('Please, select your city');
      setCityError(true);
      formIsValidated = false;
    }

    if (checkboxFilterData.length === 0) {
      setCheckboxFilterDataErrorMessage('Select your role');
      formIsValidated = false;
    }

    // API LOGIC
    if (formIsValidated === true) {
      try {
        const response = await api.Auth.setUserNameAndLastname({
          userId: localStorage.getItem('userId'),
          firstName: firstName,
          lastName: lastName,
          city: city,
          zipCode: postalCode,
          locationId: locationId ? locationId : null,
          linkedIn: linkedIn ? linkedIn : '',
        });

        const responseData = response.data as ApiResponseDTO;

        if (responseData.success === true && responseData.data) {
          try {
            const response = await api.Auth.setUserRoles({
              userId: localStorage.getItem('userId'),
              roles: checkboxFilterDataId,
            });

            const responseData = response.data as ApiResponseDTO;

            if (responseData.success === true && responseData.data) {
              localStorageSetItem(LOCAL_STORAGE_KEYS.USER_FIRST_NAME, firstName);
              navigate(`/${RoutesEnum.MANIFESTO}`);
            }
          } catch (error) {
            navigate(`/${RoutesEnum.WELCOME}`);
          } finally {
            localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP);
            // localStorage.removeItem('userSignUpStep');
          }
        } else {
          console.error('Error:', response.error);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          callToast({
            title: 'Validation error',
            error: error,
            children: <>{errorData?.errorMessage}</>,
          });
        }

        console.error('Error: ', error);
      }
    }
    // API LOGIC END
  };

  // const prepearForOnboarding = () => {
  //   if (checkboxFilterData.length > 0) {
  //     const tempRoleData = checkboxFilterData.map((role) => ({
  //       role,
  //       completed: false,
  //       current: false,
  //     }));

  //     sessionStorage.setItem('completeProfileRoles', JSON.stringify(tempRoleData));
  //     navigate('/complete-profile');
  //   }
  // };

  return (
    <section className={styles['section']}>
      <BackgroundSectionTemplate />

      <div className="wrapper type-1">
        <div className={styles['content']}>
          <div className={styles['logo']}>
            <AstronomicLogo />
          </div>

          <p className={classNames(styles['title'], 'text text--h1')}>Tell us about yourself</p>

          <form className={classNames(styles['form'])} onSubmit={handleOnSubmit}>
            <div className={classNames(styles['form__part'], styles['form__part-name'])}>
              <div className={styles['form__part__left']}>
                <div className={styles['form__part__title']}>
                  <h3 className="text text--h3">What's your name?</h3>
                </div>
              </div>

              <div className={styles['form__part__right']}>
                <div className={classNames(styles['form__part__input-layout'], 'input-layout')}>
                  <div className="input-layout__row input-layout__row--two-col">
                    <div className="input-layout__row__col">
                      <Input
                        label="First Name"
                        placeholder="John"
                        size="large"
                        value={firstName}
                        error={firstNameError}
                        onChange={handleFirstNameOnChange}
                      />

                      {firstNameErrorMessage && <InputMessage>{firstNameErrorMessage}</InputMessage>}
                    </div>

                    <div className="input-layout__row__col">
                      <Input
                        label="Last Name"
                        placeholder="Doe"
                        size="large"
                        value={lastName}
                        error={lastNameError}
                        onChange={handleLastNameOnChange}
                      />

                      {lastNameErrorMessage && <InputMessage>{lastNameErrorMessage}</InputMessage>}
                    </div>
                  </div>

                  <div className="input-layout__row">
                    <div className="input-layout__row__col">
                      <Input
                        label="Your LinkedIn"
                        placeholder="LinkedIn URL"
                        size="large"
                        value={linkedIn}
                        error={linkedInError}
                        onChange={handleLinkedInOnChange}
                      />

                      <p className={classNames(styles['input-description'], 'text text--caption')}>
                        We don’t have public user profiles yet, so until we do, we’ll link your Linkedin when connecting
                        you with others.
                      </p>

                      {linkedInErrorMessage && <InputMessage>{linkedInErrorMessage}</InputMessage>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames(styles['form__part'], styles['form__part-location'])}>
              <div className={styles['form__part__left']}>
                <div className={styles['form__part__title']}>
                  <h3 className="text text--h3">Which city are you in?</h3>
                </div>

                <div className={styles['form__part__text']}>
                  <p className="text text--body-2">We host events and make local connections.</p>
                </div>
              </div>

              <div className={styles['form__part__right']}>
                <div className={classNames(styles['form__part__input-layout'], 'input-layout')}>
                  <div className="input-layout__row input-layout__row--two-col">
                    <div className="input-layout__row__col">
                      <PlacesAutocompleteInput
                        label="City"
                        placeholder="e.g. Austin"
                        size="large"
                        error={cityError}
                        onChange={handleCityOnChange}
                      />

                      <p className={classNames(styles['input-description'], 'text text--caption')}>
                        Type and select your city.
                      </p>

                      {cityErrorMessage && <InputMessage>{cityErrorMessage}</InputMessage>}
                    </div>

                    <div className="input-layout__row__col" style={{ width: '188px' }}>
                      <Input
                        label="Your Postal Code"
                        placeholder="e.g 78701"
                        size="large"
                        value={postalCode}
                        error={postalCodeError}
                        onChange={handlePostalCodeOnChange}
                      />

                      {postalCodeErrorMessage && <InputMessage>{postalCodeErrorMessage}</InputMessage>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames(styles['form__part'], styles['form__part-role'])}>
              <div className={styles['form__part__left']}>
                <div className={styles['form__part__title']}>
                  <h3 className="text text--h3">How do you identify?</h3>
                </div>

                <div className={styles['form__part__text']}>
                  <p className="text text--body-2">You can select as many roles as you identify to.</p>
                </div>
              </div>

              <div className={styles['form__part__right']}>
                <div className={styles['form__part__role-list']}>
                  <CheckboxTiledList data={roles} name={'role'} onChange={handleRoleOnChange} />
                </div>

                {checkboxFilterDataErrorMessage && <InputMessage>{checkboxFilterDataErrorMessage}</InputMessage>}
              </div>
            </div>

            <div className={classNames(styles['form__part'], styles['form__part-location'])}>
              <div className={styles['form__part__left']}>
                <div className={styles['form__part__title']}>
                  <h3 className="text text--h3">Did someone refer you to Astronomic?</h3>
                </div>

                <div className={styles['form__part__text']}>
                  <p className="text text--body-2">If so, enter their email so that we know who to thank!</p>
                </div>
              </div>

              <div className={styles['form__part__right']}>
                <div className={classNames(styles['form__part__input-layout'], 'input-layout')}>
                  <div className="input-layout__row">
                    <div className="input-layout__row__col">
                      <Input type="email" label="Enter their email" placeholder="e.g name@email.com" size="large" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={styles['bottom-part']}>
        <div className="wrapper type-1">
          <div className={styles['bottom-part__content']}>
            <MainButton onClick={handleOnSubmit}>Continue</MainButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateAccountNameAndRoles;
