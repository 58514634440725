import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './HorizontalCard.module.scss';

interface HorizontalCardProps extends PropsWithChildren {
  footer?: React.ReactElement;
  textColor?: string;
  backgroundColor?: string;
  backgroundIllustration?: React.ReactElement;
  className?: string;
}

const HorizontalCard: FunctionComponent<HorizontalCardProps> = ({
  children,
  footer,
  textColor,
  backgroundColor,
  backgroundIllustration,
  className,
}) => {
  return (
    <div className={classNames(styles['card'], 'tile tile--padding-32px', className)} style={{ color: `${textColor}` }}>
      <div className={styles['background']} style={{ background: `${backgroundColor}` }}>
        <div className={styles['background__ill']}>{backgroundIllustration}</div>
      </div>

      <main className={styles['children']}>{children}</main>

      {footer && <footer className={styles['footer']}>{footer}</footer>}
    </div>
  );
};

export default HorizontalCard;
