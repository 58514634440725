import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import { useAppSelector } from '../../redux/hooks';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import { ReactComponent as PilotsIll } from '../../asset/images/pilots_ill.svg';
import styles from './PartnerView.module.scss';
import BackgroundSectionTemplate from '../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import { ReactComponent as JetpackGuy } from '../../asset/images/jetpack_guy.svg';
import { ReactComponent as Rocket } from '../../asset/images/rocket_with_clouds.svg';
import classNames from 'classnames';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { GENERAL_CONTENT } from '../../data/generalContent';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../enums/routes';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';

interface PartnerViewProps extends IWithUserRoleProps {}

const PartnerView: FunctionComponent<PartnerViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const navigate = useNavigate();

  return (
    <MainTemplateForMainPages>
      <HeroSection
        className={styles['hero']}
        title={
          <>
            <mark className={styles['hero__title__mark']}>Partner</mark> with us as a reseller or referrer
          </>
        }
        text={
          <span className={styles['hero__text']}>
            Know someone who’d benefit from working with us? Price on top of us and resell our products and services, or
            refer them to us and we’ll pay you in Rocket Fuel 10% of all that they spend with us during their first
            year.
          </span>
        }
        sectionVariation="var-1"
        illustration={<PilotsIll style={{ maxHeight: '400px' }} />}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <section className={classNames(styles['section-partner'])}>
        <BackgroundSectionTemplate color={'Background-Neutral-Primary'}></BackgroundSectionTemplate>

        <div className="wrapper">
          <div className="content">
            <h2 className="text text--h2">Partner Opportunities</h2>

            <p className={classNames('text text--body-1', styles['section-partner__text'])}>
              Whether you send us customers, or you price on top of us, we’re happy to accommodate you.
            </p>

            <div className={styles['section-partner__block-list']}>
              <div className={styles['section-partner__block-list__item']}>
                <div className={classNames(styles['section-partner__block-list__tile'], 'tile tile--padding-32px')}>
                  <p className={classNames(styles['section-partner__block-list__text-1'], 'text text--caps-small')}>
                    Resellers
                  </p>

                  <p className={classNames(styles['section-partner__block-list__title'], 'text text--h4')}>
                    Price on top of us and resell our offerings
                  </p>

                  <p className={classNames(styles['section-partner__block-list__text-2'], 'text text--body-2')}>
                    Join Astronomic and let us know which products or services you need, then price on top of us and
                    resell our offerings to your customers.
                  </p>

                  <div className={styles['section-partner__block-list__ill-container']}>
                    <JetpackGuy className={styles['section-partner__block-list__ill-1']} />
                  </div>
                </div>
              </div>

              <div className={styles['section-partner__block-list__item']}>
                <div className={classNames(styles['section-partner__block-list__tile'], 'tile tile--padding-32px')}>
                  <p className={classNames(styles['section-partner__block-list__text-1'], 'text text--caps-small')}>
                    Referrers
                  </p>

                  <p className={classNames(styles['section-partner__block-list__title'], 'text text--h4')}>
                    Refer customers for 10% of all sales for 1 year
                  </p>

                  <p className={classNames(styles['section-partner__block-list__text-2'], 'text text--body-2')}>
                    Your referrals will need to provide your email address upon signup in order to attibute the referral
                    to you. Referral commissions are paid in Rocket Fuel to your account.
                  </p>

                  <div className={styles['section-partner__block-list__ill-container']}>
                    <Rocket className={styles['section-partner__block-list__ill-2']} />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['section-partner__button-container']}>
              <JoinForFreeButton />
            </div>
          </div>
        </div>
      </section>
    </MainTemplateForMainPages>
  );
};

export default with_user_role(PartnerView);
