import { UserRoleEnum } from '../enums/user';

export const UserRolePlural: Record<UserRoleEnum, string> = {
  [UserRoleEnum.founder]: 'Founders',
  [UserRoleEnum.incubator]: 'Incubators',
  [UserRoleEnum['business-owner']]: 'Business Owners',
  [UserRoleEnum.accelerator]: 'Accelerators',
  [UserRoleEnum['private-investor']]: 'Private Investors',
  [UserRoleEnum['venture-studio']]: 'Venture Studios',
  [UserRoleEnum.freelancer]: 'Freelancers',
  [UserRoleEnum['coworking-space']]: 'Coworking Spaces',
  [UserRoleEnum.consultant]: 'Consultants',
  [UserRoleEnum.employee]: 'Employees',
  [UserRoleEnum.agency]: 'Agencies',
  [UserRoleEnum.fund]: 'Funds',
};

export const UserRoleId: Record<UserRoleEnum, string> = {
  [UserRoleEnum.founder]: '876a103f-6064-4256-ff17-08dbca9b1610',
  [UserRoleEnum['business-owner']]: 'd4248356-58d6-4673-ff19-08dbca9b1610',
  [UserRoleEnum['private-investor']]: 'c012d41a-0bc8-45ae-ff1a-08dbca9b1610',
  [UserRoleEnum.fund]: 'c800d50f-bdd3-4d34-ff18-08dbca9b1610',
  [UserRoleEnum.incubator]: '12418f22-106e-479a-ff1e-08dbca9b1610',
  [UserRoleEnum.accelerator]: '54355e4d-c51e-456a-ff1f-08dbca9b1610',
  [UserRoleEnum['venture-studio']]: '3ce24498-6370-4608-ff20-08dbca9b1610',
  [UserRoleEnum['coworking-space']]: '2cd50074-4d56-4c3a-ff21-08dbca9b1610',
  [UserRoleEnum.freelancer]: '4b06405f-215b-4e4d-ff1b-08dbca9b1610',
  [UserRoleEnum.consultant]: 'acfcf800-7ae8-4d86-ff1c-08dbca9b1610',
  [UserRoleEnum.agency]: 'd3621009-d734-49f1-ff1d-08dbca9b1610',
  [UserRoleEnum.employee]: 'f7369e43-a622-4c5f-ff22-08dbca9b1610',
};
