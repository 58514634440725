import { FunctionComponent } from 'react';
import styles from './NotFoundView.module.scss';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';

interface NotFoundViewProps {}

const NotFoundView: FunctionComponent<NotFoundViewProps> = () => {
  const navigate = useNavigate();

  return (
    <div className={styles['container']}>
      <div>
        <p className="text text--h1">Page not found</p>
        <p className="text text--p" style={{ marginTop: '24px' }}>
          The page you are looking for does not exist.
        </p>
        <MainButton
          style={{ marginTop: '24px' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </MainButton>
      </div>
    </div>
  );
};

export default NotFoundView;
