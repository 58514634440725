import { ChangeEvent, FunctionComponent, useState } from 'react';
import { ReactComponent as AstronomicLogo } from '../../../asset/images/astronomic_logo_2.svg';
import { ReactComponent as TickIcon } from '../../../asset/images/icons/tick_2.svg';
import { ReactComponent as MailIcon } from '../../../asset/images/icons/mail.svg';
import InputPassword from '../../../components/inputs/InputPassword/InputPassword';
import styles from './CreatePasswordTemplate.module.scss';
import classNames from 'classnames';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import { hasLowerCase, hasNumber, hasSpecialCharacter, hasUpperCase, isEmailValid } from '../../../utils/regex';
import InputMessage from '../../../components/texts/InputMessage/InputMessage';
import Input from '../../../components/inputs/Input/Input';

interface CreatePasswordTemplateProps {
  title: string;
  emailFieldActive?: boolean;
  onSubmitApiRequest(password: string, email?: string): Promise<{ success: boolean; message: string }>;
}

interface ReqItem {
  text: string;
  valid: boolean | null;
}

const reqListInitialState: ReqItem[] = [
  {
    text: 'One lower case character',
    valid: null,
  },
  {
    text: 'One special character',
    valid: null,
  },
  {
    text: 'One uppercase case character',
    valid: null,
  },
  {
    text: '8 character minimum',
    valid: null,
  },
  {
    text: 'One number',
    valid: null,
  },
  {
    text: 'Passwords must match',
    valid: null,
  },
];

const CreatePasswordTemplate: FunctionComponent<CreatePasswordTemplateProps> = ({
  title,
  emailFieldActive = false,
  onSubmitApiRequest,
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [reqList, setReqList] = useState(reqListInitialState);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError(false);
    setEmailErrorMessage('');
    setErrorMessage('');
    setEmail(e.target.value);
  };

  const firstPasswordOnChange = (e) => {
    setFirstPassword(e.target.value);
  };

  const secondPasswordOnChange = (e) => {
    setSecondPassword(e.target.value);
  };

  const changeValidAndGetNewList = (list, index, validValue) => {
    const updatedReqList = [...list];

    const indexToToggle = index;

    updatedReqList[indexToToggle] = {
      ...updatedReqList[indexToToggle],
      valid: validValue,
    };

    setReqList(updatedReqList);

    return updatedReqList;
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let allValid = true;

    const tempReqList = changeReqListAndReturnActualReqList();

    allValid = tempReqList.every((item) => item.valid === true);

    const setEmailErrorState = (message: string) => {
      setEmailErrorMessage(message);
      setEmailError(true);
      allValid = false;
    };

    if (emailFieldActive) {
      if (email === '') {
        setEmailErrorState('Enter your email');
      } else if (isEmailValid(email) === false) {
        setEmailErrorState('Enter valid email');
      }
    }

    if (allValid) {
      const apiResponse = await onSubmitApiRequest(firstPassword, email);

      if (apiResponse.success) {
        setSuccessMessage(apiResponse.message);
      } else {
        setSuccessMessage(''); // Clear success message in case of an error
        setErrorMessage(apiResponse.message);
      }
      // API LOGIC END
    }
  };

  const changeReqListAndReturnActualReqList = () => {
    let tempReqList = reqList;

    tempReqList = changeValidAndGetNewList(tempReqList, 0, hasLowerCase(firstPassword));
    tempReqList = changeValidAndGetNewList(tempReqList, 1, hasSpecialCharacter(firstPassword));
    tempReqList = changeValidAndGetNewList(tempReqList, 2, hasUpperCase(firstPassword));
    tempReqList = changeValidAndGetNewList(tempReqList, 3, firstPassword.length >= 8);
    tempReqList = changeValidAndGetNewList(tempReqList, 4, hasNumber(firstPassword));
    tempReqList = changeValidAndGetNewList(tempReqList, 5, firstPassword === secondPassword && firstPassword !== '');

    return tempReqList;
  };

  return (
    <section className={styles['section']}>
      <div className="wrapper">
        <div className={styles['content']}>
          <div className={styles['logo']}>
            <AstronomicLogo />
          </div>

          <div className={styles['title']}>
            <h3 className="text text--h3">{title}</h3>
          </div>

          <form className={classNames(styles['form'], 'input-layout')} onSubmit={handleOnSubmit}>
            {emailFieldActive && (
              <div className="input-layout__row">
                <Input
                  value={email}
                  onChange={handleEmailOnChange}
                  error={emailError}
                  size="large"
                  label="Enter your email"
                  iconLeft={<MailIcon style={{ fill: 'var(--color-gray-700)', width: '22px' }} />}
                />

                {emailErrorMessage && <InputMessage>{emailErrorMessage}</InputMessage>}
              </div>
            )}

            <div className="input-layout__row">
              <InputPassword
                value={firstPassword}
                size="large"
                label="Enter new password"
                onChange={firstPasswordOnChange}
              />
            </div>

            <div className="input-layout__row">
              <InputPassword
                value={secondPassword}
                size="large"
                label="Confirm new password"
                onChange={secondPasswordOnChange}
              />
            </div>

            <div className="input-layout__row input-layout__row--large-margin">
              <div className={styles['requirements-list']}>
                {reqList.map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        styles['requirements-list__item'],
                        item.valid === true && styles['requirements-list__item--valid'],
                        item.valid === false && styles['requirements-list__item--invalid']
                      )}
                      key={index}
                    >
                      <div className={styles['requirements-list__item__circle']}>
                        <TickIcon
                          className={styles['requirements-list__item__circle__tick']}
                          style={{ width: '10px' }}
                        />
                      </div>

                      <div className={styles['requirements-list__item__text']}>
                        <p className="text text--caption">{item.text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={classNames('input-layout__row input-layout__row--large-margin', styles['row-submit'])}>
              <MainButton sizeType="large" visualType="main">
                Continue
              </MainButton>
            </div>

            {successMessage && <InputMessage messageState="success">{successMessage}</InputMessage>}
            {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
          </form>
        </div>
      </div>
    </section>
  );
};

export default CreatePasswordTemplate;
