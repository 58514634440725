import { AxiosInstance, AxiosResponse } from 'axios';
import BaseAPI from '../base/api';
import { ApiResponseDTO } from '../../dto/api';

class ContentApi extends BaseAPI {
  private paths = {
    GET_HOME_PAGE_CONTENT: 'Content/GetHomePageContent',
    GET_CONNECT_PROGRAMS_BY_ROLE: 'Content/GetConnectPorgramsByRole',
    GET_CONNECT_PROGRAM_INFO: 'Content/GetConnectProgramInfo',
    GET_SERVICE_CATEGORIES: 'Content/GetServiceCategories',
    GET_GROWTH_STAGES: 'Content/GetGrowthStages',
    GET_SERVICES_BY_CATEGORY_AND_STAGE: 'Content/GetServicesByCategoryAndStage',
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(axios: AxiosInstance, backend: string) {
    super(axios, backend);
  }

  async getHomePageContent(roleId: string) {
    const params = {
      roleId: roleId,
    };
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_HOME_PAGE_CONTENT, { params });
  }

  async getConnectProgramsByRole(roleId: string) {
    const params = {
      roleId: roleId,
    };
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_CONNECT_PROGRAMS_BY_ROLE, { params });
  }

  async getConnectProgramInfo(programId: string) {
    const params = {
      programId: programId,
    };
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_CONNECT_PROGRAM_INFO, { params });
  }

  async getServiceCategories() {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_SERVICE_CATEGORIES);
  }

  async getGrowthStages() {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_GROWTH_STAGES);
  }

  async getServicesByCategoryAndStage(params: {
    serviceCategoryId: Array<string> | null;
    growthStageId: string | null;
  }) {
    return this.axios.get<ApiResponseDTO<any>>(this.paths.GET_SERVICES_BY_CATEGORY_AND_STAGE, {
      params,
      paramsSerializer: (params) => {
        // Custom serializer to repeat parameters without brackets
        const queryParams: string[] = [];
        for (const key in params) {
          if (Array.isArray(params[key])) {
            // Serialize array by repeating the key for each item
            params[key].forEach((item: string) => {
              queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
            });
          } else if (params[key] !== null && params[key] !== undefined) {
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
          }
        }
        return queryParams.join('&');
      },
    });
  }
}

export default ContentApi;
