import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './TitleWithIconAndTextCard.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';

interface TitleWithIconAndTextCardProps {
  title: string;
  text?: string | React.ReactElement;
  icon?: React.ReactElement;
  iconBgColor?: string;
  link?: string | null;
  linkText?: string;
  cardVariation?: 'var-0' | 'var-1';
  cardColor?: 'default' | 'white';
  cardContentSize?: 'medium' | 'large';
}

const TitleWithIconAndTextCard: FunctionComponent<TitleWithIconAndTextCardProps> = ({
  title,
  text,
  icon,
  iconBgColor,
  link,
  linkText = 'Join Astronomic for free',
  cardVariation = 'var-0',
  cardColor = 'default',
  cardContentSize = 'medium',
}) => {
  const navigate = useNavigate();

  return (
    <div className={classNames(styles['card'], styles[cardVariation], styles[`card--color-${cardColor}`])}>
      <div className={classNames(styles['card__header'])}>
        {icon && (
          <div className={classNames(styles['card__header__icon'])} style={{ backgroundColor: iconBgColor }}>
            {icon}
          </div>
        )}

        <div className={classNames(styles['card__header__title'])}>
          {cardContentSize === 'medium' ? (
            <h4 className="text text--h4">{title}</h4>
          ) : (
            <h3 className="text text--h3">{title}</h3>
          )}
        </div>
      </div>

      <div className={classNames(styles['card__body'])}>
        {text && (
          <div className={classNames(styles['card__body__text'])}>
            {cardContentSize === 'medium' ? (
              <p className="text text--body-2">{text}</p>
            ) : (
              <p className="text text--body-1">{text}</p>
            )}
          </div>
        )}

        {link && (
          <div className={classNames(styles['card__body__button'])}>
            <MainButton
              sizeType="medium"
              visualType="main"
              onClick={() => navigate(link)}
              iconRight={
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="var(--color-white)"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.47075 0.861888C6.2104 0.601539 5.78829 0.601539 5.52794 0.861888C5.26759 1.12224 5.26759 1.54435 5.52794 1.8047L9.05654 5.33329H1.33268C0.964492 5.33329 0.666016 5.63177 0.666016 5.99996C0.666016 6.36815 0.964492 6.66663 1.33268 6.66663H9.05654L5.52794 10.1952C5.26759 10.4556 5.26759 10.8777 5.52794 11.138C5.78829 11.3984 6.2104 11.3984 6.47075 11.138L11.1374 6.47136C11.3978 6.21101 11.3978 5.7889 11.1374 5.52855L6.47075 0.861888Z"
                  />
                </svg>
              }
            >
              {linkText}
            </MainButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleWithIconAndTextCard;
