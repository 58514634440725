import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './InputMessage.module.scss';

interface InputMessageProps extends PropsWithChildren {
  messageState?: 'alert' | 'success';
}

const InputMessage: FunctionComponent<InputMessageProps> = ({ children, messageState = 'alert' }) => {
  return (
    <p className={classNames(styles['message'], styles[`message--state-${messageState}`], 'text text--caption')}>
      {children}
    </p>
  );
};

export default InputMessage;
