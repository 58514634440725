import { FunctionComponent } from "react";
import styles from "./Label.module.scss";
import classNames from "classnames";

interface LabelProps {
  text: string;
  visualStyle?: "main" | "white" | "main-rounded" | "small";
  className?: string;
}

const Label: FunctionComponent<LabelProps> = ({
  text,
  visualStyle = "main",
  className,
}) => {
  return (
    <div
      className={classNames(
        styles["label"],
        styles[`label--${visualStyle}`],
        className
      )}
    >
      <p
        className={classNames(styles["text"], "text text--caption text--bold")}
      >
        {text}
      </p>
    </div>
  );
};

export default Label;
