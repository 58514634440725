import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { AppState } from '.'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
export type TypedUseAppDispatch = TypedDispatch<AppState>;
 
export const useAppDispatch = () => useDispatch<TypedUseAppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector