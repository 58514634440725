import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './BurgerButton.module.scss';

type BurgerColorType = 'light' | 'dark';

const BurgerColorMap: Record<BurgerColorType, string> = {
  light: '',
  dark: 'burger--color-dark',
};

interface BurgerButtonProps {
  active: boolean;
  className?: string;
  burgerColor?: BurgerColorType;
  onClick?(): void;
}

const BurgerButton: FunctionComponent<BurgerButtonProps> = ({ active, burgerColor = 'light', className, onClick }) => {
  return (
    <button
      className={classNames(
        styles['burger'],
        active && styles['burger--active'],
        styles[BurgerColorMap[burgerColor]],
        className
      )}
      onClick={onClick}
    >
      <div className={styles['burger__line-container']}>
        <div className={styles['burger__line']}></div>
        <div className={styles['burger__line']}></div>
        <div className={styles['burger__line']}></div>
      </div>
    </button>
  );
};

export default BurgerButton;
