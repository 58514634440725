import { FunctionComponent, useEffect } from 'react';
import { ReactComponent as AstronomicLogo } from '../../asset/images/astronomic_logo_2.svg';
import classNames from 'classnames';
import styles from './ForgotPasswordPasswordChangedView.module.scss';
import MainButton from '../../components/buttons/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import { localStorageGetItem, localStorageRemoveItem } from '../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../enums/localStorageKeys';
import RoutesEnum from '../../enums/routes';

interface ForgotPasswordPasswordChangedViewProps {}

const ForgotPasswordPasswordChangedView: FunctionComponent<ForgotPasswordPasswordChangedViewProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_FORGOT_PASSWORD_STEP) !== '2') {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }

    localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_FORGOT_PASSWORD_STEP);
  }, []);

  return (
    <section className={styles['section']}>
      <div className="wrapper">
        <div className={styles['content']}>
          <div className={styles['logo']}>
            <AstronomicLogo />
          </div>

          <div className={styles['title']}>
            <h3 className="text text--h3">Password changed</h3>
          </div>

          <p className={classNames(styles['text'], 'text text--body-2')}>
            Your password has been successfully changed.
          </p>

          <MainButton
            className={styles['button']}
            onClick={() => {
              navigate(`/${RoutesEnum.SIGN_IN}`);
            }}
          >
            Sign In
          </MainButton>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordPasswordChangedView;
