import { FunctionComponent, useEffect, useState } from 'react';
import { get_env } from '../../../config/functions/app_functions';

type env = 'development' | 'staging' | 'production';

interface ShowOnlyInSomeEnvProps extends React.PropsWithChildren {
  showInWhatEnv?: env[];
}

const ShowOnlyInSomeEnv: FunctionComponent<ShowOnlyInSomeEnvProps> = ({
  children,
  showInWhatEnv = ['development'],
}) => {
  const canOutput = showInWhatEnv.some((item) => get_env() === item);

  return <>{canOutput && children}</>;
};

export default ShowOnlyInSomeEnv;
