export function formatDate(inputDate: Date | string, outputFormat: string): string {
    let date: Date;

    if (typeof inputDate === 'string') {
        date = new Date(inputDate);
    } else {
        date = inputDate;
    }

    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()];

    // Define format tokens and their corresponding replacements
    const formatTokens: { [key: string]: string } = {
        'dd': dd,
        'mm': mm,
        'yyyy': String(yyyy),
        'mmm': monthShort,
        'HH': String(date.getHours()).padStart(2, '0'),
        'MM': String(date.getMinutes()).padStart(2, '0'),
        'SS': String(date.getSeconds()).padStart(2, '0'),
    };

    // Replace format tokens with their values
    let formattedDate = outputFormat;
    for (const token in formatTokens) {
        if (Object.prototype.hasOwnProperty.call(formatTokens, token)) {
            formattedDate = formattedDate.replace(new RegExp(`\\b${token}\\b`, 'g'), formatTokens[token]);
        }
    }

    return formattedDate;
}
