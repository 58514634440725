import { FunctionComponent } from 'react';
import { ReactComponent as LogoLight } from '../../../asset/images/astronomic_logo_3.svg';
import { ReactComponent as LogoDark } from '../../../asset/images/astronomic_logo_2.svg';
import styles from './AstronomicLogo.module.scss';

interface AstronomicLogoProps {
  color?: 'light' | 'dark';
  className?: string;
  onClick?(e?): void;
}

const AstronomicLogo: FunctionComponent<AstronomicLogoProps> = ({ color = 'light', className, onClick }) => {
  return (
    <div onClick={onClick} className={className}>
      {color === 'light' && <LogoLight />}
      {color === 'dark' && <LogoDark />}
    </div>
  );
};

export default AstronomicLogo;
