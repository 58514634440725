import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IUserSliceState } from "./slice";
import { getUserInfoAsyncThunk } from "./thunks";

const user_builder = (builder: ActionReducerMapBuilder<IUserSliceState>) => {
    builder.addCase(getUserInfoAsyncThunk.fulfilled, (state, action) => {
        return {
            ...state,
            user: action.payload,
            userLogged: true,
            statuses: {
                ...state.statuses,
                fetch_user_info: {
                    state: 'fulfilled',
                    error: undefined,
                }
            }
        }
    })
    builder.addCase(getUserInfoAsyncThunk.pending, (state, action) => {
        return {
            ...state,
            statuses: {
                fetch_user_info: {
                    state: 'pending',
                },
            }
        }
    })
    builder.addCase(getUserInfoAsyncThunk.rejected, (state, action) => {
        return {
            ...state,
            userLogged: false,
            statuses: {
                fetch_user_info: {
                    state: 'rejected',
                    error: action.payload,
                }
            },
        }
    })
}

export default user_builder;