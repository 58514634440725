import { FunctionComponent } from 'react';
import { UserRoleEnum } from '../../../../src/enums/user';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from '../Breadcrumbs/breadcrumbs.module.scss';
import NavigationLink from '../NavigationLink/NavigationLink';

interface BreadcrumbsProps {
  className?: string;
  userRole: UserRoleEnum;
  currentLinkText?: string;
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ className, userRole, currentLinkText }) => {
  const location = useLocation();
  const pathname = location.pathname;

  let crumbPath = `/${userRole}`;

  const pathnameWithoutRoleArr = pathname.split('/').filter((item, i) => i !== 0 && i !== 1);

  return pathnameWithoutRoleArr.length >= 1 ? (
    <nav aria-label="breadcrumb" className={classNames(className, styles['breadcrumbs'])}>
      <ol>
        <li aria-current="page">
          <NavigationLink path={crumbPath} withBorder={false}>
            Home
          </NavigationLink>
        </li>

        {pathnameWithoutRoleArr.map((item, i) => {
          const formattedItem = item.replace(/-/g, ' ').replace(/_/g, ' ');
          crumbPath += '/' + item;

          if (i !== pathnameWithoutRoleArr.length - 1)
            return (
              <li key={i}>
                <NavigationLink path={crumbPath} withBorder={false}>
                  {formattedItem}
                </NavigationLink>
              </li>
            );
          else
            return (
              <li key={i}>
                <span>{currentLinkText ? currentLinkText : formattedItem}</span>
              </li>
            );
        })}
      </ol>
    </nav>
  ) : (
    <></>
  );
};

export default Breadcrumbs;
