import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './CardsWithHeaderSection.module.scss';
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from '../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
interface CardsWithHeaderSectionProps extends PropsWithChildren {
  sectionVariation?: 'var-0' | 'var-1';
  sectionBackgroundColor?: BackgroundSectionTemplateColorType;
  wrapperClass?: string;
  className?: string;
  title?: string | React.ReactElement;
  text: string;
  button?: React.ReactElement;
  customElement?: React.ReactElement;
}

const CardsWithHeaderSection: FunctionComponent<CardsWithHeaderSectionProps> = ({
  sectionVariation = 'var-0',
  sectionBackgroundColor = 'white',
  wrapperClass,
  className,
  title,
  text,
  button,
  customElement,
  children,
}) => {
  return (
    <section className={classNames(styles['section'], styles[sectionVariation], className)}>
      <BackgroundSectionTemplate color={sectionBackgroundColor}></BackgroundSectionTemplate>

      <div className={classNames('wrapper', wrapperClass)}>
        <div className="content">
          <div className={styles['row-list']}>
            <div className={styles['row-list__item']}>
              <div className={styles['row-list__item__col']}>
                <div className={styles['title']}>
                  <h2 className="text text--h2">{title}</h2>
                </div>

                <div className={styles['text']}>
                  <p className="text text--body-1">{text}</p>
                </div>

                {sectionVariation === 'var-1' && <div className={styles['button-2']}>{button}</div>}
              </div>

              <div className={styles['row-list__item__col']}>
                {sectionVariation === 'var-0' && <div className={styles['button']}>{button}</div>}

                {sectionVariation === 'var-1' && customElement && customElement}
              </div>
            </div>

            <div className={styles['row-list__item']}>{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardsWithHeaderSection;
