import { FunctionComponent } from "react";
import { ReactComponent as ArrowIcon } from "../../../asset/images/arrow_down.svg";
import classNames from "classnames";
import styles from "./PercentagesCard.module.scss";

interface PercentagesCardProps {
  title: string;
  arrowDirectionUp?: boolean;
  text: string;
}

const PercentagesCard: FunctionComponent<PercentagesCardProps> = ({
  title,
  arrowDirectionUp = true,
  text,
}) => {
  return (
    <div className={styles["card"]}>
      <div className={styles["title-container"]}>
        <div className={styles["title-container__text"]}>
          <p className="text text--h3">{title}</p>
        </div>
        <div
          className={classNames(
            styles["title-container__arrow"],
            arrowDirectionUp === true
              ? styles["title-container__arrow--up"]
              : styles["title-container__arrow--down"]
          )}
        >
          {<ArrowIcon />}
        </div>
      </div>

      <div className={styles["text"]}>
        <p className="text text--caption">{text}</p>
      </div>
    </div>
  );
};

export default PercentagesCard;
