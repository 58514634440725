import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSectionWithImage from '../../components/sections/HeroSectionWithImage/HeroSectionWithImage';
import { ReactComponent as NextCoastLogo } from '../../asset/images/next_coast_logo.svg';
import Breadcrumbs from '../../components/links/Breadcrumbs/Breadcrumbs';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import styles from './CaseStudyView.module.scss';
import Content from '../../components/texts/Content/Content';
import Grid from '../../components/blocks/Grid/Grid';
import PercentagesCard from '../../components/blocks/PercentagesCard/PercentagesCard';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithElem from '../../components/titles/TitleWithElem/TitleWithElem';
import NavigationLink from '../../components/links/NavigationLink/NavigationLink';
import { ReactComponent as ButtonArrow } from '../../asset/images/button_arrow.svg';
import RoutesEnum from '../../enums/routes';

interface CaseStudyViewProps extends IWithUserRoleProps {}

const CaseStudyView: FunctionComponent<CaseStudyViewProps> = ({ user_role }) => {
  const projectDetailsInfo = [
    { title: 'Total Time', text: '2 weeks', addInfo: '78.5 hours' },
    { title: 'Meetings', text: '6.5 hours', addInfo: '' },
    { title: 'Design', text: '26 hours', addInfo: '' },
    { title: 'Copywriting', text: '13 hours', addInfo: '' },
    { title: 'Client feedback', text: '33 hours', addInfo: '' },
    { title: 'Total Cost', text: '$ 5,000', addInfo: '' },
  ];

  return (
    <MainTemplate color="white">
      <HeroSectionWithImage
        title="Case study template"
        text="From low-fidelity wireframes to high-fidelity visual designs, our product designers will create gorgeous, designs of usable interfaces so that you can visualize what your website or app will look and feel like."
        logo={<NextCoastLogo />}
        imageSrc={require('../../asset/images/case_study_image_2.jpg')}
        breadcrumbs={<Breadcrumbs userRole={user_role} currentLinkText="Case study template" />}
      />

      <SectionDivider color="white" />

      <section className={styles['content-section']}>
        <div className="background"></div>

        <div className="wrapper type-1">
          <div className="content">
            <div className={styles['content-section__block-list']}>
              <div className={styles['content-section__block-list__item-1']}>
                <div className={styles['content-section__project-details']}>
                  <div className={styles['content-section__project-details__main-title']}>
                    <h5 className="text text--h5">Project Details</h5>
                  </div>

                  <div className={styles['content-section__project-details__item-list']}>
                    {projectDetailsInfo.map((item, i) => (
                      <div className={styles['content-section__project-details__item-list__item']} key={i}>
                        <div className={styles['content-section__project-details__item-list__item__title']}>
                          <p className="text text--caps-small">{item.title}</p>
                        </div>

                        <div className={styles['content-section__project-details__item-list__item__text']}>
                          <p className="text text--h4">{item.text}</p>
                          {item.addInfo && (
                            <div
                              className={styles['content-section__project-details__item-list__item__text__add-info']}
                            >
                              <p className="text text--caption">{item.addInfo}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles['content-section__block-list__item-2']}>
                <div className={styles['content-section__content']}>
                  <Content>
                    <p>
                      Faucibus diam ut volutpat elementum vel lacus. Sodales non ut blandit ipsum nisi. Risus mi proin
                      morbi ullamcorper dolor gravida lorem tempor. Viverra scelerisque mauris vitae quam ac. Commodo
                      vitae feugiat aliquam fames erat vitae elit maecenas. Quis vestibulum et nisl pharetra lectus
                      feugiat magna blandit. Donec vitae in phasellus in molestie. Imperdiet nam orci quis rhoncus. Orci
                      elit duis duis habitant. In hac est suspendisse imperdiet. Nunc amet sit id ultricies vel
                      dignissim suspendisse eu sed.
                    </p>

                    <p>
                      Lobortis morbi sed donec vel hendrerit purus fermentum. Molestie lacinia auctor sed a integer.
                      Augue ullamcorper mauris libero consequat tellus non sed. Consequat proin quam nisl eget mattis.
                      Facilisi bibendum quam convallis gravida purus. Dolor non porttitor ullamcorper aliquet. Massa
                      facilisi integer scelerisque turpis odio quis sit. Consequat nunc tempus nunc egestas etiam in in.
                      Aliquet diam molestie amet adipiscing senectus pellentesque viverra pulvinar. In viverra.
                    </p>

                    <h2>We thrive in the "make or break” stage of the journey</h2>

                    <p>
                      Tempor dictum risus nullam feugiat. Risus enim enim dignissim quam id amet. Magnis gravida in
                      risus dignissim aliquam. At vel eget cras eros. Orci vitae cras penatibus id dui sed. Sollicitudin
                      erat quam vitae tristique in consectetur molestie imperdiet. Non vel nisl laoreet tristique nulla
                      laoreet velit et molestie.
                    </p>

                    <p>Mattis lectus aliquam orci ac netus duis euismod tristique lorem. Mauris duis eget varius.</p>

                    <img src={require('../../asset/images/random_image.jpg')} alt="Dummy" />

                    <h2>Expert Network</h2>

                    <p>
                      Viverra adipiscing neque porttitor diam viverra fames et blandit convallis. Sem est nibh
                      suspendisse at augue semper non. Purus in sed aliquet imperdiet non massa nisl. Egestas
                      ullamcorper lectus sit adipiscing turpis ullamcorper ultrices nunc. Tellus donec scelerisque
                      cursus urna enim netus magna. A rhoncus feugiat at tellus pharetra quis amet posuere. Nunc semper
                      aliquam ut semper eget sed sed. Amet massa nisi ornare mi hendrerit odio imperdiet. Donec id
                      aenean urna iaculis malesuada tincidunt. Sed arcu eu ornare sed magnis egestas et nunc augue.
                      Ullamcorper molestie eros at ultrices dictumst. Vitae nunc et urna elementum elit. Ac eget ante
                      placerat tellus. Nulla arcu nibh tincidunt aliquam consequat et est interdum. Mattis nunc etiam
                      nulla integer varius blandit. Sit id eu tincidunt eu commodo tellus ante. Semper convallis pretium
                      sit at et vestibulum. Sed ultrices sit ac turpis id nunc. Turpis leo risus massa ut fusce
                      condimentum ut. Commodo tempus semper purus feugiat. Enim.
                    </p>

                    <img src={require('../../asset/images/random_image.jpg')} alt="Dummy" />

                    <h2>The Results</h2>

                    <Grid
                      gridHorizontalMargin={3}
                      gridVerticalMargin={3}
                      itemGroup={[
                        <PercentagesCard
                          title="32%"
                          text="Diam sed lorem gravida urna sed. Pulvinar faucibus."
                          arrowDirectionUp={false}
                        />,
                        <PercentagesCard
                          title="100%"
                          text="Morbi egestas sit lacus velit. Vitae purus faucibus."
                          arrowDirectionUp={true}
                        />,
                        <PercentagesCard
                          title="32%"
                          text="Diam sed lorem gravida urna sed. Pulvinar faucibus."
                          arrowDirectionUp={false}
                        />,
                        <PercentagesCard
                          title="32%"
                          text="Diam sed lorem gravida urna sed. Pulvinar faucibus."
                          arrowDirectionUp={false}
                        />,
                        <PercentagesCard
                          title="32%"
                          text="Diam sed lorem gravida urna sed. Pulvinar faucibus."
                          arrowDirectionUp={false}
                        />,
                        <PercentagesCard
                          title="32%"
                          text="Diam sed lorem gravida urna sed. Pulvinar faucibus."
                          arrowDirectionUp={false}
                        />,
                      ]}
                    />

                    <p>
                      Euismod nec mauris neque egestas. Vitae lorem id cursus sodales ac eget. Faucibus lacus
                      scelerisque amet id. Cursus cras et vel accumsan ullamcorper nunc. Netus turpis congue netus
                      malesuada. Diam lectus nisi est curabitur hendrerit. Cursus amet tortor ut mattis. Sit odio enim
                      odio tincidunt tortor dignissim justo molestie. Sodales ornare sit commodo malesuada proin morbi
                      ut arcu. A elit interdum elementum maecenas facilisis luctus eget nulla sit.
                    </p>

                    <p>
                      Nulla tellus tellus cursus massa phasellus feugiat sit ut. Mauris proin lorem lectus a nunc. Etiam
                      tempus scelerisque etiam accumsan vitae purus senectus ut fermentum. Est enim interdum massa
                      tellus eget risus. Maecenas ut cras dictum pellentesque vitae ut sem eget. Nisi posuere orci urna
                      pellentesque morbi purus vitae. In sagittis eu nisl maecenas aliquam nisl sagittis. Et scelerisque
                      senectus vitae eget. Iaculis rhoncus id senectus habitant bibendum. Egestas id molestie neque
                      amet. Ut tristique natoque cursus enim. Sit morbi metus molestie sed. Risus amet eu quisque
                      sagittis tortor.
                    </p>
                  </Content>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionDivider color="gray" />

      <ChildrenWithHeaderSection
        title={
          <TitleWithElem
            marginBottom="medium"
            element={
              <NavigationLink
                path={`/${user_role}/${RoutesEnum.BUILD}`}
                iconRight={<ButtonArrow />}
                sizeType="small"
                withBorder={false}
              >
                View all
              </NavigationLink>
            }
          >
            <h4 className="text text--h4">Related products</h4>
          </TitleWithElem>
        }
        backgroundColor="gray"
      >
        <Grid
          itemGroup={[
            <ServiceCard
              title="UI/UX design"
              text="We’ll design your wireframes, clickable prototypes, and mockups for your app or website."
              price={null}
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
            <ServiceCard
              title="Pitch Deck Design"
              text="We’ll design your deck, write your copy, create your illustrations, and beautify your graphs."
              price={null}
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
            <ServiceCard
              title="Branding identity design"
              text="We’ll define your brand pillars, customer personas, and design your logo. "
              price={null}
              link={`/${user_role}/${RoutesEnum.BUILD}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>
    </MainTemplate>
  );
};

export default with_user_role(CaseStudyView);
