import { UserRoleId } from '../data/role';
import { UserRoleEnum } from '../enums/user';
import { is_user_role } from '../type_guards/user_role';

export function extractRoleFromUrl(url?: string): UserRoleEnum {
  // Step 1: Get the URL (use current page URL if none is provided)
  const targetUrl = url || window.location.href;

  // Step 2: Parse the URL and extract the first path segment after the domain
  const pathSegments = new URL(targetUrl).pathname.split('/').filter(Boolean);

  // Step 3: Extract the first path segment after the domain
  const firstSegment = pathSegments[0] ? pathSegments[0].toLowerCase() : '';

  // Step 4: Check if it matches a valid role
  if (is_user_role(firstSegment)) {
    return firstSegment;
  }

  // Step 5: Return the first role from UserRoleEnum if no match is found
  return Object.keys(UserRoleEnum)[0] as UserRoleEnum;
}

export function getRoleIdByUserRole(userRole: UserRoleEnum): string {
  return UserRoleId[UserRoleEnum[userRole]] ? UserRoleId[UserRoleEnum[userRole]] : 'Error. No user ID found';
}
