import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './ProductCard.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import Label from '../../texts/Label/Label';

interface ProductCardProps {
  title: string;
  buttonText: string;
  price: string;
  bonusCredits: string;
  discount: string;
  suggested?: boolean;
  onClick?(): void;
  className?: string;
}

const ProductCard: FunctionComponent<ProductCardProps> = ({
  title,
  buttonText,
  price,
  bonusCredits,
  discount,
  suggested,
  onClick,
  className,
}) => {
  return (
    <article
      className={classNames(styles['subscription-card'], 'button-container-for-hover', className)}
      onClick={onClick}
    >
      <div className={styles['info-container']}>
        <div className={styles['info-container__top-part']}>
          {suggested === true && (
            <div className={styles['suggested']}>
              <p className="text text--caps-small">Suggested for you</p>
            </div>
          )}

          <div className={styles['title']}>
            <h5 className="text text--h5">{title}</h5>
          </div>

          <div className={styles['price']}>
            <p className="text text--caption">{price}</p>
            <div className={styles['price__dot']}></div>
            <p className="text text--caption">{bonusCredits}</p>
          </div>
        </div>

        <div className={styles['info-container__bottom-part']}>
          <MainButton className={styles['button']} sizeType="medium" visualType="main">
            {buttonText}
          </MainButton>
        </div>
      </div>

      <div className={styles['discount']}>
        <Label text={discount} visualStyle="main-rounded" />
      </div>
    </article>
  );
};

export default ProductCard;
