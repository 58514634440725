import { FunctionComponent, ChangeEvent } from "react";
import styles from "./ToggleSwitch.module.scss";
import classNames from "classnames";

interface ToggleSwitchProps {
  checked: boolean;
  size?: "large" | "small";
  color?: "original" | "dark-orange";
  className?: string;
  onChange: (checked: boolean) => void;
}

const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = ({
  checked,
  size = "large",
  color = "original",
  className,
  onChange,
}) => {
  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    onChange(newChecked);
  };

  return (
    <label
      className={classNames(
        styles["toggle-switch"],
        styles[`toggle-switch--size-${size}`],
        styles[`toggle-switch--color-${color}`],
        className
      )}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleToggleChange}
        className={styles["toggle-switch__original-input"]}
      />

      <div className={styles["toggle-switch__custom-input"]}></div>
    </label>
  );
};

export default ToggleSwitch;
