import { FunctionComponent, useEffect, useRef, useState } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import animationData from '../../../asset/lotties/astronomic_loader.json';
import styles from './AstronomicLoader.module.scss';
import classNames from 'classnames';

const transitionTimeout = 600;

interface AstronomicLoaderProps {
  customTransitionTimeout?: number;
  variation?: 'default' | 'blurredBackground';
  logoPosition?: 'default' | 'top';
  color?: 'default' | 'onBlue';
}

const AstronomicLoader: FunctionComponent<AstronomicLoaderProps> = ({
  customTransitionTimeout,
  variation = 'default',
  logoPosition = 'default',
  color = 'default',
}) => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animationInstance: AnimationItem;

    if (animationContainer.current) {
      animationInstance = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    }

    return () => {
      if (animationInstance) {
        setTimeout(
          () => animationInstance.destroy(),
          customTransitionTimeout ? customTransitionTimeout : transitionTimeout
        );
      }
    };
  }, [animationContainer]);

  return (
    <div
      className={classNames(
        styles['container'],
        styles[`container--var-${variation}`],
        styles[`container--color-${color}`],
        styles[`container--logo-position-${logoPosition}`]
      )}
    >
      <div className={styles['container__loader']} ref={animationContainer}></div>
    </div>
  );
};

export default AstronomicLoader;
