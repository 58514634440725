import { FunctionComponent } from 'react';
import { ReactComponent as RocketIcon } from '../../../asset/images/icons/rocket.svg';
import styles from './RocketFuel.module.scss';
import classNames from 'classnames';
import RoutesEnum from '../../../enums/routes';
import { useNavigate } from 'react-router-dom';

interface RocketFuelProps {
  number: number;
  color?: 'default' | 'blank';
  link?: string;
}

const RocketFuel: FunctionComponent<RocketFuelProps> = ({ number, color = 'default', link }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div
      className={classNames(
        styles['container'],
        styles[`container--color-${color}`],
        link && styles['container--link']
      )}
      onClick={handleOnClick}
    >
      <div className={styles['container__icon']}>
        {<RocketIcon style={{ width: '12px', fill: 'var(--color-main)' }} />}
      </div>

      <div className={styles['container__number']}>{number.toLocaleString('en-US')}</div>
    </div>
  );
};

export default RocketFuel;
