import React, { useEffect, useState, ComponentType } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getUserInfoAsyncThunk } from '../../../redux/slices/user/thunks';
import { localStorageGetUserAuthItems } from '../../../utils/localStorageMethods';
import AstronomicLoader from '../../../components/blocks/AstronomicLoader/AstronomicLoader';

export interface AuthProps {
  isUserLoggedIn?: boolean;
}

const withAuthentication = <P extends AuthProps>(WrappedComponent: ComponentType<P>) => {
  const AuthWrapper: React.FC<P> = (props) => {
    const dispatch = useAppDispatch();
    const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
    const [isLoading, setIsLoading] = useState(true);
    const { at, rt, userId } = localStorageGetUserAuthItems();

    useEffect(() => {
      const checkAuthentication = async () => {
        if (userId) {
          if (!isUserLoggedIn && (at || rt)) {
            await dispatch(getUserInfoAsyncThunk(userId));
          }
        }

        setIsLoading(false);
      };

      checkAuthentication();
    }, []);

    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            overflow: 'hidden',
          }}
        >
          <AstronomicLoader />
        </div>
      );
    }

    return <WrappedComponent {...props} isUserLoggedIn={isUserLoggedIn} />;
  };

  return AuthWrapper;
};

export default withAuthentication;
