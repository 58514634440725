import { FunctionComponent, PropsWithChildren } from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import classNames from 'classnames';
import styles from './ToastifyContent.module.scss';
import { ToastMessageBaseProps } from '../../ToastMessage';

interface ToastifyContentProps extends ToastMessageBaseProps {}

const ToastifyContent: FunctionComponent<ToastifyContentProps> = ({
  variation = 'error',
  title = 'Something went wrong',
  actionText = 'Action',
  action,
  children,
}) => {
  return (
    <div className={classNames(styles['toast'], styles[`toast--var-${variation}`])}>
      {title && <p className={classNames(styles['toast__title'], 'text text--body-2 text--bold')}>{title}</p>}

      <div className={classNames(styles['toast__description'], 'text text--caption')}>{children}</div>

      {action && actionText && (
        <button className={styles['toast__button']} onClick={action}>
          <span className={styles['toast__button__text']}>{actionText}</span>
          <span className={styles['toast__button__icon']}>
            <ArrowIcon />
          </span>
        </button>
      )}
    </div>
  );
};

export default ToastifyContent;
