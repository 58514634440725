import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

interface InputProps {
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  label?: string | React.ReactElement;
  value?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  type?: 'text' | 'password' | 'email';
  color?: 'default' | 'gray';
  name?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const Input: FunctionComponent<InputProps> = ({
  type = 'text',
  size = 'small',
  color = 'default',
  value,
  placeholder,
  iconLeft,
  iconRight,
  label,
  name,
  error = false,
  disabled = false,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isFocused, setIsFocused] = useState(false);

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange?.(e);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    } else {
      setInputValue('');
    }
  }, [value]);

  return (
    <label
      className={classNames(
        styles['input'],
        size === 'large' && styles['input--size-large'],
        color === 'gray' && styles['input--color-gray'],
        isFocused === true && styles['input--is-focused'],
        error === true && styles['input--has-error'],
        disabled === true && styles['input--is-disabled']
      )}
    >
      {label && (
        <div className={styles['input__label']}>
          <div className="text text--body-2 text--bold">{label}</div>
        </div>
      )}

      <div
        className={classNames(
          styles['input__container'],
          iconLeft && styles['input__container--icon-left'],
          iconRight && styles['input__container--icon-right']
        )}
      >
        {iconLeft && <div className={styles['input__container__icon-left']}>{iconLeft}</div>}

        <input
          className={styles['input__container__original-input']}
          type={type}
          value={inputValue}
          placeholder={placeholder}
          name={name}
          onChange={inputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        <div className={styles['input__container__custom-input']}></div>

        {iconRight && <div className={styles['input__container__icon-right']}>{iconRight}</div>}
      </div>
    </label>
  );
};

export default Input;
