import { FunctionComponent } from 'react';
import { ReactComponent as TickIcon } from '../../../../../../../../../asset/images/icons/tick.svg';
import styles from './CompleteProfileHeaderStep.module.scss';
import classNames from 'classnames';

export type StateType = 'next' | 'current' | 'past' | string;

export const stateMap: Record<StateType, string> = {
  next: '',
  current: 'step--current',
  past: 'step--past',
};

interface CompleteProfileHeaderStepProps {
  number: number;
  label: string;
  currentStep: number;
}

const CompleteProfileHeaderStep: FunctionComponent<CompleteProfileHeaderStepProps> = ({
  number,
  label,
  currentStep,
}) => {
  return (
    <div
      className={classNames(
        styles['step'],
        currentStep === number && styles['step--current'],
        currentStep > number && styles['step--past']
      )}
    >
      <div className={styles['step__circle']}>
        {currentStep > number ? (
          <div className={styles['step__circle__tick']}>
            <TickIcon style={{ width: '12px' }} />
          </div>
        ) : (
          <div className={styles['step__circle__number']}>
            <p className="text text--tiny text--bold">{number}</p>
          </div>
        )}
      </div>

      <div className={styles['step__label']}>
        <p
          className={classNames(
            styles['step__label__text'],
            styles['step__label__text--original'],
            'text text--caption'
          )}
        >
          {label}
        </p>

        <p
          className={classNames(
            styles['step__label__text'],
            styles['step__label__text--current'],
            'text text--caption'
          )}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

export default CompleteProfileHeaderStep;
