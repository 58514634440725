import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './TitleWithSecTitle.module.scss';

interface TitleWithSecTitleProps {
  title: string | React.ReactElement;
  titleSize?: 'h2' | 'h3';
  secTitle?: string;
  text?: string | React.ReactElement;
  titleColor?: string;
  secTitleColor?: string;
  textAlign?: 'center' | 'left';
}

const TitleWithSecTitle: FunctionComponent<TitleWithSecTitleProps> = ({
  title,
  titleSize = 'h2',
  secTitle,
  text,
  titleColor,
  secTitleColor,
  textAlign,
}) => {
  return (
    <div className={classNames(styles['container'], styles[`container--text-align-${textAlign}`])}>
      {secTitle && (
        <div className={classNames(styles['sec-title'])}>
          <p className="text text--caps" style={{ color: secTitleColor }}>
            {secTitle}
          </p>
        </div>
      )}
      <div className={classNames(styles['title'])}>
        {titleSize === 'h2' && (
          <h2 className="text text--h2" style={{ color: titleColor }}>
            {title}
          </h2>
        )}

        {titleSize === 'h3' && (
          <h3 className="text text--h3" style={{ color: titleColor }}>
            {title}
          </h3>
        )}
      </div>

      {text && (
        <div className={classNames(styles['text'])}>
          <p className="text text--body-1">{text}</p>
        </div>
      )}
    </div>
  );
};

export default TitleWithSecTitle;
