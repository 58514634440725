import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './Grid.module.scss';

export interface GridProps {
  itemsInRow?: 5 | 4 | 3 | 2;
  gridVerticalMargin?: 1 | 2 | 3;
  gridHorizontalMargin?: 1 | 2 | 3;
  itemGroup: React.ReactElement[] | undefined;
  className?: string;
}

const Grid: FunctionComponent<GridProps> = ({
  itemsInRow = 3,
  gridVerticalMargin = 1,
  gridHorizontalMargin = 1,
  itemGroup,
  className,
}) => {
  return (
    <div className={classNames('g-grid', styles['grid-outer'])}>
      <div
        className={classNames(
          styles['grid'],
          styles[`grid--size-${itemsInRow}`],
          styles[`grid--vertical-margin-${gridVerticalMargin}`],
          styles[`grid--horizontal-margin-${gridHorizontalMargin}`],
          className
        )}
      >
        {itemGroup?.map((item, i) => (
          <div className={classNames(styles['grid__item'], 'g-grid__item')} key={i}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
