import { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import PopUp from '../PopUp/PopUp';
import classNames from 'classnames';
import { ReactComponent as InfoIcon } from '../../../asset/images/icons/info.svg';
import styles from './HowRocketFuelWorksPopUp.module.scss';
import { UserRoleEnum } from '../../../enums/user';
import RoutesEnum from '../../../enums/routes';
import { extractRoleFromUrl } from '../../../utils/role';
import NavigationLink from '../../links/NavigationLink/NavigationLink';

interface HowRocketFuelWorksPopUpProps {
  whatIsPopUpActive: boolean;
  changeWhatIsPopUpActiveState: (state: boolean) => void;
  userRole?: UserRoleEnum;
}

const HowRocketFuelWorksPopUp: FunctionComponent<HowRocketFuelWorksPopUpProps> = ({
  whatIsPopUpActive,
  changeWhatIsPopUpActiveState,
}) => {
  const handlePopUpClose = (state: boolean) => {
    changeWhatIsPopUpActiveState(state);
  };

  const userRole = extractRoleFromUrl();

  return (
    <>
      {createPortal(
        <PopUp
          className={classNames(styles['pop-up'])}
          active={whatIsPopUpActive}
          onPopUpStateChange={handlePopUpClose}
          popUpWidth="560"
        >
          <div className={styles['pop-up__icon-container']}>
            <InfoIcon style={{ fill: 'var(--color-main)', width: '24px' }} />
          </div>

          <p className={classNames('text text--h4')}>How Rocket Fuel works</p>

          <p className={classNames(styles['pop-up__text'], 'text text--body-2')}>
            Rocket Fuel is our loyalty credits system. Accumulate Rocket Fuel by participating in our ecosystem, then
            spend it on any of our products or services.
            <br />
            <br />
            Earn $5 in Rocket Fuel for every $100 spent. Earn an additional 1-5% Rocket Fuel by prepaying for it in
            one-time or monthly bundles. Get 2-5X Rocket Fuel multipliers by granting equity in your company. Use our{' '}
            <NavigationLink path={`/${userRole}/${RoutesEnum.PRICING}`}>pricing calculator</NavigationLink> to estimate
            your savings.
            <br />
            <br />
            For a comprehensive explanation, please refer to our{' '}
            <NavigationLink path={`/${RoutesEnum.TERMS_OF_USE}`}>Terms of Service</NavigationLink>. Rocket Fuel is
            intended to provide a fun and engaging way to grow your company while saving money. We’re always open to
            your feedback. Thanks!
          </p>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </>
  );
};

export default HowRocketFuelWorksPopUp;
