import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { UserRoleEnum } from '../../../../src/enums/user';
import styles from './HeroSectionWithImage.module.scss';
import Breadcrumbs from '../../links/Breadcrumbs/Breadcrumbs';

interface HeroSectionWithImageProps {
  title: string;
  text?: string;
  logo?: React.ReactElement;
  imageSrc?: string;
  breadcrumbs?: React.ReactElement;
  className?: string;
  userRole?: UserRoleEnum;
}

const HeroSectionWithImage: FunctionComponent<HeroSectionWithImageProps> = ({
  title,
  text,
  logo,
  imageSrc,
  breadcrumbs,
  className,
  userRole,
}) => {
  const currentRole = UserRoleEnum[userRole!];

  return (
    <section className={classNames(styles['section'], className)}>
      <div className="background"></div>

      <div className="wrapper type-1">
        <div className="content">
          <div className={styles['block-list']}>
            <div className={styles['block-list__item']}>
              <div className={styles['breadcrumbs']}>{breadcrumbs}</div>
              <div className={styles['title']}>
                <h1 className="text text--h1">{title}</h1>
              </div>

              {text && (
                <div className={styles['text']}>
                  <p className="text text--body-1">{text}</p>
                </div>
              )}

              {logo && <div className={styles['logo']}>{logo}</div>}
            </div>

            <div className={styles['block-list__item']}>
              {imageSrc && (
                <div className={styles['image']}>
                  <img src={imageSrc} alt="Case Study" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSectionWithImage;
