import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { UserRoleEnum } from '../../../../src/enums/user';
import { ReactComponent as HeroIll } from '../../../asset/images/hero_ill.svg';
import styles from './HeroSection.module.scss';
import { UserRolePlural } from '../../../data/role';
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from '../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import PreloadLine from '../../blocks/PreloadLine/PreloadLine';

interface HeroSectionProps {
  sectionVariation: 'var-0' | 'var-1' | 'var-1-a' | 'var-1-b' | 'var-1-c' | 'var-1-c-2' | 'var-1-c-3';
  sectionBackgroundColor?: BackgroundSectionTemplateColorType;
  className?: string;
  title: string | React.ReactElement;
  titleSize?: 'h1' | 'h2';
  secondaryTitle?: string | React.ReactElement;
  text: string | React.ReactElement;
  elementAfterText?: React.ReactElement;
  buttonGroup?: React.ReactElement[] | null;
  illustration?: React.ReactElement;
  customElement?: React.ReactElement;
  imageUrl?: string;
  elementAfterIll?: React.ReactElement;
  breadcrumbs?: React.ReactElement;
  wrapperClass?: 'type-1' | null;
  markTagColor?: 'main' | 'blue';
  userRole?: UserRoleEnum;
}
// c
const HeroSection: FunctionComponent<HeroSectionProps> = ({
  sectionVariation = 'var-0',
  sectionBackgroundColor,
  className,
  title,
  titleSize = 'h1',
  secondaryTitle,
  text,
  elementAfterText,
  buttonGroup,
  illustration,
  customElement,
  imageUrl,
  elementAfterIll,
  breadcrumbs,
  wrapperClass = 'type-1',
  markTagColor = 'main',
  userRole,
}) => {
  const currentRole = UserRolePlural[UserRoleEnum[userRole!]];

  return (
    <section
      className={classNames(
        styles['section'],
        styles[sectionVariation],
        styles[`section--mark-tag-color-${markTagColor}`],
        className
      )}
      style={{ minHeight: '100vh' }}
    >
      {sectionBackgroundColor ? (
        <BackgroundSectionTemplate color={sectionBackgroundColor}></BackgroundSectionTemplate>
      ) : (
        <div className="background">
          {!illustration && !customElement && !imageUrl && <div className={styles['bg-el']}>{/* <HeroIll /> */}</div>}
        </div>
      )}

      <div className={classNames('wrapper', wrapperClass)}>
        <div className="content">
          <div className={styles['block-list']}>
            <div className={styles['block-list__item']}>
              <div className={styles['breadcrumbs']} style={{ position: 'relative' }}>
                <PreloadLine type="white" />
              </div>

              {secondaryTitle && (
                <div className={styles['sec-title']} style={{ position: 'relative' }}>
                  <p className="text text--caps">{secondaryTitle}</p>
                  <PreloadLine type="white" />
                </div>
              )}

              <div className={styles['title']} style={{ position: 'relative' }}>
                <p className="text text--h1">{title}</p>
                <PreloadLine type="white" />
              </div>

              <div className={styles['text']} style={{ position: 'relative' }}>
                <p className="text text--body-1">{text}</p>
                <PreloadLine type="white" />
              </div>

              <div className={styles['button-group']} style={{ position: 'relative' }}>
                <PreloadLine type="white" />
              </div>
            </div>

            <div className={styles['block-list__item']} style={{ width: '100%' }}>
              <div className={styles['illustration']} style={{ width: '100%', height: '256px' }}>
                <PreloadLine type="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
