import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { ReactComponent as LockIcon } from '../../../asset/images/icons/lock.svg';
import { ReactComponent as EyeIcon } from '../../../asset/images/icons/eye.svg';
import classNames from 'classnames';
import styles from './InputPassword.module.scss';

interface InputPasswordProps {
  label?: string | React.ReactElement;
  value?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  color?: 'default' | 'gray';
  name?: string;
  error?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
}

const InputPassword: FunctionComponent<InputPasswordProps> = ({
  size = 'small',
  color = 'default',
  value,
  placeholder,
  label,
  name,
  error = false,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isFocused, setIsFocused] = useState(false);
  const [valueHidden, setValueHidden] = useState(true);

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange?.(e);
  };

  console.log('styles:', styles);

  const handleOnValueVisibilityToggle = () => {
    setValueHidden((prevState) => !prevState);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    } else {
      setInputValue('');
    }
  }, [value]);

  return (
    <label
      className={classNames(
        styles['input'],
        size === 'large' && styles['input--size-large'],
        color === 'gray' && styles['input--color-gray'],
        isFocused === true && styles['input--is-focused'],
        error === true && styles['input--has-error'],
        valueHidden === false && styles['input--value-hidden']
      )}
    >
      {label && (
        <div className={styles['input__label']}>
          <div className="text text--body-2 text--bold">{label}</div>
        </div>
      )}

      <div
        className={classNames(
          styles['input__container'],
          styles['input__container--icon-left'],
          styles['input__container--icon-right']
        )}
      >
        <div className={styles['input__container__icon-left']}>
          <LockIcon />
        </div>

        <input
          className={styles['input__container__original-input']}
          type={valueHidden === true ? 'password' : 'text'}
          value={inputValue}
          placeholder={placeholder}
          name={name}
          onChange={inputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        <div className={styles['input__container__custom-input']}></div>

        <div className={styles['input__container__icon-right']} onClick={handleOnValueVisibilityToggle}>
          <EyeIcon />
        </div>
      </div>
    </label>
  );
};

export default InputPassword;
