import { FunctionComponent, useState, useEffect } from 'react';
import styles from './SignUpTemplate.module.scss';
import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';
import SignUpSliderWithIllustration from './_parts/SignUpSliderWithIllustration/SignUpSliderWithIllustration';
import { ReactComponent as ButtonArrow } from '../../../asset/images/button_arrow.svg';
import { ReactComponent as Background } from '../../../asset/images/signup_bg.svg';
import whatIsAstronomicPopUpBG from '../../../asset/images/what_is_astronomic_pop_up_bg.jpg';
import PopUp from '../../../components/popups/PopUp/PopUp';
import MainButton from '../../../components/buttons/MainButton/MainButton';
import SectionDivider from '../../../components/sections/SectionDivider/SectionDivider';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import WhatIsAstronomicPopUp from '../../../components/popups/WhatIsAstronomicPopUp/WhatIsAstronomicPopUp';
import { useAppSelector } from '../../../redux/hooks';

interface SignUpTemplateProps {}

const SignUpTemplate: FunctionComponent<SignUpTemplateProps> = ({}) => {
  const isTablet = useMediaQuery('(max-width: 1279px)');
  const location = useLocation();
  const [from, setFrom] = useState<string>();
  const [secondRerender, setSecondRerender] = useState<Boolean>(false);

  useEffect(() => {
    if (location.state) {
      if (location.state.from) {
        setFrom(location.state.from);
      }
    }

    setSecondRerender(true);
  }, [location, secondRerender]);

  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const togglePopUp = () => {
    setIsPopUpActive(!isPopUpActive);
  };

  useGSAP(
    () => {
      const tl = gsap.timeline();

      if (isTablet) {
        tl.set('.gsap__ill', { y: -300 }, 'one');
        // tl.set('.gsap__ill', { x: -300 }, 'one');
      } else {
        tl.set('.gsap__ill', { x: -300 }, 'one');
      }

      tl.fromTo('.gsap__right-part', { opacity: 0 }, { duration: 0.3, opacity: 1, ease: 'power1.out' }, 'one')
        .fromTo(
          '.gsap__ill',
          { opacity: 0, scale: 1.38 },
          { duration: 0.4, opacity: 1, scale: 1.4, ease: 'power3.Out' },
          'one'
        )
        .to('.gsap__ill', { y: '+=6', repeat: 8, yoyo: true, duration: 0.075, ease: 'power3.inOut' }, 'one')

        .fromTo('.gsap__bg', { opacity: 0 }, { delay: 0.2, duration: 0.5, opacity: 1, ease: 'power1.out' }, 'two')
        .fromTo(
          '.gsap__left-part',
          { opacity: 0, x: 40 },
          { delay: 0.2, duration: 0.8, opacity: 1, x: 0, ease: 'power2.inOut' },
          'two'
        )
        .to('.gsap__ill', { y: '+=3', repeat: 8, yoyo: true, duration: 0.075, ease: 'power2.inOut' }, 'two')
        .to('.gsap__ill', { duration: 1, x: 0, y: 0, scale: 1, ease: 'power2.inOut' }, 'two')
        .to('.gsap__right-part', { duration: 0.1, overflow: 'hidden', ease: 'power1.out' }, 'three');
      tl.progress(1);

      if (from === 'startup') {
        tl.progress(0);
        tl.play();
      }
    },
    { dependencies: [from], scope: '.sign-up-template', revertOnUpdate: true }
  );

  return (
    <main className={classNames(styles['container'], 'template sign-up-template')}>
      <div className={classNames(styles['background'], 'background')}>
        <div className={classNames(styles['bg-1'], 'gsap__bg')}>
          <Background />
        </div>
      </div>

      {secondRerender && (
        <>
          <section className={classNames(styles['container__left-part'], 'gsap__left-part')}>
            {<Outlet context={{ togglePopUp }} />}
          </section>

          <section className={classNames(styles['container__right-part'], 'gsap__right-part')}>
            {<SignUpSliderWithIllustration changeWhatIsPopUpActiveState={togglePopUp} />}
          </section>
        </>
      )}

      <WhatIsAstronomicPopUp whatIsPopUpActive={isPopUpActive} changeWhatIsPopUpActiveState={togglePopUp} />
    </main>
  );
};

export default SignUpTemplate;
