import { TypedUseAppDispatch } from "../../redux/hooks";
import { getUserInfoAsyncThunk } from "../../redux/slices/user/thunks";
import { localStorageSetItem } from "../localStorageMethods";
import { LOCAL_STORAGE_KEYS } from "../../enums/localStorageKeys";
import { navigateIfPossible } from "../navigationUtils";

type NavigateParams = {
  navigate: (url: string) => void;
  navigateUrl: string;
};

type LoginFunction =
  | ((dispatch: TypedUseAppDispatch, accessToken: string, refreshToken: string, userId: string) => void)
  | ((dispatch: TypedUseAppDispatch, accessToken: string, refreshToken: string, userId: string, navigateParams?: NavigateParams) => void);

export const login: LoginFunction = async (dispatch, accessToken, refreshToken, userId, navigateParams) => {
  localStorageSetItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
  localStorageSetItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  localStorageSetItem(LOCAL_STORAGE_KEYS.USER_ID, userId);
  
  if (userId) {
    await dispatch(getUserInfoAsyncThunk(userId));
  }

  navigateIfPossible(navigateParams);
};