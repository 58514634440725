import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { ReactComponent as LensIcon } from '../../../asset/images/icons/lens.svg';
import classNames from 'classnames';
import styles from './PlacesAutocompleteInput.module.scss';

interface PlacesAutocompleteInputProps {
  label?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  error?: boolean;
  onChange?(value, locationId, error, errorMessage): void;
}

interface PlacesAutocompleteInputState {
  address: string;
  isFocused: boolean;
}

class PlacesAutocompleteInput extends React.Component<PlacesAutocompleteInputProps, PlacesAutocompleteInputState> {
  constructor(props: PlacesAutocompleteInputProps) {
    super(props);
    this.state = {
      address: '',
      isFocused: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange(address: string) {
    this.setState({ address });
  }

  handleSelect(address: string, placeId: string) {
    this.setState({ address });

    geocodeByAddress(address)
      .then((results) => {
        // console.log(results);
        // console.log(results[0].place_id);

        // console.log('City', results[0].address_components[0].long_name);
        // console.log('State', results[0].address_components[2].long_name);
        // console.log('Country', results[0].address_components[3].long_name);

        let value = '';
        let locationId = '';
        let error = false;
        let errorMessage = '';

        const buildResponse = () => {
          if (results[0].address_components.length < 3) {
            error = true;
            errorMessage = 'Please, select your city';
            return;
          }

          value = `${results[0].address_components[0].long_name}, ${results[0].address_components[2].long_name}, ${results[0].address_components[3].long_name}`;
          locationId = results[0].place_id;
        };

        buildResponse();

        if (this.props.onChange) {
          this.props.onChange(value, locationId, error, errorMessage);
        }

        return getLatLng(results[0]);
      })
      .then((latLng) => {})
      .catch((error) => console.error('Error', error));
  }

  render() {
    const { label, placeholder, size, error } = this.props;
    const { address, isFocused } = this.state;
    const { handleChange, handleSelect } = this;

    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        debounce={500}
        searchOptions={{
          //   types: ['locality', 'administrative_area_level_1', 'country'],
          //   types: ['(cities)'],
          //Most correct - types: ['locality', 'administrative_area_level_1'],
          // types: ['locality', 'administrative_area_level_1', 'postal_code'],
          language: 'en',
          types: ['locality', 'administrative_area_level_1'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={styles['main-container']}>
            <label
              className={classNames(
                styles['input'],
                isFocused === true && styles['input--is-focused'],
                size === 'large' && styles['input--size-large'],
                error === true && styles['input--has-error']
              )}
            >
              {label && (
                <div className={styles['input__label']}>
                  <div className="text text--body-2 text--bold">{label}</div>
                </div>
              )}

              <div className={styles['input__container']}>
                <div className={styles['input__container__icon-left']}>
                  <LensIcon style={{ width: '22px', fill: 'var(--color-gray-700)' }} />
                </div>

                <input
                  {...getInputProps({
                    placeholder: placeholder,
                    className: styles['input__container__original-input'],
                    onFocus: () => {
                      this.setState({ isFocused: true });
                    },
                    onBlur: () => {
                      this.setState({ isFocused: false });
                    },
                  })}
                />
              </div>
            </label>

            {suggestions.length > 0 && (
              <div className={styles['autocomplete-dropdown-container']}>
                {suggestions.map((suggestion) => {
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: classNames(
                          styles['suggestion-item'],
                          suggestion.active && styles['suggestion-item--active']
                        ),
                      })}
                      key={suggestion.index}
                    >
                      <span className={classNames('text text--body-2')}>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default PlacesAutocompleteInput;
