import { EnhancedStore, combineReducers, configureStore } from '@reduxjs/toolkit';
import ApplicationReducer from './slices/application/slice';
import UserReducer from './slices/user/slice';
import ScrollReducer from './slices/scroll/slice';
import configuration from '../config';

const { ENV } = configuration.APP;

const mainReducer = combineReducers({
  application: ApplicationReducer,
  user: UserReducer,
  scroll: ScrollReducer,
});

export type AppState = ReturnType<typeof mainReducer>;

const store: EnhancedStore<AppState> = configureStore({
  reducer: mainReducer,
  devTools: ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;

export default store;
