import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './ChildrenWithHeaderSection.module.scss';
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from '../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';

interface ChildrenWithHeaderSectionProps extends PropsWithChildren {
  className?: string;
  title?: React.ReactElement;
  sectionVariation?: 0 | 1;
  backgroundColor?: BackgroundSectionTemplateColorType;
  backgroundChildren?: React.ReactElement;
  buttonGroup?: React.ReactElement[];
  buttonGroupCentered?: boolean;
  wrapperClass?: string;
  style?: any;
}

const ChildrenWithHeaderSection: FunctionComponent<ChildrenWithHeaderSectionProps> = ({
  className,
  title,
  sectionVariation = 0,
  backgroundColor,
  backgroundChildren,
  buttonGroup,
  buttonGroupCentered = false,
  wrapperClass,
  children,
  style,
}) => {
  return (
    <section
      className={classNames(styles['section'], styles[`section--var-${sectionVariation}`], className)}
      style={style}
    >
      <BackgroundSectionTemplate color={backgroundColor}>{backgroundChildren}</BackgroundSectionTemplate>

      <div className={classNames('wrapper', wrapperClass)}>
        <div className="content">
          {title && <div className={classNames(styles['title'])}>{title}</div>}

          <div className={classNames(styles['children-container'])}>{children}</div>

          {buttonGroup && (
            <div
              className={classNames(styles['button-group'], styles[`button-group--centered-${buttonGroupCentered}`])}
            >
              {buttonGroup.map((item, i) => (
                <div className={styles['button-group__item']} key={i}>
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChildrenWithHeaderSection;
