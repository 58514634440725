import { AxiosInstance } from 'axios';
import BaseAPI from '../base/api';
import { IUserDTO } from '../../dto/user';
import { ApiResponseDTO } from '../../dto/api';

class AuthApi extends BaseAPI {
  protected paths = {
    GET_PROTECTED_DATA: 'user/GetProtectedData',
    GET_USER_INFO: (userId: string) => `user/GetUserInfo/${userId}`,
    LOGIN: 'user/Login',
    REGISTER: 'user/createAccount',
    GET_USER_VERIFICATION_CODE: (userId: string) => `user/GetUserVerificationCode/${userId}/verificationCode`,
    REQUEST_VERIFICATION_CODE: 'user/RequestVerificationCode',
    VERIFY_ACCOUNT: 'user/VerifyAccount',
    SET_USER_PASSWORD: 'user/SetUserPassword',
    SET_USER_NAME_AND_LASTNAME: 'user/SetUserNameAndLastname',
    SET_USER_ROLES: 'user/SetUserRoles',
    GET_ROLES: (userId?: string) => `user/GetRoles?userId=${userId}`,
    GET_ROLE_INFO_FOR_USER: (userId: string, roleId: string) => `user/GetRoleInfoForUser/${userId}/role/${roleId}`,
    FORGOT_PASSWORD: 'user/ForgotPassword',
    RESET_PASSWORD: 'user/ResetPassword',
    REFRESH_TOKEN: 'user/RefreshToken',
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(axios: AxiosInstance, backend: string) {
    super(axios, backend);
  }

  async login(data) {
    return this.axios.post<string, any>(this.paths.LOGIN, data);
  }

  async getProtectedData() {
    return this.axios.get<string, any>(this.paths.GET_PROTECTED_DATA);
  }

  async getUserInfo(userId: string) {
    return this.axios.get<string, any>(this.paths.GET_USER_INFO(userId));
  }

  async register(data) {
    return this.axios.post<string, any>(this.paths.REGISTER, data);
  }

  async getUserVerificationCode(userId) {
    return this.axios.get<string, any>(this.paths.GET_USER_VERIFICATION_CODE(userId));
  }

  async requestVerificationCode(data) {
    return this.axios.post<string, any>(this.paths.REQUEST_VERIFICATION_CODE, data);
  }

  async verifyAccount(data) {
    return this.axios.patch<string, any>(this.paths.VERIFY_ACCOUNT, data);
  }

  async setUserPassword(data) {
    return this.axios.patch<string, any>(this.paths.SET_USER_PASSWORD, data);
  }

  async setUserNameAndLastname(data) {
    return this.axios.patch<string, any>(this.paths.SET_USER_NAME_AND_LASTNAME, data);
  }

  async setUserRoles(data) {
    return this.axios.post<string, any>(this.paths.SET_USER_ROLES, data);
  }

  async getRoles(userId?: string) {
    return this.axios.get<string, any>(this.paths.GET_ROLES(userId ? userId : ''));
  }

  async getRoleInfoForUser(userId: string, roleId: string) {
    return this.axios.get<string, any>(this.paths.GET_ROLE_INFO_FOR_USER(userId, roleId));
  }

  async forgotPassword(data: { email: string }) {
    return this.axios.post<ApiResponseDTO>(this.paths.FORGOT_PASSWORD, data);
  }

  async resetPassword(data: { email: string; resetPasswordToken: string; newPassword: string }) {
    return this.axios.post<ApiResponseDTO>(this.paths.RESET_PASSWORD, data);
  }

  async refreshToken(data: { userId: string; refreshToken: string }) {
    return this.axios.post<string, any>(this.paths.REFRESH_TOKEN, data);
  }
}

export default AuthApi;
