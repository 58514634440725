import React, { useEffect } from 'react';
import ApplicationRoutes from './routes';
import { Provider } from 'react-redux';
import store from './redux';
import ToastContainerWrapper from './containers/wrappers/ToastContainerWrapper/ToastContainerWrapper';

function App() {
  // TEMP STAGE PROTECTION
  // useEffect(() => {
  //   const pass = 'A$tron0mic';
  //   const savedPass = localStorage.getItem('stagePass');

  //   if (savedPass) {
  //     if (savedPass === pass) {
  //     } else {
  //       let person = prompt('Password', '');

  //       if (person === pass) {
  //         localStorage.setItem('stagePass', pass);
  //       } else {
  //         window.location.href = '/';
  //       }
  //     }
  //   } else {
  //     let person = prompt('Password', '');

  //     if (person === pass) {
  //       localStorage.setItem('stagePass', pass);
  //     } else {
  //       window.location.href = '/';
  //     }
  //   }
  // }, []);
  // TEMP STAGE PROTECTION

  return (
    <Provider store={store}>
      <ApplicationRoutes />
      <ToastContainerWrapper />
    </Provider>
  );
}

export default App;
