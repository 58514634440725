import { createSlice } from "@reduxjs/toolkit";

export type IApplicationSliceType = {};

const initial_state: IApplicationSliceType = {};

const application_slice = createSlice({
  name: "application",
  initialState: initial_state,
  reducers: {},
});

export default application_slice.reducer;

export const {} = application_slice.actions;
