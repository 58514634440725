import { FunctionComponent, useState, useEffect, ChangeEvent } from 'react';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import { ReactComponent as PersonIcon } from '../../../../../asset/images/icons/person.svg';
import { ReactComponent as PlusIcon } from '../../../../../asset/images/icons/plus.svg';
import { ReactComponent as AccountIcon } from '../../../../../asset/images/icons/account.svg';
import { ReactComponent as UploadIcon } from '../../../../../asset/images/icons/upload.svg';
import { ReactComponent as MailIcon } from '../../../../../asset/images/icons/mail.svg';
import { ReactComponent as EyeIcon } from '../../../../../asset/images/icons/eye.svg';
import { ReactComponent as TrashCanIcon } from '../../../../../asset/images/icons/trash_can.svg';
import { ReactComponent as LensIcon } from '../../../../../asset/images/icons/lens.svg';
import classNames from 'classnames';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import styles from './AccountProfile.module.scss';
import Grid from '../../../../../components/blocks/Grid/Grid';
import Label from '../../../../../components/texts/Label/Label';
import Input from '../../../../../components/inputs/Input/Input';
import { createPortal } from 'react-dom';
import PopUp from '../../../../../components/popups/PopUp/PopUp';
import { useLocation, useNavigate } from 'react-router-dom';
import PlacesAutocompleteInput from '../../../../../components/inputs/PlacesAutocompleteInput/PlacesAutocompleteInput';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import api from '../../../../../api';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import RoutesEnum from '../../../../../enums/routes';
import ComingSoonMessage from '../../../../../components/texts/ComingSoonMessage/ComingSoonMessage';
import InputMessage from '../../../../../components/texts/InputMessage/InputMessage';
import { hasOnlyEnglishCharacters, isLinkedInURL, isPostalCode } from '../../../../../utils/regex';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';
import useFormSubmitLoader from '../../../../../hooks/useFormSubmitLoader';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import { getUserInfoAsyncThunk } from '../../../../../redux/slices/user/thunks';
import { localStorageGetItem } from '../../../../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../../../../enums/localStorageKeys';
import PreloadProductCard from '../../../../../components/blocks/PreloadProductCard/PreloadProductCard';

interface AccountProfileProps {}

const AccountProfile: FunctionComponent<AccountProfileProps> = () => {
  const navigate = useNavigate();
  const { isLoading, handleFormSubmitLoader } = useFormSubmitLoader();
  const dispatch = useAppDispatch();

  const [roles, setRoles] = useState<Array<any>>([]);
  const [deleteAccPopUpActive, setDeleteAccPopUpActive] = useState(false);
  const changeDeleteAccPopUpActiveState = (state) => {
    setDeleteAccPopUpActive(state);
  };

  const location = useLocation();

  // Global state selection
  const userId = useAppSelector((state) => state.user.user?.userId);
  const firstNameGlobalState = useAppSelector((state) => state.user.user?.firstName);
  const lastNameGlobalState = useAppSelector((state) => state.user.user?.lastName);
  const emailGlobalState = useAppSelector((state) => state.user.user?.email);
  const cityGlobalState = useAppSelector((state) => state.user.user?.city);
  const zipCodeGlobalState = useAppSelector((state) => state.user.user?.zipCode);
  const userIdGlobalState = useAppSelector((state) => state.user.user?.userId as string);

  // Local state with initial values from global state
  const [firstName, setFirstName] = useState(firstNameGlobalState || '');
  const [lastName, setLastName] = useState(lastNameGlobalState || '');
  const [linkedIn, setLinkedIn] = useState('');
  const [city, setCity] = useState(cityGlobalState || '');
  const [locationId, setLocationId] = useState('');
  const [postalCode, setPostalCode] = useState(zipCodeGlobalState || '');

  // Error state
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [linkedInErrorMessage, setLinkedInErrorMessage] = useState('');
  const [cityErrorMessage, setCityErrorMessage] = useState('');
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);

  // Effect to sync local state with global state on mount or when global state changes
  useEffect(() => {
    setFirstName(firstNameGlobalState || '');
    setLastName(lastNameGlobalState || '');
    setCity(cityGlobalState || '');
    setPostalCode(zipCodeGlobalState || '');
  }, [firstNameGlobalState, lastNameGlobalState, cityGlobalState, zipCodeGlobalState]);

  // Change handlers
  const handleFirstNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNameErrorMessage('');
    setFirstNameError(false);
    setFirstName(e.target.value);
  };

  const handleLastNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastNameErrorMessage('');
    setLastNameError(false);
    setLastName(e.target.value);
  };

  const handleLinkedInOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkedInErrorMessage('');
    setLinkedInError(false);
    setLinkedIn(e.target.value);
  };

  const handleCityOnChange = (value, locationId, error, errorMessage) => {
    setCity(value);
    setLocationId(locationId);
    setCityError(error);
    setCityErrorMessage(errorMessage);
  };

  const handlePostalCodeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostalCodeErrorMessage('');
    setPostalCodeError(false);
    setPostalCode(e.target.value);
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let formIsValidated = true;

    setFirstNameErrorMessage('');
    setLastNameErrorMessage('');
    setLinkedInErrorMessage('');
    setPostalCodeErrorMessage('');
    setFirstNameError(false);
    setLastNameError(false);
    setLinkedInError(false);
    setPostalCodeError(false);

    if (firstName === '') {
      setFirstNameErrorMessage('Enter your first name');
      setFirstNameError(true);
      formIsValidated = false;
    } else if (firstName.length >= 20) {
      setFirstNameErrorMessage('Maximum 20 characters');
      setFirstNameError(true);
      formIsValidated = false;
    } else if (firstName.length <= 1) {
      setFirstNameErrorMessage('Minimum 2 characters');
      setFirstNameError(true);
      formIsValidated = false;
    } else if (!hasOnlyEnglishCharacters(firstName)) {
      setFirstNameErrorMessage('Should contain only english characters');
      setFirstNameError(true);
      formIsValidated = false;
    }

    if (lastName === '') {
      setLastNameErrorMessage('Enter your last name');
      setLastNameError(true);
      formIsValidated = false;
    } else if (lastName.length >= 20) {
      setLastNameErrorMessage('Maximum 20 characters');
      setLastNameError(true);
      formIsValidated = false;
    } else if (lastName.length <= 1) {
      setLastNameErrorMessage('Minimum 2 characters');
      setLastNameError(true);
      formIsValidated = false;
    } else if (!hasOnlyEnglishCharacters(lastName)) {
      setLastNameErrorMessage('Should contain only english characters');
      setLastNameError(true);
      formIsValidated = false;
    }

    if (!isLinkedInURL(linkedIn) && linkedIn !== '') {
      setLinkedInErrorMessage('Please, enter correct LinkedIn URL');
      setLinkedInError(true);
      formIsValidated = false;
    }

    if (postalCode === '') {
      setPostalCodeErrorMessage('Enter your postal code');
      setPostalCodeError(true);
      formIsValidated = false;
    } else if (!isPostalCode(postalCode)) {
      setPostalCodeErrorMessage('Enter valid postal code');
      setPostalCodeError(true);
      formIsValidated = false;
    }

    if (city === '') {
      setCityErrorMessage('Please, select your city');
      setCityError(true);
      formIsValidated = false;
    }

    // API LOGIC
    await handleFormSubmitLoader(async () => {
      if (formIsValidated === true) {
        try {
          const response = await api.Auth.setUserNameAndLastname({
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            city: city,
            zipCode: postalCode,
            locationId: locationId ? locationId : null,
            linkedIn: linkedIn ? linkedIn : '',
          });

          const responseData = response.data as ApiResponseDTO;

          if (responseData.success === true && responseData.data) {
            callToast({
              title: 'Personal Information',
              children: 'Has been successfully updated!',
              variation: 'success',
            });

            await dispatch(getUserInfoAsyncThunk(userId as string));
          } else {
            console.error('Error:', response.error);
          }
        } catch (error) {
          const errorObj = error as AxiosError<ApiResponseDTO>;
          const errorData = errorObj.response?.data;

          if (errorData?.errorMessage) {
            callToast({
              title: 'Error',
              children: errorData?.errorMessage,
              variation: 'error',
            });
          }

          console.error('Error: ', error);
        }
      }
    });

    // API LOGIC END
  };

  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);

      if (anchor) {
        const headerHeight = 120;
        window.scrollTo({
          top: anchor.getBoundingClientRect().top + window.scrollY - headerHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Auth.getRoles(userIdGlobalState);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setRoles(responseData.data);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          alert(errorData.errorMessage);
        }

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  return (
    <div className={styles['profile']}>
      <div className={styles['roles']}>
        <header className={styles['roles__header']}>
          <TitleWithIcon
            className={styles['title']}
            icon={<PersonIcon style={{ fill: 'var(--color-main)', width: '24px' }} />}
          >
            <h3 className={'text text--h3'}>Roles</h3>
          </TitleWithIcon>

          <MainButton
            className={styles['roles__header__add-company-button']}
            sizeType="medium"
            visualType="white"
            iconLeft={<PlusIcon />}
            onClick={() => {
              navigate(`/account/profile/role/add`);
            }}
          >
            Add role
          </MainButton>
        </header>

        <div className={styles['roles__list']}>
          {roles && roles?.length > 0 ? (
            <Grid
              itemsInRow={3}
              gridHorizontalMargin={3}
              itemGroup={roles
                .filter((item) => item.hasRole === true)
                .map((item, i) => (
                  <div className={classNames(styles['roles__list__item'], 'tile tile--no-shadow')} key={item.roleId}>
                    <p className="text text--h5">{item.roleName}</p>

                    <MainButton
                      className={styles['roles__list__item__button']}
                      visualType="white"
                      sizeType="medium"
                      onClick={() =>
                        navigate(
                          `/${RoutesEnum.ACCOUNT}/${RoutesEnum.PROFILE}/${RoutesEnum.ROLE}?roleId=${item.roleId}&title=${item.roleName}`
                        )
                      }
                    >
                      View details
                    </MainButton>
                  </div>
                ))}
            />
          ) : (
            <Grid
              itemsInRow={3}
              gridHorizontalMargin={3}
              itemGroup={[1, 2, 3, 4, 5, 6].map((item, i) => (
                <PreloadProductCard showText={false} key={i} />
              ))}
            />
          )}
        </div>
      </div>

      <div className={styles['account']} id="account">
        <TitleWithIcon
          className={styles['title']}
          icon={<AccountIcon style={{ fill: 'var(--color-main)', width: '24px' }} />}
        >
          <h3 className={'text text--h3'}>Account</h3>
        </TitleWithIcon>
      </div>

      <div
        className={classNames(styles['account__personal-info'], 'tile tile--no-shadow tile--padding-32px')}
        style={{ position: 'relative' }}
      >
        <p className={classNames(styles['title-2'], 'text text--caps-small')}>Personal Information</p>

        <div className={styles['account__personal-info__avatar-container']}>
          <div className={styles['account__personal-info__avatar-container__image']}>
            <img src={require('../../../../../asset/images/avatar.png')} alt="Avatar" />
          </div>

          <div className={styles['account__personal-info__avatar-container__input']}>
            <MainButton
              className={styles['account__personal-info__avatar-container__input__button']}
              visualType="white"
              sizeType="medium"
              iconLeft={<UploadIcon />}
              disabled
            >
              Upload Photo
            </MainButton>

            <p
              className={classNames(styles['account__personal-info__avatar-container__input__tip'], 'text text--tiny')}
            >
              Max 500x500px .PNG or .JPEG
            </p>
          </div>
        </div>

        <div className={classNames(styles['account__personal-info__input-group'], 'input-layout')}>
          <div className={styles['account__personal-info__input-list']}>
            <div className="input-layout__row input-layout__row--two-col">
              <div className="input-layout__row__col">
                <Input
                  label="First Name"
                  size="large"
                  value={firstName}
                  error={firstNameError}
                  onChange={handleFirstNameOnChange}
                />

                {firstNameErrorMessage && <InputMessage>{firstNameErrorMessage}</InputMessage>}
              </div>

              <div className="input-layout__row__col">
                <Input
                  label="Last Name"
                  size="large"
                  value={lastName}
                  error={lastNameError}
                  onChange={handleLastNameOnChange}
                />

                {lastNameErrorMessage && <InputMessage>{lastNameErrorMessage}</InputMessage>}
              </div>
            </div>

            <div className="input-layout__row input-layout__row--two-col input-layout__row--medium-margin">
              <div className="input-layout__row__col">
                <Input
                  label="Email"
                  size="large"
                  name="email"
                  value={emailGlobalState}
                  disabled={true}
                  iconLeft={<MailIcon style={{ width: '22px', fill: 'var(--color-gray-700)' }} />}
                />
              </div>

              <div className="input-layout__row__col">
                <Input
                  label="Your LinkedIn"
                  placeholder="LinkedIn URL"
                  size="large"
                  value={linkedIn}
                  error={linkedInError}
                  onChange={handleLinkedInOnChange}
                />
                {linkedInErrorMessage && <InputMessage>{linkedInErrorMessage}</InputMessage>}
              </div>
            </div>

            <div className="input-layout__row input-layout__row--flexible-col input-layout__row--medium-margin">
              <div className="input-layout__row__col">
                <PlacesAutocompleteInput
                  label="City"
                  size="large"
                  placeholder={cityGlobalState}
                  error={cityError}
                  onChange={handleCityOnChange}
                />
                {cityErrorMessage && <InputMessage>{cityErrorMessage}</InputMessage>}
              </div>

              <div className="input-layout__row__col" style={{ maxWidth: '190px' }}>
                <Input
                  label="Postal Code"
                  size="large"
                  value={postalCode}
                  error={postalCodeError}
                  onChange={handlePostalCodeOnChange}
                />

                {postalCodeErrorMessage && <InputMessage>{postalCodeErrorMessage}</InputMessage>}
              </div>
            </div>
          </div>
        </div>

        <MainButton
          className={styles['account__submit']}
          sizeType="medium"
          disabled={isLoading}
          onClick={handleOnSubmit}
        >
          Save Changes
        </MainButton>

        <CSSTransitionWrapper onEnter={isLoading} styleVariation="onForeground">
          <AstronomicLoader variation="blurredBackground" color="default" />
        </CSSTransitionWrapper>
      </div>

      <div className={classNames(styles['account__change-pass'], 'tile tile--no-shadow tile--padding-32px')}>
        <p className={classNames(styles['title-2'], 'text text--caps-small')}>Change password</p>

        <div className={classNames(styles['account__change-pass__input-group'], 'input-layout')}>
          <div className={styles['account__change-pass__input-list']}>
            <div className="input-layout__row input-layout__row--two-col">
              <div className="input-layout__row__col">
                <Input
                  label="New password"
                  size="large"
                  name="pass_1"
                  value="!sdfk4K"
                  type="password"
                  iconRight={<EyeIcon />}
                />
              </div>

              <div className="input-layout__row__col">
                <Input
                  label="Repeat new password"
                  size="large"
                  name="pass_1"
                  value="!sdfk4K"
                  type="password"
                  iconRight={<EyeIcon />}
                />
              </div>
            </div>
          </div>
        </div>

        <MainButton className={styles['account__submit']} sizeType="medium" disabled>
          Save Changes
        </MainButton>
      </div>

      <MainButton
        className={styles['delete-account']}
        visualType="white"
        iconLeft={<TrashCanIcon />}
        onClick={() => {
          changeDeleteAccPopUpActiveState(true);
        }}
      >
        Delete account
      </MainButton>

      {/* <ComingSoonMessage type="overlay" /> */}

      {createPortal(
        <PopUp
          className={styles['delete-acc-pop-up']}
          active={deleteAccPopUpActive}
          onPopUpStateChange={changeDeleteAccPopUpActiveState}
          footer={
            <div className={styles['delete-acc-pop-up__footer-button-list']}>
              <div className={styles['delete-acc-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="main"
                  onClick={() => {
                    changeDeleteAccPopUpActiveState(false);
                  }}
                >
                  Keep my account
                </MainButton>
              </div>

              <div className={styles['delete-acc-pop-up__footer-button-list__item']}>
                <MainButton visualType="white" disabled>
                  Delete account
                </MainButton>
              </div>
            </div>
          }
        >
          <div className={styles['delete-acc-pop-up__icon-container']}>
            <TrashCanIcon style={{ fill: 'var(--color-red-400)', width: '30px' }} />
          </div>

          <p className={classNames(styles['delete-acc-pop-up__title'], 'text text--h4')}>Delete account</p>

          <p
            className={classNames(
              styles['delete-acc-pop-up__text'],
              styles['delete-acc-pop-up__text-1'],
              'text text--body-2'
            )}
          >
            Are you sure you want to delete your account? <b>We're sad to see you go</b>! By deleting you account,
            you'll miss out on:
          </p>

          <p
            className={classNames(
              styles['delete-acc-pop-up__text'],
              styles['delete-acc-pop-up__text-2'],
              'text text--body-2'
            )}
          >
            1. Earned Rocket Fuel Credits for discounts and services. <br />
            2. Exclusive discounts and promotions. <br />
            3. Expert Services to help scale your business.
          </p>

          <p
            className={classNames(
              styles['delete-acc-pop-up__text'],
              styles['delete-acc-pop-up__text-3'],
              'text text--body-2'
            )}
          >
            Consider the long-term benefits you'll be forfeiting.{' '}
            <b>If you have any concerns, please contact our support team</b>. We value your presence in our community
            and hope you'll reconsider.
          </p>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </div>
  );
};

export default AccountProfile;
