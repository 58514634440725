import { FunctionComponent } from 'react';
import classNames from 'classnames';
import BackgroundSectionTemplate from '../../../../../components/sections/sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';
import OnlyIconButton from '../../../../../components/buttons/OnlyIconButton/OnlyIconButton';
import { ReactComponent as PinIcon } from '../../../../../asset/images/icons/pin.svg';
import { ReactComponent as EditIcon } from '../../../../../asset/images/icons/edit.svg';
import styles from './AccountHeader.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/hooks';
import BalanceAndAccStatus from '../../../../../components/blocks/BalanceAndAccStatus/BalanceAndAccStatus';

interface AccountHeaderProps {}

const AccountHeader: FunctionComponent<AccountHeaderProps> = () => {
  const navigate = useNavigate();

  const userFirstName = useAppSelector((state) => state.user.user?.firstName);
  const userLastName = useAppSelector((state) => state.user.user?.lastName);
  const city = useAppSelector((state) => state.user.user?.city);
  const rocketFuelBalance = useAppSelector((state) => state.user.user?.rocketFuelBalance);

  return (
    <section className={styles['account-header']}>
      <BackgroundSectionTemplate className={styles['account-header__bg']} color={'blue'} />

      <div className={classNames('wrapper', 'type-1')}>
        <div className="content">
          <div className={styles['block-list']}>
            <div className={styles['block-list__item-1']}>
              <div className={styles['avatar']}>
                <img src={require('../../../../../asset/images/avatar.png')} alt="Avatar" />
              </div>

              <div className={styles['account-info']}>
                <div className={styles['account-info__name-container']}>
                  <h3 className={classNames(styles['account-info__name-container__text'], 'text text--h3')}>
                    {userFirstName} {userLastName}
                  </h3>

                  <OnlyIconButton
                    className={styles['account-info__name-container__edit-button']}
                    icon={<EditIcon />}
                    visualType="blank"
                    onClick={() => navigate('/account/profile#account')}
                  />
                </div>

                <div className={styles['account-info__location-container']}>
                  <PinIcon className={styles['account-info__location-container__icon']} />

                  <p className={classNames(styles['account-info__location-container__text'], 'text text--body-2')}>
                    {city}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles['block-list__item-2']}>
              <BalanceAndAccStatus />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountHeader;
