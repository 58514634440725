import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import MainButton from '../../components/buttons/MainButton/MainButton';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import { ReactComponent as PricingIll } from '../../asset/images/pricing_ill.svg';

import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';
import { ReactComponent as ShareIcon } from '../../asset/images/icons/share.svg';

import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people_2.svg';
import { ReactComponent as ThumbsUpIcon } from '../../asset/images/icons/thumbs_up_2.svg';
import { ReactComponent as ThumbsUp2Icon } from '../../asset/images/icons/thumbs_up_3.svg';
import { ReactComponent as CaseIcon } from '../../asset/images/icons/case_2.svg';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import HowItWorksSection from '../../components/sections/HowItWorksSection/HowItWorksSection';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import AccordionItem from '../../components/lists/AccordionItem/AccordionItem';
import RoutesEnum from '../../enums/routes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import { GENERAL_CONTENT } from '../../data/generalContent';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';
import PricingIllBlocks from '../../components/blocks/PricingIllBlocks/PricingIllBlocks';
import { UserRolePlural } from '../../data/role';
import { UserRoleEnum } from '../../enums/user';
import { setShouldScroll } from '../../redux/slices/scroll/slice';
import { useNavigate } from 'react-router-dom';
import styles from './PricingView.module.scss';

interface PricingViewProps extends IWithUserRoleProps {}

const PricingView: FunctionComponent<PricingViewProps> = ({ user_role: userRole, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentRolePlural = UserRolePlural[UserRoleEnum[userRole!]];

  const handleRoleChange = () => {
    const roleKeys = Object.keys(UserRoleEnum) as Array<keyof typeof UserRoleEnum>;

    const getNextRole = (currentRole): UserRoleEnum => {
      const currentIndex = roleKeys.indexOf(currentRole);

      // Calculate the next index, wrapping around if necessary
      const nextIndex = (currentIndex + 1) % roleKeys.length;

      return UserRoleEnum[roleKeys[nextIndex]]; // Return the next role name
    };

    const nextRole = getNextRole(userRole);
    // Assuming the URL structure is /roleName
    const nextUrl = `/${nextRole.toLowerCase().replace(/\s+/g, '-')}/${RoutesEnum.PRICING}`; // Create URL-friendly string

    dispatch(setShouldScroll(false));

    navigate(nextUrl);
  };

  return (
    <MainTemplateForMainPages>
      <HeroSection
        secondaryTitle="Pricing"
        title="Pricing, Rocket Fuel, & Equity"
        text="Whether you pay with cash, earn rewards with Rocket Fuel, or achieve steep discounts with equity, our goal is to make it as affordable and scalable as possible for you to grow your company as quickly and meaningfully as possible."
        sectionVariation="var-1"
        illustration={<PricingIll style={{ marginRight: 'auto', marginLeft: 'auto', maxHeight: '512px' }} />}
        buttonGroup={[<JoinForFreeButton />]}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title={
              <>
                How our pricing works for{' '}
                <span className={styles['title-with-role__mark']} onClick={handleRoleChange}>
                  {currentRolePlural}
                </span>
              </>
            }
            text="Pay with cash and get discounts with Rocket Fuel."
          />
        }
        backgroundColor="Background-Neutral-Primary"
      >
        <PricingIllBlocks />
      </ChildrenWithHeaderSection>

      <SectionDivider color="Background-Neutral-Primary" style={{ display: 'none' }} />

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Pricing Calculator"
            text="Use our pricing calculator to determine how much to pay for our products and services."
          />
        }
        backgroundColor="Background-Neutral-Primary"
        style={{ marginTop: '60px' }}
      >
        <div className={styles['calc-image']}>
          <img src={require('../../asset/images/calculator.png')} alt="" />
        </div>

        <p className="text text--caption" style={{ marginTop: '62px' }}>
          *Alternate pricing available. See product page for details. <br />
          **Equity rewards remain in effect for 1 year as a protection against users creating shell companies to achieve
          widespread discounts. Sharing equity is a risk to both you and to us. See our terms of service for details.
        </p>
      </ChildrenWithHeaderSection>

      {/* <HowItWorksSection
        title="How it works"
        textUnderTitle="Sit amet eu ullamcorper commodo mauris lectus dapibus mauris. Sem nunc elementum."
        items={[
          {
            title: 'Contributors',
            text: (
              <>
                About 2/3 of the way through each program, companies pledge <b>1% of their future equity</b> in the form
                of a Warrant.
              </>
            ),
            icon: <TimeIcon style={{ width: '37px', fill: 'var(--color-main)' }} />,
          },
          {
            title: 'Sharing',
            text: 'From that 2.5% share, any future financial returns from a liquidity event (for example, an IPO or acquisition) are distributed back to those who helped you, with the majority returned to your local ecosystem to support local growth.',
            icon: <ShareIcon />,
          },
          {
            title: '.5% Mentors',
            text: 'For providing feedback and guidance both during and after the mentorship program.',
          },
          {
            title: '1% Local Leaders',
            text: 'For running the local program and building the Astronomic community.',
          },
          {
            title: '1% Astronomic HQ',
            text: 'For providing assistance for years to come.',
          },
        ]}
      /> */}

      {/* <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            title="Why Shared Equity?"
            text="Sit amet eu ullamcorper commodo mauris lectus dapibus mauris. Sem nunc elementum."
          />
        }
        buttonGroup={[<JoinForFreeButton />]}
        buttonGroupCentered={true}
      >
        <Grid
          itemsInRow={2}
          gridVerticalMargin={!isUserLoggedIn ? 2 : 2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Aliquam ut lectus"
              text="Egestas sed vestibulum rutrum tortor natoque. Sed cursus tortor placerat quis. Viverra at et orci enim accumsan non dignissim. Sit venenatis maecenas eget arcu orci aliquam. Aliquam tortor nisl."
              icon={<PeopleIcon />}
              iconBgColor="#F4B84020"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Nisl molestie congue"
              text="In elit sed sed vitae non nibh faucibus. Cras donec posuere sed eu ornare luctus nibh viverra egestas. Mattis tempor in penatibus cras purus. Ut lorem faucibus in at bibendum. Vestibulum dolor neque."
              icon={<ThumbsUpIcon />}
              iconBgColor="#EA6B3D20"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Convallis in velit"
              text="Volutpat enim a senectus justo aliquam commodo rhoncus ullamcorper. Sed sit duis varius pharetra placerat nisi in. Sapien egestas elit quis justo urna pretium. Eget turpis sit turpis pulvinar id."
              icon={<ThumbsUp2Icon />}
              iconBgColor="#7AD2E720"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
            <TitleWithIconAndTextCard
              title="Et massa nisi aenean"
              text="Duis tellus at scelerisque malesuada diam odio scelerisque pellentesque. Suspendisse facilisis faucibus quisque massa fringilla vitae. Adipiscing sociis quam metus metus et massa laoreet."
              icon={<CaseIcon />}
              iconBgColor="#80D0B820"
              // link={!isUserLoggedIn ? `/${RoutesEnum.SIGN_UP}` : null}
              cardVariation="var-1"
            />,
          ]}
        />
      </ChildrenWithHeaderSection> */}

      <SectionDivider color="Background-Neutral-Secondary" />

      <ChildrenWithHeaderSection
        title={
          <SimpleTitle>
            <p className="text text--h2">Frequently Asked Questions</p>
          </SimpleTitle>
        }
        buttonGroupCentered={true}
        backgroundColor="Background-Neutral-Secondary"
      >
        <AccordionItem title="1. How does Astronomic compare to accelerators like Techstars and Y Combinator?">
          Astronomic’s full-lifecycle “cofounder-as-a-service” model stands apart from traditional accelerators like
          Techstars and Y Combinator by offering continuous, scalable support from concept to acquisition. While
          Techstars and Y Combinator provide intensive short-term programs with Demo Day as the milestone, their ongoing
          engagement typically consists of alumni events and network access rather than active, hands-on involvement in
          a company’s growth.
          <br />
          <br />
          Additionally, founders cannot reapply or grant more equity to access further support after completing these
          programs. In contrast, Astronomic enables founders to remain engaged indefinitely by granting equity or
          purchasing Rocket Fuel, giving them access to tailored services throughout their journey. This flexibility
          ensures founders can scale support to meet their needs at any stage, making Astronomic a long-term partner
          rather than a one-time boost.
        </AccordionItem>
        <AccordionItem title="2. What is Rocket Fuel, and how does it work?">
          Rocket Fuel is our digital currency that you can use to pay for all Astronomic services, including
          professional services, curated mansion dinners, and contact data for sales prospecting. Every $1 of Rocket
          Fuel equals $1 in value toward any of our offerings. You can earn Rocket Fuel by spending on our website,
          prepaying for credits, or granting us equity in your company.
        </AccordionItem>
        <AccordionItem title="3. How do I earn Rocket Fuel?">
          You earn $5 in Rocket Fuel for every $100 spent on our website. If you prepay for Rocket Fuel or grant equity,
          you can unlock additional rewards. For example, prepaying $1,000 might earn you bonus Rocket Fuel, and
          granting 1%, 2%, or 3% equity in your company gives you enhanced Rocket Fuel rewards.
        </AccordionItem>
        <AccordionItem title="4. What services can I pay for with Rocket Fuel?">
          You can use Rocket Fuel to pay for any of our services, including:
          <br />
          <br />
          - Professional services: $100/hour, such as software development or marketing support. <br />
          - Curated mansion dinners: $10,000 per event. <br />
          - Contact data for sales prospecting: $0.25 per row. <br />- All of these are discountable with Rocket Fuel,
          depending on your level of participation in our program.
        </AccordionItem>
        <AccordionItem title="5. How does prepaying for Rocket Fuel work?">
          When you prepay for Rocket Fuel, you receive a bonus amount that increases your purchasing power. For example,
          prepaying $1,000 might earn you $1,200 in Rocket Fuel. The more you prepay, the larger the bonus, and you can
          use your Rocket Fuel for any of our services.
        </AccordionItem>
        <AccordionItem title="6. What are the benefits of granting equity to Astronomic?">
          By granting equity, you unlock higher Rocket Fuel rewards. For every $100 you spend, you’ll receive: <br />
          <br />
          - $10 in Rocket Fuel if you grant 1% equity. <br />
          - $15 in Rocket Fuel if you grant 2% equity. <br />
          - $25 in Rocket Fuel if you grant 3% equity. <br />
          <br />
          This can significantly reduce your effective costs for our services, especially for large projects or events.
        </AccordionItem>
        <AccordionItem title="7. How much can I save by using Rocket Fuel?">
          The amount you save depends on how you earn and use Rocket Fuel. For example: <br />
          <br />
          - Prepaying for Rocket Fuel gives you a discount by adding bonus credits. <br />- Granting equity allows you
          to earn up to 5x the Rocket Fuel rewards compared to standard spending, enabling the deepest discounts.
        </AccordionItem>

        <AccordionItem title="8. Can I combine prepaying and equity grants for even more savings?">
          Yes! You can stack these options. For example, you might prepay for Rocket Fuel to get a bonus and also grant
          equity to maximize your rewards. This combination unlocks the deepest discounts across all of our offerings.
        </AccordionItem>

        <AccordionItem title="9. What happens if I run out of Rocket Fuel?">
          If you run out of Rocket Fuel, you can either purchase more at face value or prepay to earn additional bonus
          credits. You can also continue using traditional payment methods without discounts.
        </AccordionItem>

        <AccordionItem title="10. What if I don’t want to grant equity in my company?">
          That’s perfectly fine! You can still enjoy the benefits of Rocket Fuel by earning credits through spending or
          prepaying. While granting equity unlocks the deepest discounts, prepaying also offers significant savings.
        </AccordionItem>

        <AccordionItem title="11. Why do you offer discounts for granting equity?">
          When you grant us equity, we align our incentives with your long-term success. In return, we invest in your
          growth by giving you enhanced Rocket Fuel rewards, which can lower your costs for our services and help you
          achieve your business goals faster.
        </AccordionItem>

        <AccordionItem title="12. Can I continue using Astronomic’s services after I’ve granted equity once?">
          Yes! Rocket Fuel multipliers are active for a fixed period (e.g., 12 months) based on the equity you grant.
          After that period, you can grant additional equity, prepay for Rocket Fuel, or pay standard rates to continue
          accessing services.
        </AccordionItem>

        <AccordionItem title="13. What happens if I only need services for a specific project, like building a mobile app?">
          You can grant equity to activate Rocket Fuel multipliers for the duration of the project. For example, a 2%
          equity grant might give you a 3x multiplier for the time it takes to complete the app, ensuring you only pay
          discounted rates while actively using the service.
        </AccordionItem>

        <AccordionItem title="14. Do all user roles (founders, investors, freelancers, etc.) participate in the Rocket Fuel program the same way?">
          No, the way you earn Rocket Fuel depends on your role. Founders grant equity in their companies, while funds
          can allocate a portion of their carry. Freelancers, agencies, and other users can participate through
          revenue-sharing or co-ownership agreements.
        </AccordionItem>

        <AccordionItem title="15. How does the Rocket Fuel program support my business as it grows?">
          Rocket Fuel is designed to scale with your needs. Early-stage founders can use it to access discounts on
          services while preserving cash, and later-stage businesses can use it for networking dinners or sales data as
          they expand. The program evolves with your business.
        </AccordionItem>
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="gray" />

      <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(PricingView);
