import React, { FunctionComponent } from 'react';
import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import { ReactComponent as ErrorIcon } from '../../../asset/images/icons/alert_close.svg';
import { ReactComponent as WarningIcon } from '../../../asset/images/icons/alert.svg';
import { ReactComponent as SuccessIcon } from '../../../asset/images/icons/alert_success.svg';
import { ReactComponent as InfoIcon } from '../../../asset/images/icons/info.svg';
import ToastifyCloseButton from '../../../components/buttons/ToastifyCloseButton/ToastifyCloseButton';
import 'react-toastify/dist/ReactToastify.css';

interface ToastContainerWrapperProps {
  toastContainerProps?: ToastContainerProps;
}

const ToastContainerWrapper: FunctionComponent<ToastContainerWrapperProps> = ({ toastContainerProps }) => {
  const getIcon = (type: string): React.ReactComponentElement<any> | undefined => {
    switch (type) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'info':
        return <InfoIcon />;
      default:
        return undefined;
    }
  };

  return <ToastContainer {...toastContainerProps} icon={({ type }) => getIcon(type)} />;
};

// Set default props for ToastContainerWrapper
ToastContainerWrapper.defaultProps = {
  toastContainerProps: {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    transition: Slide,
    closeButton: <ToastifyCloseButton />,
  },
};

export default ToastContainerWrapper;
