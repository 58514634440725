import { IntroSectionText, IUserRoleText } from '../../types/user';

const fundPageContent: IUserRoleText<IntroSectionText> = {
  founder:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  'business-owner':
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  'private-investor':
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  freelancer:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  consultant:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  agency:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  incubator:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  accelerator:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  'venture-studio':
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  'coworking-space':
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  employee:
    'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently.',
  fund: 'Our studio has all of the specialists you need to grow your company, from market researchers to UI/UX designers, to software developers, to growth marketers, to everyone in between. Schedule a call to see how we can help you build/grow affordable and efficiently..',
};

export default fundPageContent;
