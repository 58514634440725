import { FunctionComponent } from 'react';
import styles from './CreditCardInfo.module.scss';
import classNames from 'classnames';
import PreloadLine from '../PreloadLine/PreloadLine';

interface CreditCardInfoProps {}

const CreditCardInfoSkeleton: FunctionComponent<CreditCardInfoProps> = () => {
  return (
    <div className={classNames(styles['card'], 'tile tile--no-shadow tile--padding-32px')}>
      <header className={styles['card__header']}>
        <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Name on card</p>

        <p
          className={classNames(styles['card__value'], 'text text--body-2 text--bold')}
          style={{ position: 'relative' }}
        >
          John Doe <PreloadLine />
        </p>
      </header>

      <footer className={styles['card__footer']}>
        <div className={styles['card__footer__part_1']}>
          <div className={styles['card__element-1']}>
            <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Card Number</p>
            <p
              className={classNames(styles['card__value'], 'text text--body-2 text--bold')}
              style={{ position: 'relative' }}
            >
              •••• •••• •••• 4242 <PreloadLine />
            </p>
          </div>
        </div>

        <div className={styles['card__footer__part_2']}>
          <div className={styles['card__element-2']}>
            <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Expiry Month</p>
            <p
              className={classNames(styles['card__value'], 'text text--body-2 text--bold')}
              style={{ position: 'relative' }}
            >
              10 <PreloadLine />
            </p>
          </div>

          <div className={styles['card__element-2']}>
            <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Expiry Year</p>
            <p
              className={classNames(styles['card__value'], 'text text--body-2 text--bold')}
              style={{ position: 'relative' }}
            >
              2030 <PreloadLine />
            </p>
          </div>

          <div className={styles['card__element-3']} style={{ position: 'relative' }}>
            *Brand icon* <PreloadLine />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CreditCardInfoSkeleton;
