import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import RadioList, { RadioData } from '../../inputs/RadioList/RadioList';
import MainButton from '../../buttons/MainButton/MainButton';
import Input from '../../inputs/Input/Input';
import { ReactComponent as Chevron } from '../../../asset/images/chevron.svg';
import { ReactComponent as LensIcon } from '../../../asset/images/icons/lens.svg';
import { UserRoleEnum } from '../../../enums/user';
import CheckboxList, { CheckboxData } from '../../inputs/CheckboxList/CheckboxList';
import styles from './GrowFiltersWithServiceCards.module.scss';

const testRadioName = 'name1';

const testCheckboxData_1: CheckboxData[] = [
  { label: 'United States', value: '1' },
  { label: `California`, value: '2' },
  { label: `Texas`, value: '3' },
  { label: `San Francisco`, value: '4' },
  { label: `China`, value: '5' },
  { label: `London`, value: '6' },
];

const testRadioData_2: CheckboxData[] = [
  { label: 'All time', value: '15' },
  { label: 'March 2023', value: '16' },
  { label: 'February 2023', value: '17' },
  { label: 'January 2023', value: '18' },
  { label: 'All of 2023', value: '19' },
  { label: 'All of 2022', value: '110' },
  { label: 'All of 2021', value: '111' },
  { label: 'All of 2020', value: '112' },
];

export interface GrowFiltersWithServiceCardsProps extends React.PropsWithChildren {
  userRole?: UserRoleEnum;
  cardsData?: any[];
}

const GrowFiltersWithServiceCards: FunctionComponent<GrowFiltersWithServiceCardsProps> = ({ userRole, children }) => {
  const [checkboxFilterData, setCheckboxFilterData] = useState<string[]>([]);
  const [radioFilterData, setRadioFilterData] = useState<string>(testCheckboxData_1[0].value);

  const likeFetch = useCallback((checkboxes, radio) => {
    const dataFilter = [...checkboxes, radio];
  }, []);

  useEffect(() => {
    likeFetch(checkboxFilterData, radioFilterData);
  }, [checkboxFilterData, radioFilterData, likeFetch]);

  return (
    <div className={classNames(styles['container'])}>
      <div className={classNames(styles['container__filters'])}>
        <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">Location</h5>
          </div>

          <div className={classNames(styles['container__filters__item__search-input'])}>
            <Input placeholder="Search location" iconLeft={<LensIcon style={{ width: '20px' }} />} />
          </div>

          <div className={classNames(styles['container__filters__item__input-group'])}>
            <CheckboxList data={testCheckboxData_1} onChange={setCheckboxFilterData} name="test" />
          </div>

          <div className={classNames(styles['container__filters__item__expand-button'])} onClick={() => {}}>
            <div className={styles['container__filters__item__expand-button__text']}>Show all locations</div>

            <div className={styles['container__filters__item__expand-button__icon']}>
              <Chevron />
            </div>
          </div>
        </div>

        <div className={classNames(styles['container__filters__item'])}>
          <div className={classNames(styles['container__filters__item__title'])}>
            <h5 className="text text--h5">Disciplines</h5>
          </div>

          <div className={classNames(styles['container__filters__item__input-group'])}>
            <RadioList
              name={testRadioName}
              data={testRadioData_2}
              onChange={setRadioFilterData}
              checkedValue={testCheckboxData_1[0].value}
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles['container__cards'])}>
        {children && children}

        <div className={classNames(styles['container__bottom'])}>
          <div className={classNames(styles['container__bottom__button-group'])}>
            <div className={classNames(styles['container__bottom__button-group__button'])}>
              <MainButton sizeType="medium" visualType="main">
                Load more
              </MainButton>
            </div>

            <div className={classNames(styles['container__bottom__button-group__button'])}>
              <MainButton sizeType="medium" visualType="white">
                Show all
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowFiltersWithServiceCards;
