import { IntroSectionText, IUserRoleText } from '../../types/user';

const buildPageContent: IUserRoleText<IntroSectionText> = {
  founder:
    'We have all of the specialists you need to grow your company, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  'business-owner':
    'We have all of the specialists you need to grow your company, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  'private-investor':
    'We have all of the specialists you need to grow your company, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  freelancer:
    'We have all of the specialists you need to grow your clients’ companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  consultant:
    'We have all of the specialists you need to grow your clients’ companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  agency:
    'We have all of the specialists you need to grow your clients’ companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  incubator:
    'We have all of the specialists your founders need to grow their companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  accelerator:
    'We have all of the specialists your founders need to grow their companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  'venture-studio':
    'We have all of the specialists you need to grow your companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  'coworking-space':
    'We have all of the specialists your members need to grow their companies, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  employee:
    'We have all of the specialists you need to grow your company, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
  fund: 'We have all of the specialists you need to grow your company, from market researchers to UI/UX designers, software developers, growth marketers, salespeople, and everyone in between. ',
};

export default buildPageContent;
