import { FunctionComponent, useEffect, useState } from 'react';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import { ReactComponent as EditIcon } from '../../../../../asset/images/icons/edit.svg';
import { ReactComponent as TrashCanIcon } from '../../../../../asset/images/icons/trash_can.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../../asset/images/icons/external_link.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Label from '../../../../../components/texts/Label/Label';
import styles from './AccountRole.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../../../api';
import { useAppSelector } from '../../../../../redux/hooks';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../../../../dto/api';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import { isUrl } from '../../../../../utils/regex';
import PreloadLine from '../../../../../components/blocks/PreloadLine/PreloadLine';

interface AccountRoleProps {}

const AccountRole: FunctionComponent<AccountRoleProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [roleInfo, setRoleInfo] = useState<Array<any>>();

  const searchParams = new URLSearchParams(location.search);
  const roleId = searchParams.get('roleId');
  const title = searchParams.get('title');

  const userId = useAppSelector((state) => state.user.user?.userId as string);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Onboarding.getUserDataByRole(userId, roleId as string);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setRoleInfo(responseData.data);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }

        callToast({
          title: 'Error',
          children: <>Something went wrong. Please try again later</>,
          variation: 'error',
        });

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  const convertStringToArray = (inputString) => {
    if (inputString) {
      return inputString.includes(',') ? inputString.split(',').map((item) => item.trim()) : inputString.trim();
    }
  };

  return (
    <div className={styles['role']}>
      <NavigationLink
        className={styles['role__back-link']}
        path="/account/profile"
        iconLeft={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
        withBorder={false}
      >
        Back to Profile
      </NavigationLink>

      <div className={styles['title-container']}>
        <h3 className={classNames(styles['title-container__text'], 'text text--h3')}>{title}</h3>

        <MainButton
          sizeType="medium"
          visualType="white"
          iconLeft={<EditIcon style={{ fill: 'var(--color-main)', width: '13px' }} />}
          onClick={() => {
            navigate(`/account/profile/role/edit?roleId=${roleId}&title=${title}`);
          }}
        >
          Edit
        </MainButton>
      </div>

      {roleInfo ? (
        roleInfo
          ?.filter((item) => item.type === 'Section')
          .map((item) => {
            return (
              <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')} key={item.id}>
                {item.questions.map((subItem) => (
                  <div className={styles['info__value-group']} key={subItem.questionID}>
                    <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>
                      {subItem.displayName}
                    </div>

                    <p className={classNames(styles['info__text'], 'text text--body-2')}>
                      {isUrl(subItem.answer) === false ? (
                        subItem.answer
                      ) : (
                        <NavigationLink
                          path="#"
                          colorType="dark"
                          sizeType="small-2"
                          iconRight={<ExternalLinkIcon style={{ width: '14px' }} />}
                          target="_blank"
                          rel="nofollow"
                          onClick={() =>
                            window.open(
                              subItem.answer.startsWith('http') ? subItem.answer : `https://${subItem.answer}`,
                              '_blank'
                            )
                          }
                        >
                          {subItem.answer}
                        </NavigationLink>
                      )}

                      {subItem.answer === null && (
                        <>
                          Fill this out and earn <b>free rocket fuel</b>!
                        </>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            );
          })
      ) : (
        <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
          {[1, 2, 3].map(() => (
            <div className={styles['info__value-group']}>
              <div
                className={classNames(styles['info__title'], 'text text--body-2 text--bold')}
                style={{ maxWidth: '50%' }}
              >
                Display name
                <PreloadLine />
              </div>

              <p className={classNames(styles['info__text'], 'text text--body-2')} style={{ maxWidth: '30%' }}>
                Answer
                <PreloadLine />
              </p>
            </div>
          ))}
        </div>
      )}

      {roleInfo ? (
        <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
          <div className={styles['info__value-group__list']}>
            {roleInfo
              ?.filter((item) => item.type === 'Question')
              .map((item) => {
                return (
                  <div className={styles['info__value-group']}>
                    <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>
                      {item.question.displayName}
                    </div>

                    <p className={classNames(styles['info__text'], 'text text--body-2')}>
                      {Array.isArray(convertStringToArray(item.question.answer)) ? (
                        <div className={styles['info__label-list']}>
                          {convertStringToArray(item.question.answer).map((subAnswer, i) => (
                            <Label text={subAnswer} visualStyle="white" key={i} />
                          ))}
                        </div>
                      ) : (
                        item.question.answer
                      )}

                      {item.question.answer === null && (
                        <>
                          Fill this out and earn <b>free rocket fuel</b>!
                        </>
                      )}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
          <div className={styles['info__value-group__list']}>
            {[1, 2, 3, 4, 5, 6].map((item) => {
              return (
                <div className={styles['info__value-group']}>
                  <div className={classNames(styles['info__title'], 'text text--body-2 text--bold')}>
                    Display name
                    <PreloadLine type="white" />
                  </div>

                  <p className={classNames(styles['info__text'], 'text text--body-2')} style={{ maxWidth: '50%' }}>
                    Answer
                    <PreloadLine type="white" />
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <MainButton
        className={styles['remove-role-link']}
        iconLeft={<TrashCanIcon style={{ width: '16px' }} />}
        sizeType="medium"
        visualType="white"
        disabled
      >
        Remove role
      </MainButton>
    </div>
  );
};

export default AccountRole;
