import { FunctionComponent } from 'react';
import styles from './CreditCardInfo.module.scss';
import classNames from 'classnames';

interface CreditCardInfoProps {
  name: string;
  cardNumber: string;
  expiry: string;
  creditCardCompanyIcon: React.ReactElement;
}

const CreditCardInfo: FunctionComponent<CreditCardInfoProps> = ({
  name,
  cardNumber,
  expiry,
  creditCardCompanyIcon,
}) => {
  const dateString = expiry;
  const [month, year] = dateString.split('/');

  return (
    <div className={classNames(styles['card'], 'tile tile--no-shadow tile--padding-32px')}>
      <header className={styles['card__header']}>
        <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Name on card</p>

        <p className={classNames(styles['card__value'], 'text text--body-2 text--bold')}>{name}</p>
      </header>

      <footer className={styles['card__footer']}>
        <div className={styles['card__footer__part_1']}>
          <div className={styles['card__element-1']}>
            <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Card Number</p>
            <p className={classNames(styles['card__value'], 'text text--body-2 text--bold')}>{cardNumber}</p>
          </div>
        </div>

        <div className={styles['card__footer__part_2']}>
          <div className={styles['card__element-2']}>
            <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Expiry Month</p>
            <p className={classNames(styles['card__value'], 'text text--body-2 text--bold')}>{month}</p>
          </div>

          <div className={styles['card__element-2']}>
            <p className={classNames(styles['card__title'], 'text text--caption text--bold')}>Expiry Year</p>
            <p className={classNames(styles['card__value'], 'text text--body-2 text--bold')}>{year}</p>
          </div>

          <div className={styles['card__element-3']}>{creditCardCompanyIcon}</div>
        </div>
      </footer>
    </div>
  );
};

export default CreditCardInfo;
