import { FunctionComponent } from 'react';
import { ReactComponent as CloseIcon } from '../../../asset/images/icons/cross.svg';
import styles from './ToastifyCloseButton.module.scss';

interface ToastifyCloseButtonProps {
  closeToast?: () => void;
}

const ToastifyCloseButton: FunctionComponent<ToastifyCloseButtonProps> = ({ closeToast }) => {
  return (
    <button className={styles['close-button']} onClick={closeToast}>
      <CloseIcon />
    </button>
  );
};

export default ToastifyCloseButton;
