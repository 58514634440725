import { AxiosInstance } from "axios";

class BaseAPI {
    protected axios: AxiosInstance;
    protected backend: string;
    constructor(axios: AxiosInstance, backend: string) {
        this.axios = axios;
        this.backend = backend;
    }
}

export default BaseAPI;