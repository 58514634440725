import { FunctionComponent, useEffect, useState } from 'react';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import styles from './CompleteProfileFooter.module.scss';
import { useNavigate } from 'react-router-dom';

interface CompleteProfileFooterProps {
  onSkipPopUpStateChange(state: boolean): void;
  onNextStep(): void;
  onPrevStep(): void;
  currentStep: number;
  hideSkipButton: boolean;
  formSchema: any;
  onSubmit?(): void;
}

const CompleteProfileFooter: FunctionComponent<CompleteProfileFooterProps> = ({
  onSkipPopUpStateChange,
  onNextStep,
  onPrevStep,
  currentStep,
  hideSkipButton = false,
  formSchema,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [prevStepObj, setPrevStepObj] = useState<any>({});
  const [nextStepObj, setNextStepObj] = useState<any>({});

  useEffect(() => {
    const findStepByOrder = (order) => {
      return formSchema.find((step) => step.stepOrder === order);
    };

    setPrevStepObj(findStepByOrder(currentStep - 1));
    setNextStepObj(findStepByOrder(currentStep + 1));
  }, [currentStep, formSchema]);

  return (
    <footer className={styles['footer']}>
      <div className="wrapper type-1">
        <div className={styles['content']}>
          <div className={styles['part-list']}>
            <div className={styles['part-list__left']}>
              <div className={styles['back-button']}>
                {prevStepObj && (
                  <MainButton
                    sizeType="large"
                    visualType="white"
                    iconLeft={<ArrowIcon style={{ width: '16px', transform: 'rotate(180deg)' }} />}
                    onClick={() => {
                      onPrevStep();
                    }}
                  >
                    {prevStepObj.stepTitle}
                  </MainButton>
                )}
              </div>
            </div>

            <div className={styles['part-list__right']}>
              {nextStepObj && hideSkipButton === false && (
                <div className={styles['skip-button']}>
                  <MainButton
                    sizeType="large"
                    visualType="white"
                    onClick={() => {
                      onSkipPopUpStateChange(true);
                    }}
                  >
                    Skip
                  </MainButton>
                </div>
              )}

              <div className={styles['next-button']}>
                {nextStepObj === undefined ? (
                  <MainButton sizeType="large" visualType="main" onClick={onSubmit}>
                    Finish
                  </MainButton>
                ) : (
                  <MainButton
                    sizeType="large"
                    visualType="main"
                    iconRight={<ArrowIcon style={{ width: '16px', fill: 'var(--color-white)' }} />}
                    onClick={() => {
                      onNextStep();
                    }}
                  >
                    {nextStepObj.stepTitle}
                  </MainButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CompleteProfileFooter;
