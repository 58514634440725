import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './TextBillet.module.scss';
import NavigationLink from '../../links/NavigationLink/NavigationLink';

interface TextBilletProps extends PropsWithChildren {
  className?: string;
  linkUrl?: string;
  linkText?: string;
}

const TextBillet: FunctionComponent<TextBilletProps> = ({ className, children, linkUrl = '#', linkText }) => {
  return (
    <div className={classNames(styles['text-billet'], className)}>
      <div className={styles['text-billet__top-part']}>
        <div className="text text--body-2">{children}</div>
      </div>

      {linkText && (
        <div className={styles['text-billet__bottom-part']}>
          <NavigationLink path={linkUrl} sizeType="small">
            {linkText}
          </NavigationLink>
        </div>
      )}
    </div>
  );
};

export default TextBillet;
