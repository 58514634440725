import { AxiosInstance, AxiosResponse } from 'axios';
import BaseAPI from '../base/api';
import { ApiResponseDTO } from '../../dto/api';
import { ProductDTO, SubscriptionDTO } from '../../dto/product';
import { ClientTransactionsDTO, DefaultPaymentMethodDTO } from '../../dto/payment';

class PaymentApi extends BaseAPI {
  private paths = {
    CREATE_CUSTOMER_WITH_DEFAULT_PM: 'payment/CreateCustomerWithDefaultPM',
    UPDATE_CUSTOMER_DEFAULT_PAYMENT_METHOD: 'payment/UpdateCustomerDefaultPaymentMethod',
    GET_DEFAULT_PAYMENT_METHOD: 'payment/GetDefaultPaymentMethod',
    GET_PRODUCTS: (isSubscriptionProduct) => `payment/GetProducts?isSubscriptionProduct=${isSubscriptionProduct}`,
    GET_ROCKET_FUEL_BUNDLES: (userId) => `payment/GetRocketFuelBundles?userId=${userId}`,
    GET_SUBSCRIPTION_PRODUCTS: 'payment/GetSubscriptionProducts',
    GET_CLIENT_TRANSACTIONS: 'payment/GetClientTransactions',
    CHARGE_CUSTOMER_FOR_PRODUCT: `payment/ChargeCustomerForProduct`,
    SUBSCRIBE_CUSTOMER_TO_PRODUCT: `payment/SubscribeCustomerToProduct`,
    UNSUBSCRIBE_FROM_PRODUCT: 'payment/UnsubscribeFromProduct',
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(axios: AxiosInstance, backend: string) {
    super(axios, backend);
  }

  async createCustomerWithDefaultPM(data: { email: string; name: string; paymentMethodId: string }) {
    return this.axios.post<ApiResponseDTO<{ stripeCustomerId: string; name: string; email: string }>>(
      this.paths.CREATE_CUSTOMER_WITH_DEFAULT_PM,
      data
    );
  }

  async updateCustomerDefaultPaymentMethod(data: { email: string; name: string; paymentMethodId: string }) {
    return this.axios.patch<ApiResponseDTO<string>>(this.paths.UPDATE_CUSTOMER_DEFAULT_PAYMENT_METHOD, data);
  }

  async getDefaultPaymentMethod(customerId: string) {
    const params = {
      customerId: customerId,
    };

    return this.axios.get<ApiResponseDTO<DefaultPaymentMethodDTO>>(this.paths.GET_DEFAULT_PAYMENT_METHOD, { params });
  }

  async getProducts(isSubscriptionProduct: boolean = false) {
    return this.axios.get<ApiResponseDTO<ProductDTO[] | SubscriptionDTO[]>>(
      this.paths.GET_PRODUCTS(isSubscriptionProduct)
    );
  }

  async getFuelBundles(userId: string) {
    return this.axios.get<ApiResponseDTO<ProductDTO[] | SubscriptionDTO[]>>(this.paths.GET_ROCKET_FUEL_BUNDLES(userId));
  }

  async getSubscriptionProducts(customerId: string) {
    const params = {
      customerId: customerId,
    };

    return this.axios.get<
      ApiResponseDTO<{ activeSubscriptions: SubscriptionDTO[]; availableSubscriptions: SubscriptionDTO[] }>
    >(this.paths.GET_SUBSCRIPTION_PRODUCTS, { params });
  }

  async getClientTransactions(customerId: string) {
    const params = {
      customerId: customerId,
    };

    return this.axios.get<ApiResponseDTO<ClientTransactionsDTO[]>>(this.paths.GET_CLIENT_TRANSACTIONS, { params });
  }

  async chargeCustomerForProduct(data: {
    stripeCustomerId: string;
    stripeProductId: string;
    paymentMethodId: string;
    userId: string;
  }) {
    return this.axios.post<ApiResponseDTO>(this.paths.CHARGE_CUSTOMER_FOR_PRODUCT, data);
  }

  async subscribeCustomerToProduct(data: {
    stripeCustomerId: string;
    stripeProductId: string;
    paymentMethodId: string;
  }) {
    return this.axios.post<ApiResponseDTO<SubscriptionDTO>>(this.paths.SUBSCRIBE_CUSTOMER_TO_PRODUCT, data);
  }

  async unsubscribeFromProduct(data: { stripeCustomerId: string; stripeProductId: string; paymentMethodId: string }) {
    return this.axios.post<ApiResponseDTO>(this.paths.UNSUBSCRIBE_FROM_PRODUCT, data);
  }
}

export default PaymentApi;
