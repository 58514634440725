import { FunctionComponent } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import MainButton from '../MainButton/MainButton';
import RoutesEnum from '../../../enums/routes';
import { GENERAL_CONTENT } from '../../../data/generalContent';
import { useNavigate } from 'react-router-dom';
import { UserRoleEnum } from '../../../enums/user';
import { extractRoleFromUrl } from '../../../utils/role';

interface JoinForFreeButtonProps {
  className?: string;
}

const JoinForFreeButton: FunctionComponent<JoinForFreeButtonProps> = ({ className }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const navigate = useNavigate();

  const userRole = extractRoleFromUrl();

  return isUserLoggedIn === false ? (
    <MainButton
      className={className}
      onClick={() => {
        navigate(`/${RoutesEnum.SIGN_UP}`);
      }}
    >
      {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
    </MainButton>
  ) : (
    <MainButton
      className={className}
      onClick={() => {
        navigate(`/${userRole}/${RoutesEnum.SCHEDULE_MEETING}`);
      }}
    >
      {GENERAL_CONTENT.SCHEDULE_A_MEETING}
    </MainButton>
  );
};

export default JoinForFreeButton;
