import { IntroSectionText, IUserRoleText } from '../../types/user';

const learnPageContent: IUserRoleText<IntroSectionText> = {
  founder:
    'Connect with accomplished founders to take your startup to the next level. We’ll match you with founders who’ve built companies of the same business type and/or industry as yours or whose skills/experience match your current need.',
  'business-owner':
    'Connect with accomplished business owners to take your company to the next level. We’ll match you with business owners who’ve built companies of the same business type and/or industry as yours or whose skills/experience match your current need.',
  'private-investor':
    'Connect with accomplished investors to take your investing to the next level. We’ll match you with investors who invest in the same business types and/or industries as you or whose skills/experience match your current need.',
  freelancer:
    'We’ll match your clients with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  consultant:
    'We’ll match your clients with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  agency:
    'We’ll match your clients with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  incubator:
    'We’ll match your founders with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  accelerator:
    'We’ll match your founders with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  'venture-studio':
    'We’ll match your founders with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  'coworking-space':
    'We’ll match your members with others who’ve built companies of the same business type and/or industry as theirs or whose skills/experience match their current need.',
  employee:
    'Connect with accomplished founders to take your startup to the next level. We’ll match you with founders who’ve built companies of the same business type and/or industry as yours or whose skills/experience match your current need.',
  fund: 'Connect with accomplished founders to take your startup to the next level. We’ll match you with founders who’ve built companies of the same business type and/or industry as yours or whose skills/experience match your current need.',
};

export default learnPageContent;
