export const isEmailValid = (email: string): boolean => {
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return emailRegExp.test(email);
}

export const hasLowerCase = (input: string): boolean => {
  const regex = /[a-z]/;

  return regex.test(input);
};

export const hasSpecialCharacter = (input: string): boolean => {
  const regex = /[^a-zA-Z0-9]/;

  return regex.test(input);
};

export const hasUpperCase = (input: string): boolean => {
  const regex = /[A-Z]/;

  return regex.test(input);
};

export const hasNumber = (input: string): boolean => {
  const regex = /\d/;

  return regex.test(input);
};

export const hasOnlyEnglishCharacters = (input: string): boolean => {
  const regex = /^[a-zA-Z\s]+$/;

  return regex.test(input);
};

export const isPostalCode = (input: string): boolean => {
  const regex = /^\d{5}(-\d{4})?$/;

  return regex.test(input);
}

export const isUrl = (input: string) => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

  return regex.test(input);
};

export const isLinkedInURL = (string: string) => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)?linkedin\.com(\/\S*)?$/i;

  return regex.test(string);
};