import { FunctionComponent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './RoleSelector.module.scss';
import { ReactComponent as ChevronIcon } from '../../../asset/images/chevron.svg';
import { ReactComponent as PersonIcon } from '../../../asset/images/icons/person.svg';
import { ReactComponent as TickIcon } from '../../../asset/images/icons/tick.svg';
import { UserRoleEnum } from '../../../enums/user';
import { NavLink, useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';
import PopUp from '../../popups/PopUp/PopUp';
import { setShouldScroll } from '../../../redux/slices/scroll/slice';
import { useAppDispatch } from '../../../redux/hooks';
import { getArticle } from '../../../utils/stringFormatMethods';

export type RoleSelectorColorType = 'white' | 'blue';

const RoleSelectorColorMap: Record<RoleSelectorColorType, string> = {
  blue: '',
  white: 'role-selector--color-white',
};
export interface RoleSelectorProps {
  className?: string;
  color?: RoleSelectorColorType;
  user_role: UserRoleEnum;
}

const RoleSelector: FunctionComponent<RoleSelectorProps> = ({ className, color = 'blue', user_role }) => {
  const dispatch = useAppDispatch();

  const roleOptions = Object.keys(UserRoleEnum);
  const currentRole = UserRoleEnum[user_role];

  const location = useLocation();

  const [rolePopUpActive, setRolePopUpActive] = useState(false);
  const [pressedIndex, setPressedIndex] = useState<number | null>(null);

  const changeRoleSelectorActiveState = (state, isRoleClicked) => {
    dispatch(setShouldScroll(false));

    if (isRoleClicked === true) {
      setTimeout(() => {
        setRolePopUpActive(state);
      }, 500);
    } else {
      setRolePopUpActive(state);
    }
  };

  const handleOnRoleClick = (index: number) => {
    setPressedIndex(index);
    setTimeout(() => setPressedIndex(null), 250); // Reset after 200ms
  };

  const pathname = location.pathname;
  let pathnameWithoutRole = '';
  pathname
    .split('/')
    .filter((item, i) => i !== 0 && i !== 1)
    .map((item, i) => (pathnameWithoutRole += `/${item}`));

  return (
    <>
      <div
        className={classNames(
          `${rolePopUpActive === true ? 'active' : ''}`,
          className,
          styles['role-selector'],
          styles[RoleSelectorColorMap[color]]
        )}
        onClick={() => {
          changeRoleSelectorActiveState(true, false);
        }}
      >
        <div className={styles['role-selector__nav']}>
          <div className={styles['role-selector__nav-selected']}>
            <div className={styles['role-selector__icon']}>
              <PersonIcon />
            </div>

            <div className={styles['role-selector__nav-text']}>
              <span className={styles['role-selector__nav-text__span']}>I'm {getArticle(currentRole)}</span>
              {currentRole}
            </div>

            <div className={styles['role-selector__nav-icon']}>
              <ChevronIcon />
            </div>
          </div>
        </div>
      </div>

      {createPortal(
        <PopUp
          className={styles['role-pop-up']}
          title="How do you identify?"
          active={rolePopUpActive}
          onPopUpStateChange={(state) => {
            changeRoleSelectorActiveState(state, false);
          }}
          popUpWidth="720"
        >
          <p className={classNames(styles['role-pop-up__text'], 'text', 'text--body-2')}>
            We’ll update our content across our site to reflect your role.
          </p>

          <div className={styles['role-selector__nav-list']}>
            {roleOptions.map((item, i) => (
              <NavLink
                className={classNames(
                  styles['role-selector__nav-list-item'],
                  pressedIndex === i && styles['role-selector__nav-list-item--pressed']
                )}
                to={`/${item}${pathnameWithoutRole}`}
                key={i}
                onClick={() => {
                  changeRoleSelectorActiveState(false, true);
                  handleOnRoleClick(i);
                }}
              >
                <p className={styles['role-selector__nav-list-item__text']}>{UserRoleEnum[item]}</p>

                <div className={styles['role-selector__nav-list-item__circle']}>
                  <TickIcon className={styles['role-selector__nav-list-item__circle__icon']} />
                </div>
              </NavLink>
            ))}
          </div>
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </>
  );
};

export default RoleSelector;
