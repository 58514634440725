import { FunctionComponent, useState, useEffect } from "react";
import { ReactComponent as Chevron } from "../../../asset/images/chevron.svg";
import MainButton from "../MainButton/MainButton";
import styles from "./BackToTopButton.module.scss";

interface BackToTopButtonProps {}

const BackToTopButton: FunctionComponent<BackToTopButtonProps> = () => {
  const [scrolledPastPortion, setScrolledPastPortion] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const portionHeight = 300;

      if (window.scrollY >= portionHeight) {
        setScrolledPastPortion(true);
      } else {
        setScrolledPastPortion(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {scrolledPastPortion === true && (
        <MainButton
          visualType="white"
          sizeType="small"
          className={styles["button"]}
          iconLeft={
            <Chevron
              style={{
                fill: "var(--color-main)",
                width: "10px",
                transform: "rotate(180deg)",
              }}
            />
          }
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Back to top
        </MainButton>
      )}
    </>
  );
};

export default BackToTopButton;
