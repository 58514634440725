import { FunctionComponent, useState } from 'react';
import styles from './CheckboxTiledList.module.scss';
import CheckboxTiled from './_parts/CheckboxTiled/CheckboxTiled';
import Grid from '../../blocks/Grid/Grid';

export interface CheckboxData {
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
}

interface CheckboxTiledListProps {
  name: string;
  data: CheckboxData[];
  onlyOneOption?: boolean;
  onChange?(data): void;
}

const CheckboxTiledList: FunctionComponent<CheckboxTiledListProps> = ({
  data,
  name,
  onlyOneOption = false,
  onChange,
}) => {
  const [checkboxData, setCheckboxData] = useState<string[]>([]);

  const onChangeHandler = (value) => {
    const newData = [...checkboxData];

    !checkboxData.includes(value) ? newData.push(value) : newData.splice(checkboxData?.indexOf(value), 1);

    setCheckboxData(newData);
    onChange?.(newData);
  };

  const onChangeForOnlyOneOptionHandler = (e, value) => {
    if (e.target.checked) {
      setCheckboxData([value]);
      onChange?.([value]);
    } else {
      setCheckboxData([]);
      onChange?.([]);
    }
  };

  return (
    <fieldset className={styles['checkbox-list']}>
      <Grid
        itemsInRow={3}
        gridHorizontalMargin={3}
        gridVerticalMargin={3}
        itemGroup={data.map((item) => (
          <div className={styles['checkbox-list__item']} key={item.value}>
            <CheckboxTiled
              // checked={item.checked === true ? true : false}
              checked={checkboxData.some((item_2) => item_2 === item.value)}
              disabled={item.disabled === true ? true : false}
              name={name}
              value={item.value}
              label={item.label}
              onChange={(e) =>
                onlyOneOption === false ? onChangeHandler(item.value) : onChangeForOnlyOneOptionHandler(e, item.value)
              }
            />
          </div>
        ))}
      />
    </fieldset>
  );
};

export default CheckboxTiledList;
