export enum UserRoleEnum {
  founder = 'Founder',
  'business-owner' = 'Business Owner',
  'private-investor' = 'Private Investor',
  fund = 'Fund',
  incubator = 'Incubator',
  accelerator = 'Accelerator',
  'venture-studio' = 'Venture Studio',
  'coworking-space' = 'Coworking Space',
  freelancer = 'Freelancer',
  consultant = 'Consultant',
  agency = 'Agency',
  employee = 'Employee',
}
