import { IntroSectionText, IUserRoleText } from '../../types/user';

const connectPageContent: IUserRoleText<IntroSectionText> = {
  founder: 'Attend our events to connect with relevant founders and investors for camaraderie and resource sharing.',
  'business-owner':
    'Attend our events to connect with relevant business owners and investors for camaraderie and resource sharing.',
  'private-investor':
    'Attend our events to connect with relevant founders and investors for camaraderie and resource sharing.',
  freelancer: 'Attend our events to connect with relevant service providers for camaraderie and resource sharing.',
  consultant: 'Attend our events to connect with relevant service providers for camaraderie and resource sharing.',
  agency: 'Attend our events to connect with relevant service providers for camaraderie and resource sharing.',
  incubator:
    'Have your founders join our dinner programs to make relevant connections who can move the needle in their businesses.',
  accelerator:
    'Have your founders join our dinner programs to make relevant connections who can move the needle in their businesses.',
  'venture-studio':
    'Attend our events to connect with relevant founders and investors for camaraderie and resource sharing.',
  'coworking-space':
    'Have your members join our dinner programs to make relevant connections who can move the needle in their businesses.',
  employee: 'Attend our events to connect with relevant founders and investors for camaraderie and resource sharing.',
  fund: 'Attend our events to connect with relevant founders and investors for camaraderie and resource sharing.',
};

export default connectPageContent;
