import { useState } from 'react';

const useFormSubmitLoader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);

  const handleFormSubmitLoader = async (submitFunction: () => Promise<void>) => {
    try {
      setPendingRequests((prevCount) => prevCount + 1);
      setIsLoading(true);
      await submitFunction();
    } finally {
      setPendingRequests((prevCount) => prevCount - 1);
      if (pendingRequests === 0) {
        setIsLoading(false);
      }
    }
  };

  return { isLoading: isLoading || pendingRequests > 0, handleFormSubmitLoader };
};

export default useFormSubmitLoader;
