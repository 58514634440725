import { FunctionComponent, useState } from 'react';
import styles from './CompleteProfileHeaderStepList.module.scss';
import CompleteProfileHeaderStep from './_parts/CompleteProfileHeaderStep/CompleteProfileHeaderStep';

interface CompleteProfileHeaderStepListProps {
  currentStep: number;
  formSchema: any;
}

const CompleteProfileHeaderStepList: FunctionComponent<CompleteProfileHeaderStepListProps> = ({
  currentStep,
  formSchema,
}) => {
  return (
    <ul className={styles['list']}>
      {formSchema.map((step, i) => {
        return (
          <li className={styles['list__item']} key={step.stepId}>
            <CompleteProfileHeaderStep label={step.stepTitle} number={i + 1} currentStep={currentStep} />
          </li>
        );
      })}
    </ul>
  );
};

export default CompleteProfileHeaderStepList;
