import { FunctionComponent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { setShouldScroll } from '../../../redux/slices/scroll/slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

const ScrollToTop: FunctionComponent = () => {
  const { pathname } = useLocation(); // Get the current URL pathname
  const shouldScroll = useAppSelector((state) => state.scroll.shouldScroll); // Get scroll state from Redux
  const dispatch = useAppDispatch();

  // Track the previous pathname
  const prevPathnameRef = useRef<string | null>(null);

  useEffect(() => {
    // Check if the route has changed
    const hasPathChanged = prevPathnameRef.current !== pathname;

    if (shouldScroll && hasPathChanged) {
      // Scroll to top if `shouldScroll` is true and the route changed
      window.scrollTo(0, 0);
    }

    // Update the previous pathname to the current one
    prevPathnameRef.current = pathname;

    // Reset `shouldScroll` to true after a route change
    if (hasPathChanged) {
      dispatch(setShouldScroll(true));
    }
  }, [pathname, shouldScroll, dispatch]);

  return null; // No UI rendered
};

export default ScrollToTop;
