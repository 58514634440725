import { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./PriceBillet.module.scss";
import NavigationLink from "../../links/NavigationLink/NavigationLink";

interface PriceBilletProps {
  className?: string;
  textBefore?: string;
  textAfter?: string;
  price: string;
  linkUrl?: string;
  linkText?: string;
}

const PriceBillet: FunctionComponent<PriceBilletProps> = ({
  textBefore = "From",
  textAfter = "/hour",
  price,
  linkUrl = "#",
  linkText,
}) => {
  return (
    <div className={styles["price-billet"]}>
      <div className={styles["price-billet__top-part"]}>
        <div className="text text--body-2 text--bold">
          {textBefore}
          <span
            className={classNames(
              styles["price-billet__text"],
              "text text--h3"
            )}
          >
            {price}
          </span>
          {textAfter}
        </div>
      </div>

      {linkText && (
        <div className={styles["price-billet__bottom-part"]}>
          <NavigationLink path={linkUrl} sizeType="small">
            {linkText}
          </NavigationLink>
        </div>
      )}
    </div>
  );
};

export default PriceBillet;
