export const stringToUserFriendlyURL = (string: string): string =>
  string
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters, keeping only letters, numbers, and spaces
    .trim() // Trim leading and trailing spaces
    .replace(/\s+/g, '-'); // Replace spaces with hyphens

export function truncateString(input: string, maxLength: number = 100): string {
  // If the input string is shorter than the maximum length, return it as is
  if (input.length <= maxLength) {
    return input;
  }

  // Find the last space within the maxLength limit
  const truncated = input.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  // If there's no space, just return the truncated string
  // Otherwise, return the string up to the last space
  return lastSpaceIndex !== -1 ? truncated.slice(0, lastSpaceIndex) + '...' : truncated + '...';
}

export function getStringDividedByLastWord(str: string): { firstPart: string; lastWord: string } {
  if (!str || typeof str !== 'string' || str.trim() === '') {
    return { firstPart: '', lastWord: '' }; // Return empty values if the input is invalid
  }

  const words = str.trim().split(/\s+/);
  const lastWord = words[words.length - 1];
  const firstPart = words.slice(0, words.length - 1).join(' ');

  return { firstPart, lastWord };
}

export function getCollectionOfStringDividedByWord(
  word: string,
  str: string
): { firstPart: string; secondPart: string; word: string } {
  if (!str || typeof str !== 'string' || str.trim() === '') {
    return { firstPart: '', secondPart: '', word: '' }; // Return empty parts if the input is invalid
  }

  const index = str.indexOf(word);
  if (index === -1) return { firstPart: str, secondPart: '', word: '' }; // Word not found

  const firstPart = str.slice(0, index).trim();
  const secondPart = str.slice(index + word.length).trim();
  return { firstPart, secondPart, word };
}

export const getArticle = (role: string): string => (/^([aeiou])/i.test(role) ? 'an' : 'a');
