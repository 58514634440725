import { FunctionComponent, useState } from 'react';
import { createPortal } from 'react-dom';
import PopUp from '../PopUp/PopUp';
import classNames from 'classnames';
import styles from './WhatIsAstronomicPopUp.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import { ReactComponent as ButtonArrow } from '../../../asset/images/button_arrow.svg';
import whatIsAstronomicPopUpBG from '../../../asset/images/what_is_astronomic_pop_up_bg.jpg';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../enums/routes';
import { GENERAL_CONTENT } from '../../../data/generalContent';
import { useAppSelector } from '../../../redux/hooks';
import { UserRoleEnum } from '../../../enums/user';

interface WhatIsAstronomicPopUpProps {
  whatIsPopUpActive: boolean;
  userRole?: UserRoleEnum;
  changeWhatIsPopUpActiveState: (state: boolean) => void;
}

const WhatIsAstronomicPopUp: FunctionComponent<WhatIsAstronomicPopUpProps> = ({
  whatIsPopUpActive,
  userRole = '',
  changeWhatIsPopUpActiveState,
}) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  const navigate = useNavigate();

  const [whatIsPopUpCurrentStep, setWhatIsPopUpCurrentStep] = useState(0);

  const handlePopUpClose = (state: boolean) => {
    changeWhatIsPopUpActiveState(state);
    setWhatIsPopUpCurrentStep(0);
  };

  const whatIsSliderStepsContentData = [
    {
      title: '',
      text: '',
      icon: '',
      nextButton: 'Next',
    },
    {
      title: 'A La Carte Pricing',
      text: 'Whether you need professional services, mentorship, data, capital, or are looking to meet others in your orbit, everything at Astronomic is available a la carte. Get what you need when you need it.',
      icon: '💫',
      nextButton: 'Next',
    },
    {
      title: 'Rocket Fuel',
      text: 'Rocket Fuel is our credits system for earning rewards and gamifying your growth. Earn $5 in Rocket Fuel for every $100 spent. Prepay for Rocket Fuel to get discounts across all of our products and services.',
      icon: '🚀',
      nextButton: 'Next',
    },
    {
      title: `Equity`,
      text: 'Grant equity to Astronomic and we’ll discount our pricing as close to cost as we can. Our mission is to enable as much entrepreneurship as possible, so if you share the reward with us, we’ll share the risk with you.',
      icon: '⚡️',
      nextButton: 'Next',
    },
    {
      title: 'Dinners',
      text: 'We’re passionate about connecting people relevantly, and what better way to do this than by breaking bread together? We organize a lot of curated networking dinners. Join us to meet incredible new people who can take your business to new heights.',
      icon: '🤝',
      nextButton: 'Next',
    },
    {
      title: 'Partner with Us',
      text: `Know someone who’d benefit from working with us? Price on top of us and resell our products and services, or refer them to us and we’ll pay you 10% of everything they spend during their first year with us.`,
      icon: '👋',
      nextButton: isUserLoggedIn ? GENERAL_CONTENT.SCHEDULE_A_MEETING : GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE,
    },
  ];

  return (
    <>
      {createPortal(
        <PopUp
          className={classNames(
            styles['what-is-pop-up'],
            whatIsPopUpCurrentStep > 0 && styles['what-is-pop-up--not-first-slide']
          )}
          active={whatIsPopUpActive}
          onPopUpStateChange={handlePopUpClose}
          popUpWidth="720"
          footer={
            <div className={styles['what-is-pop-up__footer-button-list']}>
              <div className={styles['what-is-pop-up__footer-button-list__item']}>
                {whatIsPopUpCurrentStep === 0 ? (
                  <MainButton
                    visualType="blank"
                    onClick={() => {
                      changeWhatIsPopUpActiveState(false);
                      navigate(`/${RoutesEnum.SIGN_UP}`);
                    }}
                  >
                    {GENERAL_CONTENT.JOIN_ASTRONOMIC_FOR_FREE}
                  </MainButton>
                ) : (
                  <MainButton
                    visualType="white"
                    iconLeft={
                      <ButtonArrow
                        style={{
                          fill: 'var(--color-main)',
                          width: '16px',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    }
                    onClick={() => {
                      setWhatIsPopUpCurrentStep(whatIsPopUpCurrentStep - 1);
                    }}
                  >
                    Back
                  </MainButton>
                )}
              </div>

              <div className={styles['what-is-pop-up__footer-button-list__item']}>
                <MainButton
                  visualType="main"
                  iconRight={<ButtonArrow style={{ fill: 'var(--color-white)', width: '16px' }} />}
                  onClick={() => {
                    if (whatIsSliderStepsContentData.length - 1 === whatIsPopUpCurrentStep) {
                      changeWhatIsPopUpActiveState(false);
                      setWhatIsPopUpCurrentStep(0);

                      if (isUserLoggedIn) {
                        navigate(`/${userRole}/${RoutesEnum.SCHEDULE_MEETING}`);
                      } else {
                        navigate(`/${RoutesEnum.SIGN_UP}`);
                      }
                    } else {
                      setWhatIsPopUpCurrentStep(whatIsPopUpCurrentStep + 1);
                    }
                  }}
                >
                  {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].nextButton}
                </MainButton>
              </div>
            </div>
          }
        >
          <img
            className={styles['what-is-pop-up__bg']}
            src={whatIsAstronomicPopUpBG}
            alt="What is Astronomic background"
          />

          {whatIsPopUpCurrentStep > 0 && (
            <div className={styles['what-is-pop-up__progress-bar']}>
              <div
                className={styles['what-is-pop-up__progress-bar__indicator']}
                style={{
                  width: `${(whatIsPopUpCurrentStep / (whatIsSliderStepsContentData.length - 1)) * 100}%`,
                }}
              ></div>
            </div>
          )}

          {whatIsPopUpCurrentStep === 0 ? (
            <div className={styles['what-is-pop-up__slide']}>
              <div className={styles['what-is-pop-up__slide__inner']}>
                <p className={classNames(styles['what-is-pop-up__sub-title'], 'text text--caps')}>
                  Cofounder as a Service
                </p>

                <h3 className={classNames(styles['what-is-pop-up__title'], 'text text--h3')}>
                  Astronomic is your one-stop shop, providing everything you need to build your company, fund, or
                  practice from idea through exit.
                </h3>
              </div>
            </div>
          ) : (
            <div className={classNames(styles['what-is-pop-up__slide'], styles['what-is-pop-up__slide--not-first'])}>
              <div className={styles['what-is-pop-up__slide__inner']}>
                <div className={styles['what-is-pop-up__icon-container']}>
                  <div className={styles['what-is-pop-up__icon-container__icon']}>
                    {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].icon}
                  </div>
                </div>

                <p className={classNames(styles['what-is-pop-up__steps'], 'text text--caps')}>
                  Step {whatIsPopUpCurrentStep} of 5
                </p>

                <h3 className={classNames(styles['what-is-pop-up__title-2'], 'text text--h3')}>
                  {whatIsSliderStepsContentData[whatIsPopUpCurrentStep].title}
                </h3>

                <p
                  className={classNames(styles['what-is-pop-up__text'], 'text text--body-2')}
                  dangerouslySetInnerHTML={{
                    __html: whatIsSliderStepsContentData[whatIsPopUpCurrentStep].text,
                  }}
                ></p>
              </div>
            </div>
          )}
        </PopUp>,
        document.getElementById('modal') as HTMLElement
      )}
    </>
  );
};

export default WhatIsAstronomicPopUp;
