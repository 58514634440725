import { FunctionComponent, ReactNode } from 'react';
import { Tooltip, ITooltip } from 'react-tooltip';
import styles from './TooltipWrapper.module.scss';

// Define the type for the custom wrapper props, extending the Tooltip props
interface TooltipWrapperProps extends ITooltip {
  text?: string | ReactNode;
  children?: ReactNode;
  onClick?(e?): void;
}

const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({ text, children, onClick, ...tooltipProps }) => {
  return (
    <div className={styles['tooltip-wrapper']} onClick={onClick}>
      <Tooltip {...tooltipProps}>
        {children ? (
          children
        ) : (
          <p className="text text--caption" style={{ textAlign: 'center' }}>
            {text}
          </p>
        )}
      </Tooltip>
    </div>
  );
};

export default TooltipWrapper;
