import { FunctionComponent, useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './CheckboxTiled.module.scss';

interface CheckboxTiledProps extends Pick<HTMLInputElement, 'name' | 'checked' | 'disabled' | 'value'> {
  label?: string | React.ReactElement;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const CheckboxTiled: FunctionComponent<CheckboxTiledProps> = ({
  name,
  label,
  checked = false,
  disabled,
  value,
  onChange,
}) => {
  const [checkedState, setChecked] = useState(checked);

  const checkboxToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChange?.(e);
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <label
      className={classNames(
        styles['checkbox'],
        disabled && styles['checkbox--disabled'],
        checkedState === true && styles['checkbox--active']
      )}
    >
      <input
        className={styles['checkbox__input-original']}
        type="checkbox"
        checked={checkedState}
        name={name}
        disabled={disabled}
        value={value}
        onChange={checkboxToggle}
      />
      <div className={styles['checkbox__input-custom']}></div>

      {label && <p className={styles['checkbox__label']}>{label}</p>}
    </label>
  );
};

export default CheckboxTiled;
