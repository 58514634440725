import { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './BackgroundSectionTemplate.module.scss';

export type BackgroundSectionTemplateColorType =
  | 'white'
  | 'gray'
  | 'blue'
  | 'blueGradient'
  | 'Background-Neutral-Primary'
  | 'Background-Neutral-Secondary';

const BackgroundSectionTemplateColorMap: Record<BackgroundSectionTemplateColorType, string> = {
  white: '',
  gray: 'background--color-gray',
  blue: 'background--color-blue',
  blueGradient: 'background--color-blue-gradient',
  'Background-Neutral-Primary': 'background--color-neutral-primary',
  'Background-Neutral-Secondary': 'background--color-neutral-secondary',
};

interface BackgroundTemplateProps extends PropsWithChildren {
  className?: string;
  color?: BackgroundSectionTemplateColorType;
}

const BackgroundSectionTemplate: FunctionComponent<BackgroundTemplateProps> = ({
  children,
  className,
  color = 'white',
}) => {
  return (
    <div
      className={classNames(styles['background'], 'background', BackgroundSectionTemplateColorMap[color], className)}
    >
      {children}
    </div>
  );
};

export default BackgroundSectionTemplate;
