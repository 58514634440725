import { FunctionComponent, useEffect } from 'react';
import CreatePasswordTemplate from '../../containers/templates/CreatePasswordTemplate/CreatePasswordTemplate';
import { ApiResponseDTO } from '../../dto/api';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useAppDispatch } from '../../redux/hooks';
import { login } from '../../utils/auth/login';
import { localStorageGetItem, localStorageSetItem } from '../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../enums/localStorageKeys';
import RoutesEnum from '../../enums/routes';

interface SignUpCreatePasswordViewProps {}

const SignUpCreatePasswordView: FunctionComponent<SignUpCreatePasswordViewProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP) !== '3') {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }
  }, []);

  const handleOnSubmitApiRequest = async (password: string) => {
    let noErrors = true;
    let responseAccessToken = '';
    let responseRefreshToken = '';
    let responseUserId = '';

    const userId = localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID);
    const userEmail = localStorageGetItem(LOCAL_STORAGE_KEYS.USER_EMAIL);

    try {
      const response = await api.Auth.setUserPassword({
        userId: userId,
        password: password,
      });
      const responseData = response.data as ApiResponseDTO;

      if (responseData.success === true && responseData.data) {
        // LOGIN
        try {
          const response = await api.Auth.login({
            email: userEmail,
            password: password,
          });

          const responseData = response.data as ApiResponseDTO<{
            userId: string;
            refreshToken: string;
            token: string;
          }>;

          if (responseData.success === true && responseData.data) {
            responseAccessToken = responseData.data.token;
            responseRefreshToken = responseData.data.refreshToken;
            responseUserId = responseData.data.userId;
          } else {
            console.error('Error:', response.error);

            noErrors = false;
          }
        } catch (error) {
          console.error('Error: ', error);

          noErrors = false;
        }
        // LOGIN END

        if (noErrors === true) {
          localStorageSetItem(LOCAL_STORAGE_KEYS.USER_SIGN_UP_STEP, '4');
          // localStorage.setItem('userSignUpStep', '4');

          login(dispatch, responseAccessToken, responseRefreshToken, responseUserId, {
            navigate: navigate,
            navigateUrl: `/${RoutesEnum.SIGN_UP}/${RoutesEnum.NAME_AND_ROLES}`,
          });

          return { success: true, message: 'All good. Proceeding to the next step...' };
        } else {
          return { success: false, message: 'Something went wrong. Please, try again later' };
        }
      } else {
        console.error('Error:', response.error);
        return { success: false, message: 'An error occurred.' };
      }
    } catch (error) {
      const errorObj = error as AxiosError<ApiResponseDTO>;
      const errorData = errorObj.response?.data;

      if (errorData?.errorMessage) {
        console.error('Error: ', errorData.errorMessage);
        return { success: false, message: errorData.errorMessage };
      }

      console.error('Error: ', error);
      return { success: false, message: 'An error occurred.' };
    }
  };

  return <CreatePasswordTemplate title="Choose a password" onSubmitApiRequest={handleOnSubmitApiRequest} />;
};

export default SignUpCreatePasswordView;
