import React from "react";

export interface ComponentWithAsyncImportProps {}

export interface ComponentWithAsyncImportState {
  component?: any;
}

const withAsyncImport = (
  importComponentFun: () => any
): React.ComponentType => {
  return class ComponentWithAsyncImport extends React.Component<
    ComponentWithAsyncImportProps,
    ComponentWithAsyncImportState
  > {
    constructor(props: ComponentWithAsyncImportProps) {
      super(props);
      this.state = {};
    }

    componentDidMount() {
      importComponentFun().then((cmp: any) => {
        this.setState({
          component: cmp.default,
        });
      });
    }

    render() {
      let C = this.state.component;
      return C ? <C {...this.props} /> : null;
    }
  };
};

export default withAsyncImport;
