import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './RadioList.module.scss';

export interface RadioData {
  value: string;
  label: string;
  disabled?: boolean;
}

interface RadioListProps extends Pick<HTMLInputElement, 'name'> {
  sizeSmall?: boolean;
  data: RadioData[];
  checkedValue?: string;
  className?: string;
  onChange?(data): void;
}

const RadioList: FunctionComponent<RadioListProps> = ({ name, sizeSmall, data, checkedValue, className, onChange }) => {
  const [radioData, setRadioData] = useState<string | undefined>(checkedValue);

  const onChangeHandler = (value) => {
    setRadioData(value);
    onChange?.({ name: name, value: value });
  };

  useEffect(() => {
    setRadioData(checkedValue);
  }, [checkedValue]);

  return (
    <fieldset className={classNames(styles['radio-list'], className)}>
      {data.map((item) => (
        <div className={styles['radio-list__item']} key={item.value}>
          <label
            className={classNames(
              styles['radio'],
              sizeSmall && styles['radio--small'],
              item.disabled && styles['radio--disabled']
            )}
          >
            <input
              className={styles['radio__input-original']}
              type="radio"
              checked={item.value === radioData}
              name={name}
              disabled={item.disabled}
              onChange={() => onChangeHandler(item.value)}
              value={item.value}
            />
            <div className={styles['radio__input-custom']}></div>

            <p className={styles['radio__label']}>{item.label}</p>
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export default RadioList;
