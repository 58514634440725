import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import Label from '../../../../../components/texts/Label/Label';
import styles from './AccountRoleEdit.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CompleteProfileTemplate from '../../../CompleteProfileTemplate/CompleteProfileTemplate';
import GroupWithGap from '../../../../../components/lists/GroupWithGap/GroupWithGap';
import api from '../../../../../api';
import { useAppSelector } from '../../../../../redux/hooks';
import { AxiosError } from 'axios';
import callToast from '../../../../../components/blocks/ToastMessage/_parts/callToast/callToast';
import { ApiResponseDTO } from '../../../../../dto/api';
import RoutesEnum from '../../../../../enums/routes';
import PreloadLine from '../../../../../components/blocks/PreloadLine/PreloadLine';

interface AccountRoleEditProps {}

const AccountRoleEdit: FunctionComponent<AccountRoleEditProps> = () => {
  const childRef = useRef<{ handleSubmit: (e?: React.FormEvent) => Promise<void> }>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [roleInfo, setRoleInfo] = useState<Array<any>>();

  const searchParams = new URLSearchParams(location.search);
  const roleId = searchParams.get('roleId');
  const title = searchParams.get('title');

  const userId = useAppSelector((state) => state.user.user?.userId as string);

  const handleRoleSave = async () => {
    if (childRef.current) {
      const result = (await childRef.current.handleSubmit()) as any;

      if (result === true) {
        callToast({
          title: `${title}`,
          children: <>Has been saved</>,
          variation: 'success',
        });

        navigate(`/${RoutesEnum.ACCOUNT}/${RoutesEnum.PROFILE}/${RoutesEnum.ROLE}?roleId=${roleId}&title=${title}`);
      }
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await api.Onboarding.getUserDataByRole(userId, roleId as string);

        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setRoleInfo(responseData.data);
        } else {
          console.error('Error:', response.data.errors);
        }
      } catch (error) {
        const errorObj = error as AxiosError<ApiResponseDTO>;
        const errorData = errorObj.response?.data;

        if (errorData?.errorMessage) {
          console.error(errorData.errorMessage);
        }

        callToast({
          title: 'Error',
          children: <>Something went wrong. Please try again later</>,
          variation: 'error',
        });

        console.error('Error: ', error);
      }
    };

    getRoles();
  }, []);

  return (
    <div className={styles['role']}>
      <NavigationLink
        className={styles['role__back-link']}
        path="/account/profile"
        iconLeft={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
        withBorder={false}
      >
        Back to Profile
      </NavigationLink>

      <div className={styles['title-container']}>
        <h3 className={classNames(styles['title-container__text'], 'text text--h3')}>{title}</h3>

        <GroupWithGap
          items={[
            <MainButton
              sizeType="medium"
              visualType="white"
              onClick={() => {
                navigate(
                  `/${RoutesEnum.ACCOUNT}/${RoutesEnum.PROFILE}/${RoutesEnum.ROLE}?roleId=${roleId}&title=${title}`
                );
              }}
            >
              Cancel
            </MainButton>,
            <MainButton sizeType="medium" visualType="main" onClick={handleRoleSave}>
              Save
            </MainButton>,
          ]}
        />
      </div>

      <main className={styles['main']}>
        {roleInfo ? (
          <CompleteProfileTemplate
            formSchemaProp={roleInfo}
            outputBySteps={false}
            ref={childRef}
            hideFooter={true}
            hideHeader={true}
            hideSidebar={true}
            hideTitle={true}
          />
        ) : (
          [1, 2, 3].map(() => (
            <div className={styles['info__value-group']} style={{ marginTop: '24px' }}>
              <div className={classNames(styles['info'], 'tile tile--no-shadow tile--padding-32px')}>
                <div
                  className={classNames(styles['info__title'], 'text text--body-2 text--bold')}
                  style={{ maxWidth: '50%' }}
                >
                  Display name
                  <PreloadLine />
                </div>

                <p
                  className={classNames(styles['info__text'], 'text text--body-2')}
                  style={{ maxWidth: '30%', marginTop: '16px' }}
                >
                  Answer
                  <PreloadLine />
                </p>
              </div>
            </div>
          ))
        )}
      </main>

      <footer className={styles['footer']}>
        <div className={styles['footer__button-group']}>
          <MainButton
            sizeType="large"
            visualType="white"
            onClick={() => {
              navigate(
                `/${RoutesEnum.ACCOUNT}/${RoutesEnum.PROFILE}/${RoutesEnum.ROLE}?roleId=${roleId}&title=${title}`
              );
            }}
          >
            Cancel
          </MainButton>

          <MainButton sizeType="large" visualType="main" onClick={handleRoleSave}>
            Save
          </MainButton>
        </div>
      </footer>
    </div>
  );
};

export default AccountRoleEdit;
