export const isTokenValid = (token: string) => {
  if (!token) {
    return false;
  }

  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));

    const expirationTimeInSeconds = decodedToken.exp;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    return expirationTimeInSeconds > currentTimeInSeconds;
  } catch (error) {
    console.error('Error decoding or validating token:', error);
    return false;
  }
};