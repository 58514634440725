import { FunctionComponent, useState, useEffect, ChangeEvent } from "react";
import classNames from "classnames";
import styles from "./Checkbox.module.scss";

interface CheckboxProps
  extends Pick<HTMLInputElement, "name" | "checked" | "disabled" | "value"> {
  label?: string | React.ReactElement;
  sizeSmall?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  name,
  label,
  checked = false,
  disabled,
  value,
  sizeSmall,
  onChange,
}) => {
  const [checkedState, setChecked] = useState(checked);

  const checkboxToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChange?.(e);
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <label
      className={classNames(
        styles["checkbox"],
        sizeSmall && styles["checkbox--small"],
        disabled && styles["checkbox--disabled"]
      )}
    >
      <input
        className={styles["checkbox__input-original"]}
        type="checkbox"
        checked={checkedState}
        name={name}
        disabled={disabled}
        value={value}
        onChange={checkboxToggle}
      />
      <div className={styles["checkbox__input-custom"]}></div>

      {label && <p className={styles["checkbox__label"]}>{label}</p>}
    </label>
  );
};

export default Checkbox;
