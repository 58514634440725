import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastifyContent from '../ToastifyContent/ToastifyContent';
import { ToastMessageBaseProps } from '../../ToastMessage';

interface ToastContentProps extends ToastMessageBaseProps {
  error?: any;
  options?: ToastOptions;
}

const callToast = ({
  title = 'Something went wrong',
  variation = 'error',
  actionText = 'Action',
  action,
  children,
  error,
  options,
}: ToastContentProps): void => {
  if (variation) {
    toast[variation](() => {
      return (
        <ToastifyContent title={title} action={action} actionText={actionText} variation={variation}>
          {children}
        </ToastifyContent>
      );
    }, options);
  } else {
    toast(
      () => (
        <ToastifyContent title={title} action={action} actionText={actionText} variation={variation}>
          {children}
        </ToastifyContent>
      ),
      options
    );
  }

  if (error) {
    console.error('Error: ', error);
  }
};

export default callToast;
