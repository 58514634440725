import { FunctionComponent } from 'react';
import { NavLink, To } from 'react-router-dom';
import classNames from 'classnames';
import styles from './AccountSidebar.module.scss';

export interface AccountSidebarLink {
  icon: React.ReactElement;
  text: string;
  url: To;
}

interface AccountSidebarProps {
  items: AccountSidebarLink[];
}

const AccountSidebar: FunctionComponent<AccountSidebarProps> = ({ items }) => {
  return (
    <aside className={styles['sidebar']}>
      <ul className={styles['list']}>
        {items.map((item, i) => (
          <li className={styles['item']} key={i}>
            <NavLink to={item.url} className={styles['link']}>
              <div className={styles['link__icon']}>{item.icon}</div>

              <p className={classNames(styles['link__text'], 'text text--body-2 text--bold')}>{item.text}</p>
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default AccountSidebar;
