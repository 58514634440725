import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as FundIll } from '../../asset/images/fund_ill.svg';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import Grid from '../../components/blocks/Grid/Grid';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';
import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';

import { ReactComponent as PersonCheckIcon } from '../../asset/images/icons/person_check.svg';
import { ReactComponent as DollarIcon } from '../../asset/images/icons/dollar.svg';
import { ReactComponent as ContractIcon } from '../../asset/images/icons/contract.svg';
import { ReactComponent as PeopleIcon } from '../../asset/images/icons/people.svg';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import PriceBillet from '../../components/blocks/PriceBillet/PriceBillet';
import RoutesEnum from '../../enums/routes';
import { useAppSelector } from '../../redux/hooks';
import fundPageContent from '../../data/fund';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';
import JoinForFreeButton from '../../components/buttons/JoinForFreeButton/JoinForFreeButton';

interface FundViewProps extends IWithUserRoleProps {}

const FundView: FunctionComponent<FundViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages>
      <HeroSection
        secondaryTitle="Fund"
        title="Raise capital to accelerate your growth"
        text={fundPageContent[user_role]}
        sectionVariation="var-1"
        illustration={<FundIll style={{ maxHeight: '400px' }} />}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <CardsWithHeaderSection
        title="Fund"
        text="Apply for equity or non-equity funding."
        sectionBackgroundColor="Background-Neutral-Primary"
        sectionVariation="var-1"
        button={<JoinForFreeButton />}
        customElement={
          <PriceBillet
            price="$160-$250"
            linkText="How hourly billing works"
            linkUrl={`/${user_role}/${RoutesEnum.PRICING}`}
          />
        }
      >
        <Grid
          itemGroup={[
            <ServiceCard
              title="Venture Capital"
              text="Apply for funding consideration by our investment team"
              textBeforePrice=""
              price={null}
              link={`/${user_role}/${RoutesEnum.FUND}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
            <ServiceCard
              title="Startup Studio"
              text="Apply for funding consideration by our investment team"
              textBeforePrice=""
              price={null}
              link={`/${user_role}/${RoutesEnum.FUND}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
            <ServiceCard
              title="Venture Debt"
              text="Apply for funding consideration by our investment team"
              textBeforePrice=""
              price={null}
              link={`/${user_role}/${RoutesEnum.FUND}/${RoutesEnum.SERVICE_DETAIL}`}
            />,
          ]}
          itemsInRow={3}
        />
      </CardsWithHeaderSection>

      <ChildrenWithHeaderSection
        title={<TitleWithSecTitle secTitle="Our thesis" title="Proven Founders with Revenue" />}
        backgroundColor="Background-Neutral-Primary"
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="Founder-focused"
              text="Our venture capital team invests in founder-focused companies in sectors like future of work, dev tools, marketing tech, and sales tech."
              icon={<PersonCheckIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Revenue required"
              text="While we will back a pre-seed startup, we require revenue to show proof of traction. This can be in the form of pre-sales, consulting revenue, or revenue achieves from software sales."
              icon={<DollarIcon style={{ fill: 'var(--color-main)' }} />}
            />,
            <TitleWithIconAndTextCard
              title="ARR collateral"
              text="Our venture debt team will only lend to SaaS companies with $1M ARR or more. We’ll lend against your future annual ARR contracts."
              icon={<ContractIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Institutional cofounder"
              text="Consider us for your venture studio. We’re lean startup specialists with a wide breadth of services and skills across disciplines. "
              icon={<PeopleIcon style={{ fill: 'var(--color-main)' }} />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="white" />

      <TestimonialsSection /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(FundView);
