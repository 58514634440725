import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Mesh, Color } from 'three';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import gsap from 'gsap';
import { MeshDistortMaterial, OrbitControls, PerspectiveCamera, Sparkles, Stars } from '@react-three/drei';
import Nebula, { SpriteRenderer } from 'three-nebula';
import json from '../../../asset/particles/rocket_particles.json';
import NebulaEngine, { NebulaSystem } from './NebulaEngine';
import { useGSAP } from '@gsap/react';
import classNames from 'classnames';
import styles from './ThreeSceneFiber.module.scss';
import {
  BloomEffect,
  DotScreenEffect,
  EffectComposer,
  EffectPass,
  GlitchEffect,
  RenderPass,
  ShockWaveEffect,
} from 'postprocessing';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../enums/routes';

interface Props {}

interface CustomMeshProps {
  launch3dScene?: Boolean;
  is3dSceneReady?: Boolean;
  handle3dSceneReady?(flag: boolean): void;
  navigate?: NavigateFunction;
}

const CustomMesh: React.FC<CustomMeshProps> = ({ launch3dScene, is3dSceneReady, handle3dSceneReady, navigate }) => {
  const [updateParticles, setUpdateParticles] = useState<Boolean>(true);
  const [particleSystem, setParticleSystem] = useState<any>();
  const [isParticlesForceDown, setParticlesForceDown] = useState<Boolean>(true);
  const [isExploding, setIsExploding] = useState<Boolean>(false);

  const cameraRef = useRef() as any;

  const { camera, scene, gl } = useThree();

  // const [particleSystem, setParticleSystem] = useState<NebulaSystem>();

  useEffect(() => {
    NebulaEngine.loadSystem(json as unknown as JSON, scene).then((nebulaSystem) => {
      setParticleSystem(nebulaSystem);
    });
  }, []);

  useFrame(() => {
    if (particleSystem) {
      particleSystem.emitters[0].position = meshRef.current.position;
      particleSystem.emitters[0].behaviours[3].force = { x: 0, y: 0, z: 0, id: 0.5411449242512609 };

      if (isParticlesForceDown) {
        particleSystem.emitters[0].behaviours[3].force = { x: 4, y: -20, z: 0, id: 0.5411449242512609 };
      }

      if (launch3dScene) {
        if (updateParticles) {
          NebulaEngine.update(particleSystem);
        } else {
          particleSystem.emitters[0].position = new THREE.Vector3(-1000, 0, 0);
          NebulaEngine.update(particleSystem);
        }
      }
    }
  });

  const gltf = useLoader(GLTFLoader, '/Space_rocket.glb');
  const meshRef = useRef<Mesh>() as any;

  useEffect(() => {
    if (gltf && gltf.scene) {
      gltf.scene.traverse((child) => {
        if (child instanceof Mesh) {
          if (handle3dSceneReady) {
            handle3dSceneReady(true);
          }
          // meshRef.current = child;
        }
      });
    }
  }, [gltf]);

  // SHOCKWAVE
  const composer = new EffectComposer(gl);
  composer.addPass(new RenderPass(scene, camera));

  const shockWaveEffect = new ShockWaveEffect(camera, new THREE.Vector3(5.45, 40, -11), {
    speed: 2,
    maxRadius: 0.35,
    waveSize: 0.7,
    amplitude: 0.25,
  });

  composer.addPass(new EffectPass(camera, shockWaveEffect));

  const bloomEffect = new BloomEffect({
    intensity: 1.2, // You can adjust intensity here
    luminanceThreshold: 0.2,
    luminanceSmoothing: 0.9,
    height: 240, // Optional: tweak for performance and visual adjustments
  });

  composer.addPass(new EffectPass(camera, bloomEffect));

  useFrame(() => {
    shockWaveEffect.mainCamera = camera;
    // shockWaveEffect.position = meshRef.current.position;
    composer.render();
  });

  useEffect(() => {
    shockWaveEffect.explode();
  }, [isExploding]);
  // SHOCKWAVE END

  useGSAP(
    () => {
      if (launch3dScene && is3dSceneReady) {
        const tl = gsap.timeline();

        tl.to(
          meshRef.current.position,
          {
            y: 0.1,
            ease: 'back.in',
            duration: 1,
          },
          'one'
        )
          .to(
            meshRef.current.position,
            {
              y: 40,
              ease: 'power1.inOut',
              duration: 0.6,
            },
            'two'
          )
          .to(
            camera.position,
            {
              delay: 0.8,
              x: 5,
              y: 15,
              z: -10,
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {},
              onUpdate: (event) => {
                // camera.lookAt(meshRef.current.position);
                // camera.quaternion.copy(startOrientation).slerp(meshRef.current.quaternion.clone().normalize(), 1);
              },
            },
            'one'
          )
          .to(
            camera.rotation,
            {
              delay: 0.8,
              x: THREE.MathUtils.degToRad(90),
              // y: THREE.MathUtils.degToRad(45),
              z: THREE.MathUtils.degToRad(-15),
              duration: 1,
              ease: 'power1.inOut',
              onStart: () => {},
              onUpdate: () => {
                // camera.lookAt(meshRef.current.position);
              },
            },
            'one'
          )

          .to(
            meshRef.current.position,
            {
              delay: 0.6,
              x: 0,
              y: 150,
              // y: 150,
              z: 0,
              // z: -100,
              ease: 'power2.inOut',
              duration: 1,
              onStart: () => {
                setIsExploding(true);
                setParticlesForceDown(false);
              },
              onUpdate: () => {
                // camera.lookAt(meshRef.current.position);
              },
            },
            'two'
          )

          .to(
            meshRef.current.rotation,
            {
              delay: 0.6,
              x: 0,
              y: -3,
              z: 0,
              ease: 'power2.inOut',
              duration: 1,
            },
            'two'
          )

          .to(
            camera.position,
            {
              delay: 0.6,
              x: 0.3,
              y: 148,
              z: 2,
              duration: 0.9,
              ease: 'back.in',
              onStart: () => {},
              onUpdate: () => {
                // camera.lookAt(meshRef.current.position);
              },
            },
            'two'
          )
          .to(
            camera.rotation,
            {
              delay: 1.1,
              x: THREE.MathUtils.degToRad(80),
              // y: THREE.MathUtils.degToRad(45),
              z: THREE.MathUtils.degToRad(-7.5),
              duration: 0.5,
              ease: 'power1.inOut',
              onStart: () => {},
              onUpdate: () => {
                // camera.lookAt(meshRef.current.position);
              },
            },
            'two'
          )
          .to(
            '.gsap__overlay',
            {
              delay: 1.46,
              opacity: 1,
              duration: 0.1,
              ease: 'power1.Out',
              onStart: () => {
                setUpdateParticles(false);
              },
              onComplete: () => {
                if (navigate) {
                  navigate(`/${RoutesEnum.SIGN_UP}`, { state: { from: 'startup' } });
                }
              },
            },
            'two'
          );

        // tl.progress(0.8);
        // tl.pause();
      }
    },
    { dependencies: [launch3dScene, is3dSceneReady] }
  );

  return (
    // position={[4, -3, -6]}
    <>
      <perspectiveCamera />
      <group position={[5.45, -4, -11]} rotation={[0, Math.PI - 0.2, 0]} ref={meshRef}>
        <mesh
        // position={[4.3, -3, -20]}
        // rotation={[0, 2.95, 0]}
        // onPointerOver={() => {
        //   if (meshRef.current) meshRef.current.dispatchEvent({ type: 'pointerover' });
        // }}
        // onPointerOut={() => {
        //   if (meshRef.current) meshRef.current.dispatchEvent({ type: 'pointerout' });
        // }}
        // onClick={handleClick}
        ></mesh>

        <primitive object={gltf.scene} />
      </group>
    </>
  );
};

interface ShockWaveProps {}

interface ThreeSceneFiberProps {
  launch3dScene?: Boolean;
  is3dSceneReady?: Boolean;
  handle3dSceneReady?(flag: boolean): void;
}

const ThreeSceneFiber: React.FC<ThreeSceneFiberProps> = ({ launch3dScene, is3dSceneReady, handle3dSceneReady }) => {
  const navigate = useNavigate();

  return (
    <>
      <Canvas>
        <ambientLight intensity={0.7} />
        {/* <pointLight position={[10, 10, 10]} intensity={0.1} /> */}

        <directionalLight position={[-10, -4, 3]} intensity={1} color={'#ffffff'} />
        <directionalLight position={[0, -4, -11]} intensity={1} color={'#7BD7F1'} />
        <pointLight position={[12, 0, -11]} intensity={1} color={'#4D7ABD'} />

        {/* <mesh position={[0, 0, -50]} rotation={[0, 0, 0]} scale={[100, 100, 1]}>
      <planeBufferGeometry attach="geometry" args={[100, 100]} />
      <meshBasicMaterial attach="material" color="#23286D" />
    </mesh> */}

        <CustomMesh
          launch3dScene={launch3dScene}
          handle3dSceneReady={handle3dSceneReady}
          is3dSceneReady={is3dSceneReady}
          navigate={navigate}
        />

        <Stars radius={300} depth={100} count={3000} factor={20} saturation={2} speed={4} fade />

        {/* <OrbitControls /> */}
      </Canvas>
      <div className={classNames(styles['overlay'], 'gsap__overlay')}></div>
    </>
  );
};

export default ThreeSceneFiber;
