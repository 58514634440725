import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './PreloadProductCard.module.scss';
import MainButton from '../../buttons/MainButton/MainButton';
import PreloadLine from '../PreloadLine/PreloadLine';

interface PreloadProductCardProps {
  showTitle?: boolean;
  showText?: boolean;
  showButton?: boolean;
  visualType?: 'default' | '1';
  className?: string;
}

const PreloadProductCard: FunctionComponent<PreloadProductCardProps> = ({
  showTitle = true,
  showText = true,
  showButton = true,
  visualType = 'default',
  className,
}) => {
  return (
    <article
      className={classNames(
        styles['subscription-card'],
        styles[`subscription-card--type-${visualType}`],
        'button-container-for-hover',
        className
      )}
    >
      <div className={styles['info-container']}>
        <div className={styles['info-container__top-part']}>
          {showTitle && (
            <div className={styles['title']}>
              <h5 className="text text--h5">Preloading</h5>
              <PreloadLine />
            </div>
          )}

          {showText && (
            <div className={styles['price']}>
              <p className="text text--caption">Preloading</p>
              <div className={styles['price__dot']}></div>
              <p className="text text--caption">Preloading</p>

              <PreloadLine />
            </div>
          )}
        </div>

        <div className={styles['info-container__bottom-part']}>
          {showButton && (
            <MainButton className={styles['button']} sizeType="medium" visualType="main">
              Preloading
              <PreloadLine />
            </MainButton>
          )}
        </div>
      </div>
    </article>
  );
};

export default PreloadProductCard;
