import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserDTO } from "../../../dto/user";
import user_builder from "./builder";
import { IReduxThunkEvent } from "../../../types/redux";

export type IUserSliceState = {
    user: IUserDTO | null;
    userLogged: boolean;
    statuses: {
        fetch_user_info: IReduxThunkEvent
    }
};

const initial_state: IUserSliceState = {
    user: null,
    userLogged: false,
    statuses: {
        fetch_user_info: {
            state: 'idle'
        }
    }
}

const user_slice = createSlice({
    name: "user",
    initialState: initial_state,
    reducers: {
        setUser(state, action: PayloadAction<IUserDTO>) {
            return {
                ...state,
                user: action.payload,
            }
        },
        setStripeCustomerId(state, action: PayloadAction<string>) {
            return {
                ...state,
                user: {
                    ...state.user!,
                    stripeCustomerId: action.payload,
                },
            };
        },
        setPaymentMethodId(state, action: PayloadAction<string>) {
            return {
                ...state,
                user: {
                    ...state.user!,
                    stripeDefaultPaymentMethodId: action.payload,
                },
            };
        },
        clearUser(state) {
            return {
                ...state,
                user: null,
                userLogged: false
            }
        }
    },
    extraReducers: user_builder,
})

export default user_slice.reducer;

export const { setUser, setStripeCustomerId, setPaymentMethodId, clearUser } = user_slice.actions;