import { FunctionComponent, PropsWithChildren, useRef, useState, useEffect } from 'react';
import { ReactComponent as CrossIcon } from '../../../asset/images/icons/cross.svg';
import styles from './PopUp.module.scss';
import classNames from 'classnames';
import OnlyIconButton from '../../buttons/OnlyIconButton/OnlyIconButton';

interface PopUpProps extends PropsWithChildren {
  title?: string;
  footer?: React.ReactElement;
  active: boolean;
  popUpWidth?: '560' | '640' | '720';
  className?: string;
  onPopUpStateChange?(state: boolean): void;
}

const PopUp: FunctionComponent<PopUpProps> = ({
  title,
  children,
  footer,
  active,
  popUpWidth = '640',
  className,
  onPopUpStateChange,
}) => {
  const popUpRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target as Node)) {
        onPopUpStateChange?.(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onPopUpStateChange]);

  return active === true ? (
    <div className={classNames(styles['pop-up-container'], className)}>
      <div className={styles['pop-up-background']}></div>

      <div className={classNames(styles['pop-up'], 'pop-up')} style={{ maxWidth: `${popUpWidth}px` }} ref={popUpRef}>
        <div className={styles['pop-up__header']}>
          <h4 className={classNames(styles['pop-up__header__title'], 'text text--h4')}>{title}</h4>

          <OnlyIconButton
            icon={<CrossIcon />}
            onClick={() => {
              onPopUpStateChange?.(false);
            }}
            className={styles['pop-up__header__close-button']}
          />
        </div>

        <div className={classNames(styles['pop-up__body'], 'pop-up__body')}>{children}</div>

        {footer && <div className={styles['pop-up__footer']}>{footer}</div>}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PopUp;
