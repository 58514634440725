import { FunctionComponent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./Content.module.scss";

interface ContentProps extends PropsWithChildren {}

const Content: FunctionComponent<ContentProps> = ({ children }) => {
  return (
    <div className={classNames(styles["content"], "content")}>{children}</div>
  );
};

export default Content;
