import { FunctionComponent, useState, useEffect } from "react";
import TitleWithIcon from "../../../../../components/titles/TitleWithIcon/TitleWithIcon";
import { ReactComponent as MailIcon } from "../../../../../asset/images/icons/mail.svg";
import classNames from "classnames";
import styles from "./AccountNotifications.module.scss";
import ToggleSwitch from "../../../../../components/inputs/ToggleSwitch/ToggleSwitch";
import Grid from "../../../../../components/blocks/Grid/Grid";

interface AccountNotificationsProps {}

const AccountNotifications: FunctionComponent<AccountNotificationsProps> =
  () => {
    const [constellationDinners, setConstellationDinners] =
      useState<boolean>(false);
    const [statementOfWork, setStatementOfWork] = useState<boolean>(false);

    const handleConstellationDinners = (newChecked: boolean) => {
      setConstellationDinners(newChecked);
    };

    const handleStatementOfWork = (newChecked: boolean) => {
      setStatementOfWork(newChecked);
    };

    useEffect(() => {
      setConstellationDinners(true);
      setStatementOfWork(true);
    }, []);

    return (
      <div className={styles["notifications"]}>
        <TitleWithIcon
          className={styles["title"]}
          icon={
            <MailIcon style={{ fill: "var(--color-main)", width: "29px" }} />
          }
        >
          <h3 className={"text text--h3"}>Notifications</h3>
        </TitleWithIcon>

        <p className={classNames(styles["text"], "text text--body-1")}>
          You have the right to decide how Astronomic may communicate with you.
          Select from the options below and review your data privacy rights.
        </p>

        <div className={styles["list"]}>
          <Grid
            itemsInRow={2}
            gridHorizontalMargin={3}
            itemGroup={[
              <div
                className={classNames(
                  styles["list__item"],
                  "tile tile-no-shadow tile--padding-32px"
                )}
              >
                <header className={styles["list__item__header"]}>
                  <p
                    className={classNames(
                      styles["list__item__header__title"],
                      "text text--h5"
                    )}
                  >
                    Constellation Dinners
                  </p>

                  <ToggleSwitch
                    checked={constellationDinners}
                    onChange={handleConstellationDinners}
                  />
                </header>

                <p
                  className={classNames(
                    styles["list__item__text"],
                    "text text--body-2"
                  )}
                >
                  We may send you email based on the preferences that you
                  specify.
                </p>
              </div>,
              <div
                className={classNames(
                  styles["list__item"],
                  "tile tile-no-shadow"
                )}
              >
                <header className={styles["list__item__header"]}>
                  <p
                    className={classNames(
                      styles["list__item__header__title"],
                      "text text--h5"
                    )}
                  >
                    Statement of Work
                  </p>

                  <ToggleSwitch
                    checked={statementOfWork}
                    onChange={handleStatementOfWork}
                  />
                </header>

                <p
                  className={classNames(
                    styles["list__item__text"],
                    "text text--body-2"
                  )}
                >
                  You will receive emails every time a new SOW is approved.
                </p>
              </div>,
            ]}
          />
        </div>
      </div>
    );
  };

export default AccountNotifications;
