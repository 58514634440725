export type NavigateParams = {
  navigate: (url: string) => void;
  navigateUrl: string;
};

export const navigateIfPossible = (navigateParams?: NavigateParams): void => {
  if (navigateParams && navigateParams.navigate && navigateParams.navigateUrl) {
    navigateParams.navigate(navigateParams.navigateUrl);
  }
};
