import { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './FooterSection.module.scss';
import { ReactComponent as Illustration } from '../../../asset/images/ill_two_ppl_touching_2.svg';
import { ReactComponent as BgElements } from '../../../asset/images/bg_elements.svg';

export interface FooterSectionProps {
  footerSectionVariation?: 'var-0' | 'var-1';
  title: string;
  text: string;
  buttonGroup?: React.ReactElement[];
}

const FooterSection: FunctionComponent<FooterSectionProps> = ({
  footerSectionVariation = 'var-0',
  title,
  text,
  buttonGroup,
}) => {
  return (
    <section className={classNames(styles['section'], styles[`section--${footerSectionVariation}`])}>
      <div className="background">
        <div className={classNames(styles['bg-elements'])}>
          <BgElements />
        </div>
      </div>

      <div className="wrapper">
        <div className="content">
          <div className={classNames(styles['block-list'])}>
            <div className={classNames(styles['block-list__item-1'])}>
              <div className={classNames(styles['title'])}>
                <h2 className="text text--h2">{title}</h2>
              </div>

              <div className={classNames(styles['text'])}>
                <p className="text text--body-1">{text}</p>
              </div>

              {buttonGroup && (
                <div className={styles['button-group']}>
                  {buttonGroup.map((item, i) => (
                    <div className={styles['button-group__item']} key={i}>
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={classNames(styles['block-list__item-2'])}>
              <div className={classNames(styles['image'])}>
                <Illustration />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
