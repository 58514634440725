import { FunctionComponent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './StartupSection.module.scss';
import { UserRoleEnum } from '../../../enums/user';
import { UserRolePlural } from '../../../data/role';
import { ReactComponent as Smoke } from '../../../asset/images/startup/smoke.svg';
import { ReactComponent as Rocket } from '../../../asset/images/startup/full_rocket.svg';
import { ReactComponent as Planet } from '../../../asset/images/startup/planet.svg';
import { ReactComponent as Moon } from '../../../asset/images/startup/moon.svg';
import { ReactComponent as Astronaut } from '../../../asset/images/startup/astronaut.svg';
// import { ReactComponent as StartUpBg } from '../../../asset/images/startup/startup_bg.svg';
import StartUpBg from '../../../asset/images/startup/startup_bg.svg';
import { ReactComponent as AstronomicLogo } from '../../../asset/images/astronomic_logo_3.svg';
import { ReactComponent as CrossIcon } from '../../../asset/images/icons/cross.svg';
import BigSmoke from '../../../asset/lotties/startup_rocket_big_smoke.json';
import BigSmokeForLaunch from '../../../asset/lotties/startup_rocket_big_smoke_for_launch.json';
import Dude from '../../../asset/lotties/startup_dude.json';
import MainButton from '../../buttons/MainButton/MainButton';
import OnlyIconButton from '../../buttons/OnlyIconButton/OnlyIconButton';
import ThreeSceneFiber from '../../../views/TestView/_parts/ThreeSceneFiber';
import Lottie, { AnimationItem } from 'lottie-web';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import RoutesEnum from '../../../enums/routes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { startUpSectionContent } from '../../../data/home';
import HowRocketFuelWorksPopUp from '../../popups/HowRocketFuelWorksPopUp/HowRocketFuelWorksPopUp';
import TextWithIconForPopUp from '../../texts/TextWithIconForPopUp/TextWithIconForPopUp';
import { setShouldScroll } from '../../../redux/slices/scroll/slice';

interface StartupSectionProps {
  className?: string;
  title: string | React.ReactElement;
  text: string | React.ReactElement;
  buttonGroup?: React.ReactElement[] | null;
  userRole?: UserRoleEnum;
  startAnimation: Boolean;
  skipToRocket?: Boolean;
  setStartAnimation(state: Boolean): void;
}

const StartupSection: FunctionComponent<StartupSectionProps> = ({
  className,
  title,
  text,
  buttonGroup,
  userRole,
  startAnimation,
  skipToRocket = false,
  setStartAnimation,
}) => {
  const dispatch = useAppDispatch();

  const currentRolePlural = UserRolePlural[UserRoleEnum[userRole!]];
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);
  const content = startUpSectionContent[userRole!];

  const [isPrepareSequence, setIsPrepareSequence] = useState<Boolean>(false);
  const [isFlySequence, setIsFlySequence] = useState<Boolean>(false);
  const [isStartupActive, setIsStartupActive] = useState<Boolean>(false);
  const [is3dSequence, setIs3dSequence] = useState<Boolean>(false);
  const [is3dSceneReady, setIs3dSceneReady] = useState<Boolean>(false);

  const navigate = useNavigate();

  const isTablet = useMediaQuery('(max-width: 1279px)');

  const handleRoleChange = () => {
    const roleKeys = Object.keys(UserRoleEnum) as Array<keyof typeof UserRoleEnum>;

    const getNextRole = (currentRole): UserRoleEnum => {
      const currentIndex = roleKeys.indexOf(currentRole);

      // Calculate the next index, wrapping around if necessary
      const nextIndex = (currentIndex + 1) % roleKeys.length;

      return UserRoleEnum[roleKeys[nextIndex]]; // Return the next role name
    };

    const nextRole = getNextRole(userRole);
    // Assuming the URL structure is /roleName
    const nextUrl = `/${nextRole.toLowerCase().replace(/\s+/g, '-')}`; // Create URL-friendly string

    dispatch(setShouldScroll(false));

    navigate(nextUrl);
  };

  const handle3dSceneReady = (flag) => {
    setIs3dSceneReady(flag);
  };

  const handleResetAnimation = () => {
    setStartAnimation(false);
    setIsPrepareSequence(false);
    setIsFlySequence(false);
    setIsStartupActive(false);
    setIs3dSequence(false);
    setIs3dSceneReady(false);
  };

  const handle3dLaunch = () => {
    if (isTablet) {
      if (navigate) {
        navigate(`/${RoutesEnum.SIGN_UP}`, { state: { from: 'startup' } });
      }
    } else {
      setIs3dSequence(true);
    }
  };

  const sectionRef = useRef<HTMLDivElement>(null);

  // ORBIT
  const [orbitDuration, setOrbitDuration] = useState(140);

  useGSAP(
    () => {
      const orbits = sectionRef.current?.querySelectorAll(`.gsap__orbit-obj`);
      const orbitPlanets = sectionRef.current?.querySelectorAll(`.gsap__orbit-planet`);

      const tl = gsap.timeline();

      if (orbits && orbitPlanets && isFlySequence === false) {
        tl.to(orbits, { rotationZ: 360, ease: 'none', repeat: -1, duration: orbitDuration }, 'one').to(
          orbitPlanets,
          { rotationZ: -360, ease: 'none', repeat: -1, duration: orbitDuration },
          'one'
        );
      }

      if (isFlySequence) {
        tl.clear(true);
      }
    },
    { dependencies: [orbitDuration, isFlySequence, isTablet], scope: sectionRef, revertOnUpdate: false }
  );

  // controls
  // const [showOrbitControls, setShowOrbitControls] = useState(false);

  // useEffect(() => {
  //   const handleKeyPress = (event: KeyboardEvent) => {
  //     if (event.key === 's' || event.key === 'S') {
  //       setShowOrbitControls((prevState) => !prevState);
  //     }
  //   };

  //   document.addEventListener('keypress', handleKeyPress);

  //   return () => {
  //     document.removeEventListener('keypress', handleKeyPress);
  //   };
  // }, []);

  // const handleInputChange = (event) => {
  //   const value = parseInt(event.target.value, 10);
  //   if (!isNaN(value) && value > 0 && value <= 300) {
  //     setOrbitDuration(value);
  //   }
  // };
  // controls end
  // ORBIT END

  // MAIN SMOKE
  const bigSmokeContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // let bigSmokeAnimationInstance: AnimationItem;
    // if (bigSmokeContainer.current) {
    //   bigSmokeAnimationInstance = Lottie.loadAnimation({
    //     container: bigSmokeContainer.current,
    //     renderer: 'svg',
    //     loop: true,
    //     autoplay: true,
    //     animationData: BigSmoke,
    //   });
    //   if (isFlySequence) {
    //     bigSmokeAnimationInstance.destroy();
    //   }
    // }
    // return () => {
    //   if (bigSmokeAnimationInstance) {
    //     bigSmokeAnimationInstance.destroy();
    //   }
    // };
  }, [bigSmokeContainer, isFlySequence]);
  // MAIN SMOKE END

  // SMOKE TRAIL
  const smokeTrailContainer = useRef<HTMLDivElement>(null);
  const smokeTrailAnimationInstanceRef = useRef<AnimationItem | null>();

  useEffect(() => {
    if (smokeTrailContainer.current) {
      // if (isPrepareSequence) {
      if (true) {
        smokeTrailAnimationInstanceRef.current = Lottie.loadAnimation({
          container: smokeTrailContainer.current,
          renderer: 'svg',
          loop: false,
          autoplay: false,
          animationData: BigSmokeForLaunch,
        });

        smokeTrailAnimationInstanceRef.current.pause();
        smokeTrailAnimationInstanceRef.current.setSpeed(0.6);
      }
    }

    if (isFlySequence) {
      smokeTrailAnimationInstanceRef.current?.stop();
    }

    return () => {
      if (smokeTrailAnimationInstanceRef.current) {
        smokeTrailAnimationInstanceRef.current.destroy();
      }
    };
  }, [smokeTrailContainer, isFlySequence, isPrepareSequence]);
  // SMOKE TRAIL END

  // DUDE
  const dudeContainer = useRef<HTMLDivElement>(null);
  const dudeAnimationInstanceRef = useRef<AnimationItem | null>();

  useEffect(() => {
    if (dudeContainer.current) {
      dudeAnimationInstanceRef.current = Lottie.loadAnimation({
        container: dudeContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData: Dude,
      });

      dudeAnimationInstanceRef.current.pause();
      // dudeAnimationInstanceRef.current.setSpeed(1.2);
      dudeAnimationInstanceRef.current.setSpeed(1.7);
    }

    if (isPrepareSequence) {
      dudeAnimationInstanceRef.current?.play();
    }

    if (isFlySequence) {
      dudeAnimationInstanceRef.current?.stop();
    }

    return () => {
      if (dudeAnimationInstanceRef.current) {
        dudeAnimationInstanceRef.current.destroy();
      }
    };
  }, [dudeContainer, isPrepareSequence, isFlySequence]);

  // DUDE END

  // FALLING STARS
  const fallingStarsCanvasRef = useRef<HTMLCanvasElement>(null);
  const fallingStarsSettingsRef = useRef({
    speedValue: 10,
  });

  useEffect(() => {
    const canvas = fallingStarsCanvasRef.current;

    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const ctx = canvas.getContext('2d');

      if (ctx && isFlySequence) {
        interface Star {
          x: number;
          y: number;
          length: number;
          color: string;
        }

        const stars: Star[] = [];
        for (let i = 0; i < 200; i++) {
          stars.push({
            x: Math.random() * window.innerWidth, // Adjust as needed (canvas width)
            y: Math.random() * -window.innerHeight + 1000, // Start stars above the canvas
            length: Math.random() * 100,
            color: `#ffffff`, // Adjust as needed
          });
        }

        const drawStar = (x: number, y: number, length: number, color: string) => {
          ctx.beginPath();
          ctx.strokeStyle = color;
          ctx.moveTo(x, y);
          ctx.lineTo(x, y + length);
          ctx.stroke();
        };

        // Animation function to draw and move stars
        const animate = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas for each frame

          // Update star positions and handle off-screen reset
          for (let i = 0; i < stars.length; i++) {
            stars[i].y += fallingStarsSettingsRef.current.speedValue; // Adjust speedValue for desired speed

            if (stars[i].y > canvas.height) {
              stars[i].y = -Math.random() * canvas.height - 100; // Reset y when star goes off-screen
              stars[i].x = Math.random() * window.innerWidth;
            }
          }

          // Draw stars using the existing stars array
          for (const star of stars) {
            drawStar(star.x, star.y, star.length, star.color);
          }

          requestAnimationFrame(animate);
        };

        // Define and adjust speed value (higher value = faster stars)

        animate();
      }
    }
  }, [isFlySequence]);
  // FALLING STARS END

  // LAUNCH
  const testTlRef = useRef(gsap.timeline());
  const startUpCloseButtonRef = useRef() as any;
  const startUpBackButtonRef = useRef() as any;

  useGSAP(
    (context, contextSafe) => {
      // STARTUP SCREEN IDLE ANIM
      if (false) {
        const startupBgTl = gsap.timeline();
        const startupBgCometOneTl = gsap.timeline({ repeat: -1 });
        const startupBgCometTwoTl = gsap.timeline({ repeat: -1 });
        const startupBgCometThreeTl = gsap.timeline({ repeat: -1 });

        // startupBgTl.pause();
        // startupBgCometOneTl.pause();
        // startupBgCometTwoTl.pause();
        // startupBgCometThreeTl.pause();

        startupBgTl.to('.gsap__startup-bg svg g[data-name="startup_nebula"] circle', {
          scale: '+=0.05',
          opacity: '-=0.2',
          duration: 3,
          repeat: -1,
          yoyo: true,
          stagger: -0.05,
          ease: 'power1.inOut',
          transformOrigin: 'center',
        });

        startupBgCometOneTl
          .set('.gsap__startup-bg svg g[data-name="startup_comet_2"]', { opacity: 0 })
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_2"]',
            { x: 300, y: -100 },
            {
              delay: 3,
              x: 0,
              y: 0,
              duration: 2,
              ease: 'power2.inOut',
            },
            'one'
          )
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_2"]',
            { opacity: 0 },
            {
              delay: 3,
              opacity: 1,
              duration: 1,
              ease: 'power1.inOut',
            },
            'one'
          )
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_2"]',
            { opacity: 1 },
            {
              delay: 4,
              opacity: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            'one'
          );

        startupBgCometTwoTl
          .set('.gsap__startup-bg svg g[data-name="startup_comet_1"]', { opacity: 0 })
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_1"]',
            { x: 300, y: -100 },
            {
              delay: 2,
              x: 0,
              y: 0,
              duration: 2,
              ease: 'power2.inOut',
            },
            'one'
          )
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_1"]',
            { opacity: 0 },
            {
              delay: 2,
              opacity: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            'one'
          )
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_1"]',
            { opacity: 1 },
            {
              delay: 3,
              opacity: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            'one'
          );

        startupBgCometThreeTl
          .set('.gsap__startup-bg svg g[data-name="startup_comet_3"]', { opacity: 0 })
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_3"]',
            { x: 300, y: -100 },
            {
              delay: 4,
              x: 0,
              y: 0,
              duration: 2,
              ease: 'power2.inOut',
            },
            'one'
          )
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_3"]',
            { opacity: 0 },
            {
              delay: 4,
              opacity: 0.6,
              duration: 1,
              ease: 'power1.inOut',
            },
            'one'
          )
          .fromTo(
            '.gsap__startup-bg svg g[data-name="startup_comet_3"]',
            { opacity: 0.6 },
            {
              delay: 5,
              opacity: 0,
              duration: 1,
              ease: 'power1.inOut',
            },
            'one'
          );
      }
      // STARTUP SCREEN IDLE ANIM END

      // MAIN ANIM

      // const tl = gsap.timeline();
      const rocketShakeTl = gsap.timeline();

      const header = document.querySelector('.global__header') as HTMLElement;
      const headerBar = document.querySelector('.global__header-bar') as HTMLElement;
      const viewChildren = document.querySelector('.global__children') as HTMLElement;

      // ROCKET SHAKE
      if (true) {
        if (skipToRocket) {
          rocketShakeTl.seek('three');
        }

        rocketShakeTl
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=1',
              duration: 0.1,
              repeat: 10,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'one'
          )
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=2',
              duration: 0.1,
              repeat: 5,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'two'
          )
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=2',
              duration: 0.08,
              repeat: 20,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'three'
          )
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=3',
              duration: 0.07,
              repeat: 15,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'four'
          )
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=2',
              duration: 0.1,
              repeat: 5,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'five'
          )
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=1',
              duration: 0.1,
              repeat: 5,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'six'
          )
          .to(
            '.gsap__rocket-shake',
            {
              y: '+=10',
              duration: 1,
              repeat: -1,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'seven'
          );
      }
      // ROCKET SHAKE END

      if (true) {
        const launchEase = 'power2.in' as gsap.EaseString;
        const launchDuration = 1.8;
        const flyDelay = 2;
        const arriveDelay = flyDelay + 1.6;

        if (skipToRocket) {
          testTlRef.current.seek('four');
        }

        testTlRef.current
          .to(
            '.gsap__circles-outer',
            {
              opacity: 0,
              duration: 0.6,
              ease: 'power1.out',
              onStart: () => {
                setIsPrepareSequence(true);
              },
              onReverseComplete: () => {
                handleResetAnimation();
              },
            },
            'one'
          )
          .to('.gsap__label-3', { delay: 0.1, scale: 1.4, x: -5, y: -80, ease: 'back.out', duration: 0.6 }, 'one')
          .to('.gsap__content-1', { opacity: 0.33, ease: 'power1.inOut', duration: 0.33 }, 'one')

          // .to('.gsap__ill', { y: -500, ease: 'power1.inOut', duration: 0.5 })

          .to(
            '.gsap__label-3',
            {
              delay: 0,
              y: -60,
              opacity: 0,
              duration: 0.4,
            },
            'two'
          )
          .to(
            '.gsap__label-2',
            {
              delay: 0.1,
              scale: 1.4,
              x: 30,
              y: -100,
              ease: 'back.out',
              duration: 0.6,
              onStart: () => {
                if (!testTlRef.current.reversed()) {
                  rocketShakeTl.play();
                } else {
                  rocketShakeTl.pause();
                  rocketShakeTl.progress(0);
                }
              },
            },
            'two'
          )
          .to('.gsap__label-2', { delay: 0.9, y: -80, opacity: 0, duration: 0.4 }, 'two')
          .to('.gsap__label-1', { delay: 0.9, scale: 1.4, x: 20, y: -70, ease: 'back.out', duration: 0.65 }, 'two')

          .set('.gsap__wrapper-container', { zIndex: 111 }, 'four')
          .to('.gsap__label-1', { delay: 0.2, y: -45, opacity: 0, duration: 0.4 }, 'four')
          .to(
            '.gsap__moon, .gsap__orbit, .gsap__astronaut, .gsap__planet, .gsap__content-in-smoke, .gsap__rocket-smoke',
            {
              y: 1300,
              ease: launchEase,
              duration: launchDuration,
              onStart: () => smokeTrailAnimationInstanceRef.current?.play(),
              onComplete: () => setIsFlySequence(true),
            },
            'four'
          )
          .to('.gsap__rocket-smoke-for-launch', { opacity: 1, ease: 'none', duration: 0.5 }, 'four')
          .to('.gsap__content-1', { y: 1600, display: 'none', ease: launchEase, duration: launchDuration }, 'four')
          // .to(
          //   '.gsap__content-1',
          //   { y: 1600, pointerEvents: 'none', position: 'absolute', ease: launchEase, duration: launchDuration },
          //   'four'
          // )
          .to(viewChildren, { y: 1300, display: 'none', ease: launchEase, duration: launchDuration }, 'four')
          .to(header, { y: 1300, ease: launchEase, duration: launchDuration }, 'four')
          .to(headerBar, { y: 1300, ease: launchEase, duration: launchDuration }, 'four')
          .to(header, { opacity: 0, duration: 0.2, delay: launchDuration - 0.2 }, 'four')
          .to(headerBar, { opacity: 0, duration: 0.2, delay: launchDuration - 0.2 }, 'four')
          .to('.gsap__rocket-size', { scale: 1.8, ease: launchEase, duration: launchDuration }, 'four')
          .to('.gsap__bg-1', { opacity: 0.5, ease: launchEase, duration: launchDuration }, 'four')
          .to('.gsap__falling-stars', { delay: 0.8, opacity: 0.8, ease: launchEase, duration: launchDuration }, 'four')
          .to(
            fallingStarsSettingsRef.current,
            { speedValue: 40, delay: 0.8, ease: 'power2.inOut', duration: launchDuration },
            'four'
          )
          .to('.gsap__fire-1', { delay: 0.2, opacity: 0.8, ease: launchEase, duration: 0.5 }, 'four')
          .to('.gsap__fire-2', { delay: 0.5, opacity: 0.8, ease: launchEase, duration: 0.5 }, 'four')
          .to('.gsap__fire-3', { delay: 0.6, opacity: 0.8, ease: launchEase, duration: 0.5 }, 'four')
          .from(
            '.gsap__fire-1, .gsap__fire-2, .gsap__fire-3',
            {
              delay: 0.6,
              height: 0,
              ease: 'power2.inOut',
              duration: 1,
            },
            'four'
          )
          .to(
            '.gsap__fire-1, .gsap__fire-2, .gsap__fire-3',
            {
              delay: 0.6,
              scale: '+=0.1',
              duration: 0.07,
              repeat: 35,
              yoyo: true,
              ease: 'power1.inOut',
            },
            'four'
          )

          .to('.gsap__bg-1', { delay: flyDelay + 0.6, opacity: 0, ease: 'power2.in', duration: 1 }, 'four')
          .to('.gsap__falling-stars', { delay: flyDelay + 1, opacity: 0, ease: 'power2.in', duration: 1 }, 'four')
          .to(
            fallingStarsSettingsRef.current,
            { delay: flyDelay + 1, speedValue: 20, ease: 'power2.inOut', duration: 1 },
            'four'
          )
          .to(
            '.gsap__rocket #left_rocket',
            { delay: flyDelay, x: -40, y: 600, rotateZ: -15, ease: 'power2.inOut', duration: 2.5 },
            'four'
          )
          .to('.gsap__fire-2', { delay: flyDelay, opacity: 0, ease: 'power2.in', duration: 0.5 }, 'four')
          .to(
            '.gsap__rocket #right_rocket',
            { delay: flyDelay + 0.5, x: 40, y: 600, rotateZ: 15, ease: 'power2.inOut', duration: 2.5 },
            'four'
          )
          .to('.gsap__fire-3', { delay: flyDelay + 0.5, opacity: 0, ease: 'power2.in', duration: 0.5 }, 'four')
          .to(
            '.gsap__rocket #main_rocket',
            {
              delay: flyDelay + 0.9,
              x: 40,
              y: 700,
              rotateZ: 15,
              ease: 'power2.inOut',
              duration: 2.5,
            },
            'four'
          )
          .to(
            '.gsap__fire-1',
            {
              delay: flyDelay + 0.8,
              opacity: 0,
              ease: 'power2.in',
              duration: 0.5,
            },
            'four'
          )

          .fromTo(
            '.gsap__s-bg',
            {
              opacity: 0,
              y: -30,
              display: 'none',
            },
            {
              delay: arriveDelay,
              y: 0,
              display: 'block',
              opacity: 1,
              ease: 'power1.inOut',
              duration: 2,
              onStart: () => {
                setIsStartupActive(true);
              },
            },
            'four'
          )
          .fromTo(
            '.gsap__content-2',
            {
              opacity: 0,
              display: 'none',
            },
            {
              delay: arriveDelay,
              display: 'block',
              opacity: 1,
              ease: 'power2.in',
              duration: 1,
            },
            'four'
          )
          .fromTo(
            '.gsap__logo, .gsap__close',
            {
              opacity: 0,
              display: 'none',
            },
            { delay: arriveDelay, display: 'block', opacity: 1, ease: 'power2.in', duration: 1 },
            'four'
          )
          .fromTo(
            '.gsap__s-text-1, .gsap__s-text-2, .gsap__s-text-3, .gsap__s-buttons',
            {
              opacity: 0,
              y: 20,
            },
            { delay: arriveDelay + 0.5, opacity: 1, y: 0, ease: 'power3.out', duration: 1, stagger: 0.15 },
            'four'
          )
          .from(
            '.gsap__s-text-1',
            {
              delay: arriveDelay + 1.5,
              color: '#ffffff',
              onReverseComplete: () => {
                gsap.to('.gsap__rocket-shake', {
                  duration: 1,
                  y: 0,
                  ease: 'none',
                });
              },
            },
            'four'
          );

        if (isTablet) {
          // testTlRef.current.to('.gsap__rocket', { opacity: 0, duration: 0.5 });
        }
      }

      testTlRef.current.pause();
      rocketShakeTl.pause();
      // MAIN ANIM END

      if (startAnimation) {
        testTlRef.current.play();
        testTlRef.current.timeScale(1.05);
        rocketShakeTl.timeScale(1.05);

        if (isTablet) testTlRef.current.timeScale(2);
        if (isTablet) testTlRef.current.seek('four');

        console.log('forwardOnState');
      }

      const onClose = contextSafe!(() => {
        testTlRef.current.timeScale(8);
        testTlRef.current.reverse();

        console.log('reversing');
      });

      startUpCloseButtonRef.current.addEventListener('click', onClose);
      startUpBackButtonRef.current.addEventListener('click', onClose);

      return () => {
        startUpCloseButtonRef.current.removeEventListener('click', onClose);
        startUpBackButtonRef.current.removeEventListener('click', onClose);
      };
    },
    { dependencies: [startAnimation, isTablet], scope: sectionRef, revertOnUpdate: true }
  );
  // LAUNCH END

  useEffect(() => {
    // setStartAnimation(true);
  }, []);

  useGSAP(
    () => {
      if (is3dSequence && is3dSceneReady) {
        const launch3dTl = gsap.timeline();

        launch3dTl
          .to(
            '.gsap__rocket',
            {
              delay: 0.5,
              opacity: 0,
              ease: 'none',
              duration: 0.5,
            },
            'one'
          )
          .to('.gsap__rocket-shake', { y: 0, duration: 1 }, 'one')
          .fromTo(
            '.gsap__3d-scene',
            {
              opacity: 0,
            },
            { delay: 0.5, opacity: 1, ease: 'none', duration: 0.5 },
            'one'
          )
          .to(
            '.gsap__rocket',
            {
              y: -100,
              ease: 'back.in',
              duration: 1,
            },
            'one'
          )
          // .to(
          //   '.gsap__rocket',
          //   {
          //     delay: 1,
          //     y: -500,
          //     ease: 'none',
          //     duration: 1,
          //   },
          //   'one'
          // )
          .to(
            '.gsap__content-2',
            { delay: 0.3, y: 1600, opacity: 0, display: 'none', ease: 'power2.in', duration: 1.4 },
            'one'
          )
          .to('.gsap__s-bg', { opacity: 0, ease: 'power2.in', duration: 1 }, 'one')
          .to('.gsap__close', { opacity: 0, ease: 'power2.in', duration: 1 }, 'one');
      }
    },
    { dependencies: [is3dSequence, is3dSceneReady], scope: sectionRef }
  );

  return (
    <section
      className={classNames(
        styles['section'],
        styles['var-0'],
        skipToRocket && styles['section--skip-to-rocket'],
        className
      )}
      ref={sectionRef}
    >
      <div className="background">
        <div className={styles['background__inner']}>
          <div className={classNames(styles['s__bg-outer'], 'gsap__s-bg')}>
            <div className={classNames(styles['s__bg'], 'gsap__startup-bg')}>
              {/* <StartUpBg /> */}
              {isStartupActive && <img src={StartUpBg} alt="StartUp section bg" />}
            </div>
          </div>
        </div>

        <div className={classNames(styles['s__3d-scene-outer'], 'gsap__3d-scene')}>
          <div className={classNames(styles['s__3d-scene-shake'], 'gsap__3d-scene-shake')}>
            {is3dSequence && (
              <div className={classNames(styles['s__3d-scene'])}>
                <ThreeSceneFiber
                  launch3dScene={is3dSequence}
                  handle3dSceneReady={handle3dSceneReady}
                  is3dSceneReady={is3dSceneReady}
                />
              </div>
            )}
          </div>
        </div>

        <div className={classNames(styles['bg-1'], 'gsap__bg-1')}></div>

        <canvas
          className={classNames(styles['canvas'], 'gsap__falling-stars')}
          id="canvas"
          ref={fallingStarsCanvasRef}
        ></canvas>
      </div>

      {/* {showOrbitControls && (
        <div className={styles['anim-controls']}>
          <label>Orbit duration:</label>
          <input type="range" min="1" max="300" value={orbitDuration} onChange={handleInputChange} />
          <input type="number" min="1" max="300" value={orbitDuration} onChange={handleInputChange} />
        </div>
      )} */}

      <div className={classNames(styles['wrapper-container'], 'gsap__wrapper-container')}>
        <div className={classNames(styles['s__content'])}>
          <div className={classNames(styles['s__wrapper'], 'wrapper type-1')}>
            <div className={styles['s__top-block-list']}>
              <div className={styles['s__top-block-list__item']} style={{ visibility: 'hidden' }}>
                <OnlyIconButton visualType="blank" icon={<CrossIcon />} />
              </div>

              <div className={classNames(styles['s__top-block-list__item'], 'gsap__logo')}>
                <div className={styles['s__logo']}>
                  <AstronomicLogo />
                </div>
              </div>

              <div className={classNames(styles['s__top-block-list__item'], 'gsap__close')} ref={startUpCloseButtonRef}>
                <OnlyIconButton visualType="blank" icon={<CrossIcon />} />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles['main-wrapper'], 'wrapper type-1')}>
          <div className={styles['content']}>
            <div className={styles['first-part']}>
              <div className={styles['block-list']}>
                <div className={styles['block-list__item']}>
                  <div className={classNames(styles['content-1'], 'gsap__content-1')}>
                    <div className={styles['title']}>
                      <h1 className="text text--h1">
                        {title}{' '}
                        <span className={styles['title__mark']} onClick={handleRoleChange}>
                          {currentRolePlural}
                          {/* <div className={styles['title__mark__line']}></div> */}
                        </span>
                      </h1>
                    </div>

                    <div className={styles['text']}>
                      <p className="text text--body-1">{content?.mainHeroDescription}</p>
                    </div>

                    {buttonGroup && (
                      <div className={styles['button-group']}>
                        {buttonGroup.map((item, i) => (
                          <div className={styles['button-group__item']} key={i}>
                            {item}
                          </div>
                        ))}
                      </div>
                    )}

                    {isUserLoggedIn === false && <TextWithIconForPopUp className={styles['text-for-popup']} />}
                  </div>

                  <div className={classNames(styles['content-2'], 'gsap__content-2')}>
                    <p className={classNames(styles['s__sec-title'], 'text text--h3', 'gsap__s-text-1')}>
                      {content?.aboveLaunchScreenLabel}
                    </p>

                    <p className={classNames(styles['s__main-title'], 'text text--h1', 'gsap__s-text-2')}>
                      {content?.aboveLaunchScreenHeadline} 🚀️
                    </p>

                    <p
                      className={classNames(
                        styles['s__text'],
                        'text text--body-2 text--body-2-var-a',
                        'gsap__s-text-3'
                      )}
                    >
                      {content?.aboveLaunchScreenParagraph}
                    </p>

                    <div
                      className={classNames(styles['button-group'], styles['button-group--var-1'], 'gsap__s-buttons')}
                    >
                      <div className={styles['button-group__item']}>
                        {isUserLoggedIn === false ? (
                          <MainButton sizeType={'large'} visualType="main" onClick={handle3dLaunch}>
                            Launch
                          </MainButton>
                        ) : (
                          <MainButton
                            sizeType={'large'}
                            visualType="main"
                            onClick={() => navigate(`/${userRole}/${RoutesEnum.SCHEDULE_MEETING}`)}
                          >
                            Schedule a meeting
                          </MainButton>
                        )}
                      </div>

                      <div className={styles['button-group__item']}>
                        <div ref={startUpBackButtonRef}>
                          <MainButton sizeType={'large'} visualType="blank">
                            Back
                          </MainButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles['block-list__item']}>
                  <div className={classNames(styles['illustration'], 'gsap__ill')}>
                    <div className={classNames(styles['moon'], 'gsap__moon')}>
                      <Moon />
                    </div>

                    <div className={classNames(styles['orbit'], 'gsap__orbit')}>
                      <div className={styles['orbit__obj-container-one']}>
                        <div className={classNames(styles['orbit__one'], 'gsap__orbit-obj')}>
                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--1'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Services</div>
                          </div>

                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--2'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Funding</div>
                          </div>

                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--3'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Talent</div>
                          </div>

                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--4'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Tools</div>
                          </div>

                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--5'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Community</div>
                          </div>
                        </div>
                      </div>

                      <div className={styles['orbit__obj-container-two']}>
                        <div className={classNames(styles['orbit__two'], 'gsap__orbit-obj')}>
                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--6'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Data</div>
                          </div>

                          <div
                            className={classNames(
                              styles['orbit__planet'],
                              styles['orbit__planet--7'],
                              'gsap__orbit-planet'
                            )}
                          >
                            <div className={classNames(styles['orbit__planet__dot'])}></div>

                            <div className={classNames(styles['orbit__planet__text'])}>Education</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={classNames(styles['astronaut'], 'gsap__astronaut')} ref={dudeContainer}>
                      {/* <Astronaut /> */}
                    </div>

                    <div className={classNames(styles['planet'], 'gsap__planet')}>
                      <Planet />
                    </div>

                    <div className={styles['rocket']}>
                      <div className={styles['rocket__obj']}>
                        <div className={styles['rocket__obj__inner']}>
                          <div className="gsap__rocket-shake">
                            <div className="gsap__rocket-size">
                              <Rocket className="gsap__rocket" />

                              <div className={classNames(styles['rocket__fire-1'], 'gsap__fire-1')}></div>
                              <div className={classNames(styles['rocket__fire-2'], 'gsap__fire-2')}></div>
                              <div className={classNames(styles['rocket__fire-3'], 'gsap__fire-3')}></div>
                              <div className={classNames(styles['rocket__fire-4'], 'gsap__fire-4')}></div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={classNames(styles['rocket__smoke'], 'gsap__rocket-smoke')}
                          // ref={bigSmokeContainer}
                        >
                          <Smoke />
                        </div>

                        <div
                          className={classNames(
                            styles['rocket__smoke'],
                            styles['rocket__smoke--for-launch'],
                            'gsap__rocket-smoke',
                            'gsap__rocket-smoke-for-launch'
                          )}
                          ref={smokeTrailContainer}
                        >
                          {/* <Smoke /> */}
                        </div>
                      </div>
                    </div>

                    <div className={styles['label-group']}>
                      <div className={classNames(styles['label'], styles['label--1'], 'gsap__label-1')}>
                        {content?.moonLabel}
                      </div>
                      <div className={classNames(styles['label'], styles['label--2'], 'gsap__label-2')}>
                        {content?.rocketLabel}
                      </div>
                      <div className={classNames(styles['label'], styles['label--3'], 'gsap__label-3')}>You</div>
                    </div>

                    <div className={classNames(styles['content-in-smoke'], 'gsap__content-in-smoke')}>
                      <button
                        className={classNames(styles['content-in-smoke__button'], 'gsap__launch-button')}
                        onClick={() => {
                          setStartAnimation(true);
                        }}
                      >
                        <div className={styles['content-in-smoke__button__arrow']}>
                          <div className={styles['content-in-smoke__button__arrow__vertical']}></div>
                          <div className={styles['content-in-smoke__button__arrow__diag-1']}></div>
                          <div className={styles['content-in-smoke__button__arrow__diag-2']}></div>
                        </div>

                        <div
                          className={classNames(
                            styles['content-in-smoke__button__circles-outer'],
                            'gsap__circles-outer'
                          )}
                        >
                          <div className={classNames(styles['content-in-smoke__button__circle-1'])}></div>

                          <div className={classNames(styles['content-in-smoke__button__circle-2'])}></div>
                        </div>
                      </button>

                      <p className={classNames(styles['content-in-smoke__text-1'], 'text text--caption text--bold')}>
                        Launch
                      </p>

                      <p className={styles['content-in-smoke__text-2']}>{content?.belowLaunchButtonTitle}</p>

                      <p className={classNames(styles['content-in-smoke__text-3'], 'text text--caption')}>
                        {content?.belowLaunchButtonDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartupSection;
