import { FunctionComponent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./TitleWithIcon.module.scss";

interface TitleWithIconProps extends PropsWithChildren {
  icon: React.ReactElement;
  className?: string;
}

const TitleWithIcon: FunctionComponent<TitleWithIconProps> = ({
  icon,
  children,
  className,
}) => {
  return (
    <div className={classNames(styles["title-container"], className)}>
      <div className={styles["title-container__icon"]}>{icon}</div>
      <div className={styles["title-container__text"]}>{children}</div>
    </div>
  );
};

export default TitleWithIcon;
