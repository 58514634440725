import { FunctionComponent, useEffect, useState } from 'react';
import CreatePasswordTemplate from '../../containers/templates/CreatePasswordTemplate/CreatePasswordTemplate';
import { ApiResponseDTO } from '../../dto/api';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { localStorageGetItem, localStorageRemoveItem, localStorageSetItem } from '../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../enums/localStorageKeys';
import RoutesEnum from '../../enums/routes';

interface ForgotPasswordNewPasswordViewProps {}

const ForgotPasswordNewPasswordView: FunctionComponent<ForgotPasswordNewPasswordViewProps> = () => {
  const navigate = useNavigate();
  const [userEmailFromLocalStorage, setUserEmailFromLocalStorage] = useState<string | null>();
  const [token, setToken] = useState<string | null>();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get('token');
    setToken(token);
    setUserEmailFromLocalStorage(localStorageGetItem(LOCAL_STORAGE_KEYS.USER_EMAIL));

    if (!token) {
      navigate(`/${RoutesEnum.SIGN_UP}`);
    }
  }, []);

  const handleOnSubmitApiRequest = async (password: string, email?: string) => {
    let userEmail = localStorageGetItem(LOCAL_STORAGE_KEYS.USER_EMAIL);

    if (email) {
      userEmail = email;
    }

    try {
      const response = await api.Auth.resetPassword({
        email: userEmail!,
        newPassword: password,
        resetPasswordToken: token!,
      });

      const responseData = response.data as ApiResponseDTO;

      if (responseData.success === true && responseData.data) {
        navigate(`/${RoutesEnum.PASSWORD_CHANGED}`);
        localStorageRemoveItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
        localStorageSetItem(LOCAL_STORAGE_KEYS.USER_FORGOT_PASSWORD_STEP, '2');
        return { success: true, message: 'All good. Proceeding to the next step...' };
      } else {
        console.error('Error:', response);
        return { success: false, message: 'An error occurred.' };
      }
    } catch (error) {
      const errorObj = error as AxiosError<ApiResponseDTO>;
      const errorData = errorObj.response?.data;

      if (errorData?.errorMessage) {
        console.error('Error: ', errorData.errorMessage);
        return { success: false, message: errorData.errorMessage };
      }

      console.error('Error: ', error);
      return { success: false, message: 'An error occurred.' };
    }
  };

  return (
    <CreatePasswordTemplate
      title="Choose a new password"
      emailFieldActive={userEmailFromLocalStorage ? false : true}
      onSubmitApiRequest={handleOnSubmitApiRequest}
    />
  );
};

export default ForgotPasswordNewPasswordView;
