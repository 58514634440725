import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define the type for scroll state
interface ScrollState {
  shouldScroll: boolean; // Track whether scrolling to the top is allowed
}

// Initial state of scroll behavior (default: scroll to top)
const initialState: ScrollState = {
  shouldScroll: true,
};

const scrollSlice = createSlice({
  name: 'scroll',
  initialState: initialState,
  reducers: {
    setShouldScroll: (state, action: PayloadAction<boolean>) => {
      // Action to set whether to scroll or not
      state.shouldScroll = action.payload;
    },
  },
});

export default scrollSlice.reducer;

export const { setShouldScroll } = scrollSlice.actions;
