export enum GENERAL_CONTENT {
  JOIN_ASTRONOMIC_FOR_FREE = 'Join Astronomic for free',
  JOIN_ASTRONOMIC = 'Join Astronomic',
  JOIN_FOR_FREE = 'Join for free',
  SCHEDULE_A_MEETING = 'Schedule a meeting',
  WHAT_IS_ASTRONOMIC = 'What is Astronomic',
  HOW_ASTRONOMIC_WORKS = 'How Astronomic works',
  SEE_DETAILS = 'See details',
  BUILD_STAGES = 'Build stages',
  VIEW_MORE = 'View more',
}
