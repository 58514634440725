import { FunctionComponent, useEffect, useState } from 'react';
import CompleteProfileTemplate from '../../containers/templates/CompleteProfileTemplate/CompleteProfileTemplate';
import { localStorageGetItem } from '../../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../../enums/localStorageKeys';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../dto/api';
import RoutesEnum from '../../enums/routes';

interface CompleteProfileViewProps {}

const CompleteProfileView: FunctionComponent<CompleteProfileViewProps> = () => {
  const navigate = useNavigate();

  const [formSchema, setFormSchema] = useState();

  // GET STEPS
  useEffect(() => {
    const getOnboardingSteps = async () => {
      if (localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID)) {
        try {
          const response = await api.Onboarding.getSteps(localStorageGetItem(LOCAL_STORAGE_KEYS.USER_ID) as string);
          const responseData = response.data;

          if (responseData.success === true && responseData.data) {
            setFormSchema(responseData.data);
          } else {
            console.error('Error:', responseData.errors);
            navigate(`/${RoutesEnum.WELCOME}`);
          }
        } catch (error) {
          const errorObj = error as AxiosError<ApiResponseDTO>;
          const errorData = errorObj.response?.data;
          if (errorData?.errorMessage) {
            console.error(errorData.errorMessage);
          }
          console.error('Error: ', error);
          navigate(`/${RoutesEnum.WELCOME}`);
        }
      } else {
        navigate(`/${RoutesEnum.WELCOME}`);
      }
    };

    getOnboardingSteps();
  }, []);
  // GET STEPS END

  return <CompleteProfileTemplate formSchemaProp={formSchema} />;
};

export default CompleteProfileView;
