import { IConfiguration } from "../types/configuration";
import { get_backend, get_env, get_stripe_public_key } from "./functions/app_functions";

const configuration: IConfiguration = {
  APP: {
    ENV: get_env(),
    BACKEND: get_backend(),
    STRIPE_PUBLIC_KEY: get_stripe_public_key(),
  },
};

export default configuration;
