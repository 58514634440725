import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { UserRoleEnum } from '../../../../src/enums/user';
import { ReactComponent as HeroIll } from '../../../asset/images/hero_ill.svg';
import styles from './HeroSection.module.scss';
import { UserRolePlural } from '../../../data/role';
import BackgroundSectionTemplate, {
  BackgroundSectionTemplateColorType,
} from '../sectionTemplates/BackgroundSectionTemplate/BackgroundSectionTemplate';

interface HeroSectionProps {
  sectionVariation: 'var-0' | 'var-1' | 'var-1-a' | 'var-1-b' | 'var-1-c' | 'var-1-c-2' | 'var-1-c-3';
  sectionBackgroundColor?: BackgroundSectionTemplateColorType;
  className?: string;
  title: string | React.ReactElement;
  titleSize?: 'h1' | 'h2';
  secondaryTitle?: string | React.ReactElement;
  text: string | React.ReactElement;
  elementAfterText?: React.ReactElement;
  buttonGroup?: React.ReactElement[] | null;
  illustration?: React.ReactElement;
  illustrationPosition?: 'default' | 'var-a';
  customElement?: React.ReactElement;
  imageUrl?: string;
  elementAfterIll?: React.ReactElement;
  breadcrumbs?: React.ReactElement;
  wrapperClass?: 'type-1' | null;
  markTagColor?: 'main' | 'blue';
  userRole?: UserRoleEnum;
}
// c
const HeroSection: FunctionComponent<HeroSectionProps> = ({
  sectionVariation = 'var-0',
  sectionBackgroundColor,
  className,
  title,
  titleSize = 'h1',
  secondaryTitle,
  text,
  elementAfterText,
  buttonGroup,
  illustration,
  illustrationPosition = 'default',
  customElement,
  imageUrl,
  elementAfterIll,
  breadcrumbs,
  wrapperClass = 'type-1',
  markTagColor = 'main',
  userRole,
}) => {
  const currentRole = UserRolePlural[UserRoleEnum[userRole!]];

  return (
    <section
      className={classNames(
        styles['section'],
        styles[sectionVariation],
        styles[`section--mark-tag-color-${markTagColor}`],
        className
      )}
    >
      {sectionBackgroundColor ? (
        <BackgroundSectionTemplate color={sectionBackgroundColor}></BackgroundSectionTemplate>
      ) : (
        <div className="background">
          {!illustration && !customElement && !imageUrl && (
            <div className={styles['bg-el']}>
              <HeroIll />
            </div>
          )}
        </div>
      )}

      <div className={classNames('wrapper', wrapperClass)}>
        <div className="content">
          <div className={styles['block-list']}>
            <div className={styles['block-list__item']}>
              {breadcrumbs && <div className={styles['breadcrumbs']}>{breadcrumbs}</div>}

              {secondaryTitle && (
                <div className={styles['sec-title']}>
                  <p className="text text--caps">{secondaryTitle}</p>
                </div>
              )}

              <div className={styles['title']}>
                {titleSize === 'h1' && (
                  <h1 className="text text--h1">
                    {title}{' '}
                    <span className={styles['title__mark']}>
                      {currentRole}
                      <div className={styles['title__mark__line']}></div>
                    </span>
                  </h1>
                )}

                {titleSize === 'h2' && (
                  <h2 className="text text--h2">
                    {title}{' '}
                    <span className={styles['title__mark']}>
                      {currentRole}
                      <div className={styles['title__mark__line']}></div>
                    </span>
                  </h2>
                )}
              </div>

              <div className={styles['text']}>
                <p className="text text--body-1">{text}</p>
              </div>

              {elementAfterText && <div className={styles['element-after-text']}>{elementAfterText}</div>}

              {buttonGroup && (
                <div className={styles['button-group']}>
                  {buttonGroup.map((item, i) => (
                    <div className={styles['button-group__item']} key={i}>
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={styles['block-list__item']}>
              {illustration && (
                <div
                  className={classNames(
                    styles['illustration'],
                    styles[`illustration--position-${illustrationPosition}`]
                  )}
                >
                  {illustration}
                </div>
              )}

              {customElement && <div className={styles['custom-element']}>{customElement}</div>}

              {imageUrl && (
                <div className={styles['image-element']}>
                  <img src={imageUrl} alt="" />
                </div>
              )}

              {elementAfterIll && elementAfterIll}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
