import { FunctionComponent } from 'react';
import styles from './HeaderBar.module.scss';
import classNames from 'classnames';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import { ReactComponent as ArrowButton } from '../../../../../asset/images/arrow_down.svg';
import { useNavigate } from 'react-router-dom';
import { startUpSectionContent } from '../../../../../data/home';
import { UserRoleEnum } from '../../../../../enums/user';

interface HeaderBarProps {
  userRole?: UserRoleEnum;
  setStartAnimation(state: Boolean): void;
}

const HeaderBar: FunctionComponent<HeaderBarProps> = ({ userRole, setStartAnimation }) => {
  const content = startUpSectionContent[userRole!];

  return (
    <section className={classNames(styles['section'], 'global__header-bar')}>
      <div className="background"></div>

      <div className="wrapper type-1">
        <div className={styles['block-list']}>
          <div className={classNames(styles['block-list__item'], styles['block-list__item-1'])}>
            <p className={classNames(styles['text-1'], 'text text--body-2 text--bold')}>{content?.blueBannerText}</p>

            <p className={classNames(styles['text-2'], 'text text--caption')}>{content?.whiteBannerText}</p>
          </div>

          <div className={classNames(styles['block-list__item'], styles['block-list__item-2'])}>
            <MainButton
              sizeType="small"
              visualType="blue"
              iconRight={
                <ArrowButton style={{ fill: 'var(--color-cyan)', width: '12px', transform: 'rotateZ(180deg)' }} />
              }
              onClick={() => {
                setStartAnimation(true);
              }}
            >
              Launch
            </MainButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderBar;
