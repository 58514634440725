import { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './CalendlyWidget.module.scss';

interface CalendlyWidgetProps {
  dataUrl: string;
}

const CalendlyWidget: FunctionComponent<CalendlyWidgetProps> = ({ dataUrl }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted (optional)
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles['container']}>
      <div
        className={classNames(styles['widget'], 'calendly-inline-widget')}
        data-url={dataUrl}
        style={{ minWidth: '320px', height: '630px' }}
      ></div>
    </div>
  );
};

export default CalendlyWidget;
