import { FunctionComponent, PropsWithChildren, useState } from "react";
import styles from "./AccordionItem.module.scss";
import { ReactComponent as Chevron } from "../../../asset/images/chevron.svg";
import classNames from "classnames";

interface AccordionItemProps extends PropsWithChildren {
  title: string;
}

const AccordionItem: FunctionComponent<AccordionItemProps> = ({
  title,
  children,
}) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className={classNames(
        styles["accordion-item"],
        active === true && styles["accordion-item--active"]
      )}
    >
      <div
        className={styles["accordion-item__header"]}
        onClick={() => {
          setActive(!active);
        }}
      >
        <div className={styles["accordion-item__header__title"]}>
          <p className="text text--body-1 text--bold">{title}</p>
        </div>

        <div className={styles["accordion-item__header__chevron"]}>
          <Chevron
            style={{
              fill: "var(--color-black)",
              width: "14px",
              minWidth: "14px",
            }}
          />
        </div>
      </div>

      <div className={styles["accordion-item__footer"]}>
        <div className={styles["accordion-item__footer__content"]}>
          <p className="text text--body-2">{children}</p>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
