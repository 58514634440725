import { FunctionComponent } from "react";
import styles from "./OnlyIconButton.module.scss";
import classNames from "classnames";

export type VisualType = "main" | "white" | "blank";

interface OnlyIconButtonProps {
  icon: React.ReactElement;
  className?: string;
  visualType?: VisualType;
  onClick?(): void;
}

const OnlyIconButton: FunctionComponent<OnlyIconButtonProps> = ({
  icon,
  className,
  visualType = "white",
  onClick,
}) => {
  return (
    <button
      className={classNames(
        styles["button"],
        styles[visualMap[visualType]],
        className
      )}
      onClick={onClick}
    >
      <div className={styles["button__icon"]}>{icon}</div>
    </button>
  );
};

export const visualMap: Record<VisualType, string> = {
  main: "button--color-main",
  white: "button--color-white",
  blank: "button--color-blank",
};

export default OnlyIconButton;
