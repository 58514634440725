import { clearUser } from "../../redux/slices/user/slice";
import { TypedUseAppDispatch } from "../../redux/hooks";
import { localStorageClearUserItems } from "../localStorageMethods";
import { NavigateParams, navigateIfPossible } from "../navigationUtils";

type LogoutFunction =
  | ((dispatch: TypedUseAppDispatch) => void)
  | ((dispatch: TypedUseAppDispatch, navigateParams?: NavigateParams) => void);

export const logout: LogoutFunction = (dispatch, navigateParams) => {
  localStorageClearUserItems();

  dispatch(clearUser());
  
  navigateIfPossible(navigateParams);
};