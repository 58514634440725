import { FunctionComponent, useEffect, useState } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import MainTemplate from '../../containers/templates/MainTemplate/MainTemplate';
import HeroSection from '../../components/sections/HeroSection/HeroSection';
import { ReactComponent as BuildIll } from '../../asset/images/build_ill.svg';

import SectionDivider from '../../components/sections/SectionDivider/SectionDivider';
import ChildrenWithHeaderSection from '../../components/sections/ChildrenWithHeaderSection/ChildrenWithHeaderSection';

import TestimonialCard from '../../components/blocks/TestimonialCard/TestimonialCard';
import SimpleTitle from '../../components/titles/SimpleTitle/SimpleTitle';
import Slider from '../../components/lists/Slider/Slider';
import CardsWithHeaderSection from '../../components/sections/CardsWithHeaderSection/CardsWithHeaderSection';
import MainButton from '../../components/buttons/MainButton/MainButton';
import PriceBillet from '../../components/blocks/PriceBillet/PriceBillet';
import { ReactComponent as BgElements } from '../../asset/images/bg_elements_2.svg';

import { ReactComponent as NoContractIcon } from '../../asset/images/icons/no_contract.svg';
import { ReactComponent as TimeIcon } from '../../asset/images/icons/time.svg';
import { ReactComponent as NoDepositIcon } from '../../asset/images/icons/no_deposit.svg';
import { ReactComponent as RocketIcon } from '../../asset/images/icons/rocket.svg';
import { ReactComponent as CalendarIcon } from '../../asset/images/icons/calendar_2.svg';
import { ReactComponent as SquaresIcon } from '../../asset/images/icons/squares.svg';

import TitleWithSecTitle from '../../components/titles/TitleWithSecTitle/TitleWithSecTitle';
import Grid from '../../components/blocks/Grid/Grid';
import TitleWithIconAndTextCard from '../../components/blocks/TitleWithIconAndTextCard/TitleWithIconAndTextCard';
import BuildFiltersWithServiceCards from '../../components/blocks/BuildFiltersWithServiceCards/BuildFiltersWithServiceCards';
import RoutesEnum from '../../enums/routes';
import ServiceCard from '../../components/blocks/ServiceCard/ServiceCard';
import { useAppSelector } from '../../redux/hooks';
import buildPageContent from '../../data/build';
import api from '../../api';
import { AxiosError } from 'axios';
import { ApiResponseDTO } from '../../dto/api';
import TestimonialsSection from '../../components/sections/TestimonialsSection/TestimonialsSection';
import MainTemplateForMainPages from '../../containers/templates/MainTemplate/MainTemplateForMainPages';

interface BuildViewProps extends IWithUserRoleProps {}

const BuildView: FunctionComponent<BuildViewProps> = ({ user_role, router }) => {
  const isUserLoggedIn = useAppSelector((state) => state.user.userLogged);

  return (
    <MainTemplateForMainPages>
      <HeroSection
        secondaryTitle="Build"
        title="Research, design, build, market, sell, fundraise"
        text={buildPageContent[user_role]}
        sectionVariation="var-1"
        illustration={<BuildIll style={{ maxHeight: '400px' }} />}
      />

      <SectionDivider color="Background-Neutral-Primary" />

      <CardsWithHeaderSection
        sectionVariation="var-1"
        sectionBackgroundColor="Background-Neutral-Primary"
        wrapperClass="type-1"
        title="Build your startup 🚀"
        text="Hire our startup specialists to design, build, launch, and scale your company."
        button={
          isUserLoggedIn === false ? (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${RoutesEnum.SIGN_UP}`);
              }}
            >
              Join Astronomic for free
            </MainButton>
          ) : (
            <MainButton
              sizeType={'large'}
              visualType="main"
              onClick={() => {
                router.navigate(`/${user_role}/${RoutesEnum.SCHEDULE_MEETING}`);
              }}
            >
              Schedule a meeting
            </MainButton>
          )
        }
        customElement={
          <PriceBillet
            price="$48-75"
            linkText="How hourly billing works"
            linkUrl={`/${user_role}/${RoutesEnum.PRICING}`}
          />
        }
      >
        <BuildFiltersWithServiceCards userRole={user_role} />
      </CardsWithHeaderSection>

      <SectionDivider color="blue" />

      <ChildrenWithHeaderSection
        title={
          <TitleWithSecTitle
            secTitle="Accessibility"
            title="“The 7 Eleven for Startups”"
            titleColor="var(--color-white)"
            secTitleColor="var(--color-cyan)"
          />
        }
        backgroundColor="blue"
        backgroundChildren={<BgElements style={{ position: 'absolute', width: '100%' }} />}
      >
        <Grid
          itemsInRow={2}
          itemGroup={[
            <TitleWithIconAndTextCard
              title="No long-term contracts"
              text="We use a repeating 7-day contract so that if you need to stop, you can stop on a dime without being locked into a long-term commitment."
              cardColor="white"
              icon={<NoContractIcon />}
            />,
            <TitleWithIconAndTextCard
              title="No retainers"
              text="It's lonely at the top, but not here. Share your challenges, goals, and questions with other founders, learn from their experiences, and develop rewarding friendships in the process."
              cardColor="white"
              icon={<TimeIcon />}
            />,
            <TitleWithIconAndTextCard
              title="No deposits"
              text="As long as you agree to our terms, we’ll get started working without you needing to put any money down. "
              cardColor="white"
              icon={<NoDepositIcon />}
            />,
            <TitleWithIconAndTextCard
              title="No lead times"
              text="Need to get started ASAP? You got it. We can assemble a team and start your work within 24 hours."
              cardColor="white"
              icon={<RocketIcon fill="var(--color-main)" style={{ width: '24px' }} />}
            />,
            <TitleWithIconAndTextCard
              title="Weekly accountability"
              text="Every Monday, we’ll tell you our plan for the week, how many hours we’ll spend, and which priorities we’ll tackle. Every Friday, we’ll report all hours worked by each team member."
              cardColor="white"
              icon={<CalendarIcon />}
            />,
            <TitleWithIconAndTextCard
              title="Modern tools"
              text="We use Slack for client account management, Google Drive for file storage, Figma for design, Bitbucket for version control, Jira for task management, and Confluence for documentation."
              cardColor="white"
              icon={<SquaresIcon />}
            />,
          ]}
        />
      </ChildrenWithHeaderSection>

      {/* <SectionDivider color="gray" /> */}

      {/* <TestimonialsSection backgroundColor="gray" /> */}
    </MainTemplateForMainPages>
  );
};

export default with_user_role(BuildView);
