import { SESSION_STORAGE_KEYS } from "../enums/sessionStorageKeys";

export const sessionStorageGetItem = (key: SESSION_STORAGE_KEYS): string | null => {
  return sessionStorage.getItem(key);
};

export const sessionStorageSetItem = (key: SESSION_STORAGE_KEYS, value: string | null): void => {
  if (value) {
    sessionStorage.setItem(key, value);
  }
};

export const sessionStorageRemoveItem = (key: SESSION_STORAGE_KEYS): void => {
  if (sessionStorageGetItem(key)) {
    sessionStorage.removeItem(key);
  } else {
    // console.error((`There is no ${key} in localStorage`));
  }
};