import { FunctionComponent } from 'react';
import with_user_role, { IWithUserRoleProps } from '../../containers/hocs/main/WithUserRole';
import styles from './TestView.module.scss';
import ThreeSceneFiber from './_parts/ThreeSceneFiber';

interface TestViewProps extends IWithUserRoleProps {}

const TestView: FunctionComponent<TestViewProps> = () => {
  return (
    <>
      <section className={styles['section']}>
        <span style={{ position: 'absolute' }}>test</span>
        <ThreeSceneFiber />
      </section>
    </>
  );
};

export default with_user_role(TestView);
