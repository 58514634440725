import { FunctionComponent, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./SimpleTitle.module.scss";

interface SimpleTitleProps extends PropsWithChildren {}

const SimpleTitle: FunctionComponent<SimpleTitleProps> = ({ children }) => {
  return <div className={classNames(styles["simple-title"])}>{children}</div>;
};

export default SimpleTitle;
