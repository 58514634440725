import { FunctionComponent } from 'react';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import { ReactComponent as LayersIcon } from '../../../../../asset/images/icons/layers.svg';
import styles from './AccountApps.module.scss';
import ComingSoonMessage from '../../../../../components/texts/ComingSoonMessage/ComingSoonMessage';

interface AccountSubscriptionProps {}

const AccountApps: FunctionComponent<AccountSubscriptionProps> = () => {
  return (
    <div className={styles['apps']}>
      <TitleWithIcon
        className={styles['title']}
        icon={<LayersIcon style={{ fill: 'var(--color-main)', width: '29px' }} />}
      >
        <h3 className={'text text--h3'}>Apps</h3>
      </TitleWithIcon>

      <div className="bottom-part">
        <ComingSoonMessage />
      </div>
    </div>
  );
};

export default AccountApps;
