import { FunctionComponent } from 'react';
import styles from './PreloadLine.module.scss';
import classNames from 'classnames';

interface PreloadLineProps {
  type?: 'default' | 'white';
}

const PreloadLine: FunctionComponent<PreloadLineProps> = ({ type }) => {
  return <div className={classNames(styles['element'], styles[`element--type-${type}`])}></div>;
};

export default PreloadLine;
