import React from 'react';
import withRouter, { IWithRouterProps } from '../basic/withRouter';
import { is_user_role } from '../../../type_guards/user_role';
import { UserRoleEnum } from '../../../../src/enums/user';
import NotFoundView from '../../../views/NotFoundView/NotFoundView';

export type IWithUserRoleProps = {
  user_role: UserRoleEnum;
} & WithUserRoleProps;

interface WithUserRoleProps extends IWithRouterProps {}

interface WithUserRoleState {}

const with_user_role = (WrappedComponent: any) => {
  class WithUserRole extends React.Component<WithUserRoleProps, WithUserRoleState> {
    constructor(props: WithUserRoleProps) {
      super(props);
      this.state = {};
    }

    render() {
      const role = this.props.router.params.role;
      const userRoleDefined = is_user_role(role);
      return userRoleDefined ? <WrappedComponent {...this.props} user_role={role} /> : <NotFoundView />;
    }
  }

  return withRouter(WithUserRole);
};

export default with_user_role;
