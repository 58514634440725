import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserDTO } from '../../../dto/user';
import api from '../../../api';
import { ApiResponseDTO } from '../../../dto/api';

export const getUserInfoAsyncThunk = createAsyncThunk<IUserDTO, string, { rejectValue: Error }>(
  'user/getUser',
  async (userId, thunkAPI) => {
    try {
      const response = await api.Auth.getUserInfo(userId);
      const responseData = response.data as ApiResponseDTO<IUserDTO>;

      if (responseData.success === true && responseData.data) {
        return responseData.data;
      } else {
        throw new Error('User data is undefined or null');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error as Error);
    }
  }
);
