import axios, { AxiosInstance } from 'axios';
import configuration from '../config';
import AuthApi from './_parts/auth';
import PaymentApi from './_parts/payment';
import {
  localStorageClearUserItems,
  localStorageGetItem,
  localStorageGetUserAuthItems,
  localStorageSetItem,
} from '../utils/localStorageMethods';
import { LOCAL_STORAGE_KEYS } from '../enums/localStorageKeys';
import { isTokenValid } from '../utils/tokenValidation';
import RoutesEnum from '../enums/routes';
import { ApiResponseDTO } from '../dto/api';
import OnboardingApi from './_parts/onboarding';
import ContentApi from './_parts/content';

const backend = configuration.APP.BACKEND;

class API {
  private axios_instance: AxiosInstance;
  private isRefreshingToken: boolean = false;

  Auth: AuthApi;
  Payment: PaymentApi;
  Onboarding: OnboardingApi;
  Content: ContentApi;

  constructor() {
    this.axios_instance = axios.create({ baseURL: `${backend}` });

    this.axios_instance.interceptors.request.use(
      async (config) => {
        const at = localStorageGetItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

        if (at && isTokenValid(at)) {
          config.headers.Authorization = `Bearer ${at}`;
        } else {
          await this.handleRefreshToken(config, false);
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    this.axios_instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401) {
          this.isRefreshingToken = false;
          await this.handleRefreshToken(originalRequest, true);
        }

        return Promise.reject(error);
      }
    );

    this.Auth = new AuthApi(this.axios_instance, backend);
    this.Payment = new PaymentApi(this.axios_instance, backend);
    this.Onboarding = new OnboardingApi(this.axios_instance, backend);
    this.Content = new ContentApi(this.axios_instance, backend);
  }

  private async handleRefreshToken(originalRequest, originalRequestResendIsNeeded) {
    // Check if token refresh is already in progress
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      const { rt, userId } = localStorageGetUserAuthItems();

      if (userId && rt) {
        try {
          const response = await this.Auth.refreshToken({ userId: userId, refreshToken: rt });

          const responseData = response.data as ApiResponseDTO<string>;

          if (responseData.data && responseData.success === true) {
            const at = responseData.data;
            localStorageSetItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, at);
            originalRequest.headers.Authorization = `Bearer ${at}`;

            // Resend the original request with the new access token
            if (originalRequestResendIsNeeded) {
              return this.axios_instance(originalRequest);
            }
          } else {
            // redirect logic
            this.refreshAndRedirectToLoginConditionally();
          }
        } catch (_error) {
          // redirect logic
          this.refreshAndRedirectToLoginConditionally();

          return Promise.reject(_error);
        } finally {
          this.isRefreshingToken = false;
        }
      } else {
        // redirect logic
        this.refreshAndRedirectToLoginConditionally();
      }
    }

    // If token refresh is already in progress, return the original request as is
    return Promise.resolve(originalRequest);
  }

  refreshAndRedirectToLoginConditionally() {
    const { at, rt, userId } = localStorageGetUserAuthItems();

    if (at || rt || userId) {
      localStorageClearUserItems();
      window.location.href = `/${RoutesEnum.SIGN_IN}`;
    }
  }
}

const api = new API();

export default api;
