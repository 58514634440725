import { FunctionComponent } from 'react';
import styles from './GroupWithGap.module.scss';

interface GroupWithGapProps {
  items: React.ReactElement[];
  className?: string;
}

const GroupWithGap: FunctionComponent<GroupWithGapProps> = ({ items, className }) => {
  return (
    <div className={styles['list']}>
      {items.map((item, i) => (
        <div className={styles['list__item']} key={i}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default GroupWithGap;
