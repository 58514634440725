import { FunctionComponent } from 'react';
import { ReactComponent as ErrorIcon } from '../../../asset/images/icons/alert_close.svg';
import { ReactComponent as WarningIcon } from '../../../asset/images/icons/alert.svg';
import { ReactComponent as SuccessIcon } from '../../../asset/images/icons/alert_success.svg';
import { ReactComponent as InfoIcon } from '../../../asset/images/icons/info.svg';
import { ReactComponent as CloseIcon } from '../../../asset/images/icons/cross.svg';
import classNames from 'classnames';
import styles from './ToastMessage.module.scss';
import ToastifyContent from './_parts/ToastifyContent/ToastifyContent';

export interface ToastMessageBaseProps extends React.PropsWithChildren {
  variation?: 'error' | 'warning' | 'success' | 'info' | string;
  title?: string;
  actionText?: string;
  action?: () => void;
}

interface ToastMessageProps extends ToastMessageBaseProps {
  visualStyle?: 'default' | '1';
  customIcon?: React.ReactElement;
  className?: string;
  onClose?: () => void;
}

const ToastMessage: FunctionComponent<ToastMessageProps> = ({
  variation = 'error',
  visualStyle = 'default',
  customIcon,
  title,
  actionText = 'Action',
  children,
  className,
  action,
  onClose,
}) => {
  const IconComponent: React.ComponentType<any> = customIcon ? () => customIcon : iconMap[variation];

  return (
    <div
      className={classNames(
        styles['toast'],
        styles[`toast--var-${variation}`],
        styles[`toast--var-${visualStyle}`],
        className
      )}
    >
      <div className={styles['toast__left']}>
        <div className={styles['toast__icon']}>{IconComponent && <IconComponent />}</div>
      </div>

      <div className={styles['toast__right']}>
        <ToastifyContent action={action} actionText={actionText} title={title}>
          {children}
        </ToastifyContent>
      </div>

      {onClose && (
        <button className={styles['toast__close']} onClick={onClose}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export const iconMap: Record<'alert' | 'caution' | 'success' | 'info', React.ComponentType<any>> = {
  alert: ErrorIcon,
  caution: WarningIcon,
  success: SuccessIcon,
  info: InfoIcon,
};

export default ToastMessage;
