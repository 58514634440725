import { FunctionComponent, useState, useEffect } from 'react';
import classNames from 'classnames';
import TitleWithIcon from '../../../../../components/titles/TitleWithIcon/TitleWithIcon';
import { ReactComponent as CreditCardIcon } from '../../../../../asset/images/icons/credit_card.svg';
import { ReactComponent as EditIcon } from '../../../../../asset/images/icons/edit.svg';
import { ReactComponent as TimeIcon } from '../../../../../asset/images/icons/time_2.svg';
import { ReactComponent as VisaIcon } from '../../../../../asset/images/icons/visa.svg';
import { ReactComponent as SortForTableIcon } from '../../../../../asset/images/icons/sort_for_table.svg';
import { ReactComponent as AlertIcon } from '../../../../../asset/images/icons/alert.svg';
import { ReactComponent as RecentTransactionsBg } from '../../../../../asset/images/recent_transactions_bg.svg';
import { ReactComponent as ArrowIcon } from '../../../../../asset/images/button_arrow.svg';
import MainButton from '../../../../../components/buttons/MainButton/MainButton';
import HorizontalCard from '../../../../../components/blocks/HorizontalCard/HorizontalCard';
import ToggleSwitch from '../../../../../components/inputs/ToggleSwitch/ToggleSwitch';
import styles from './AccountBilling.module.scss';
import CreditCardForm from '../../../../../components/forms/CreditCardForm/CreditCardForm';
import CreditCardInfo from '../../../../../components/blocks/CreditCardInfo/CreditCardInfo';
import { loadStripe } from '@stripe/stripe-js';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import configuration from '../../../../../config';
import { Elements } from '@stripe/react-stripe-js';
import { useAppSelector } from '../../../../../redux/hooks';
import api from '../../../../../api';
import { ClientTransactionsDTO, DefaultPaymentMethodDTO } from '../../../../../dto/payment';
import { isAxiosError } from 'axios';
import { formatDate } from '../../../../../utils/date';
import RoutesEnum from '../../../../../enums/routes';
import { useNavigate } from 'react-router-dom';
import ComingSoonMessage from '../../../../../components/texts/ComingSoonMessage/ComingSoonMessage';
import ShowOnlyInSomeEnv from '../../../../../components/helpers/ShowOnlyInSomeEnv/ShowOnlyInSomeEnv';
import NavigationLink from '../../../../../components/links/NavigationLink/NavigationLink';
import CreditCardInfoSkeleton from '../../../../../components/blocks/CreditCardInfo/CreditCardInfoSkeleton';
import PreloadLine from '../../../../../components/blocks/PreloadLine/PreloadLine';
import CSSTransitionWrapper from '../../../../wrappers/CSSTransitionWrapper/CSSTransitionWrapper';
import AstronomicLoader from '../../../../../components/blocks/AstronomicLoader/AstronomicLoader';

const stripePromise = loadStripe(configuration.APP.STRIPE_PUBLIC_KEY);

interface AccountBillingProps {}

// type Transactions = {
//   date: string;
//   description: string;
//   charge: number;
//   boostedAccountCharge: number;
//   discount: number;
//   paymentFailed: boolean;
// };

// const defaultData: ClientTransactionsDTO[] = [
//   {
//     transactionDate: '-//-',
//     description: '-//-',
//     amountInCents: 0,
//     amountInDollars: 0,
//     boostedAccountCharge: 0,
//     discount: 0,
//     paymentFailed: false,
//     currency: '$',
//   },
// ];

const columnHelper = createColumnHelper<ClientTransactionsDTO>();

const DEMO_DISCOUNT = 0.25;

const columns = [
  columnHelper.accessor('transactionDate', {
    header: () => 'Date',
    cell: (info) => formatDate(info.getValue(), 'mmm dd, yyyy'),
  }),
  columnHelper.accessor('description', {
    header: () => 'Description',
    // cell: (info) => `${info.getValue()}`,
    cell: (info) => `Rocket Fuel Purchase`,
  }),
  columnHelper.accessor('amountInDollars', {
    header: () => 'Charge',
    cell: (info) => `$${info.getValue().toLocaleString('en-US')}`,
    // ${
    //   info.table.options.data[info.row.id].paymentFailed
    // }
  }),
  columnHelper.accessor('boostedAccountCharge', {
    header: () => 'Boosted Account Charge',
    cell: (info) => {
      const value = info.row.original.amountInDollars * (1 - DEMO_DISCOUNT);
      // return `$${info.getValue() ? info.getValue()!.toLocaleString('en-US') : '0'}`;
      return `$${value.toLocaleString('en-US')}`;
    },
  }),
  columnHelper.accessor('discount', {
    header: () => 'Discount',
    cell: (info) => {
      const value = info.row.original.amountInDollars * DEMO_DISCOUNT;
      return `$${value.toLocaleString('en-US')}`;
      // return `$${info.getValue() ? info.getValue()!.toLocaleString('en-US') : '0'}`;
    },
  }),
];

const AccountBilling: FunctionComponent<AccountBillingProps> = () => {
  const [billingFilled, setBillingFilled] = useState(false);
  const [creditCardInfo, setCreditCardInfo] = useState<DefaultPaymentMethodDTO | null>();
  const [simulateSavings, setSimulateSavings] = useState(false);

  const [transactionsData, setTransactionsData] = useState<ClientTransactionsDTO[]>(() => []);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const stripeCustomerId = useAppSelector((state) => state.user.user?.stripeCustomerId);

  console.log('Transaction data: ', transactionsData);

  const totalAmountInDollars = transactionsData?.reduce(
    (sum, transaction) => sum + (transaction.amountInDollars || 0),
    0
  );

  const potentialSavings = totalAmountInDollars * DEMO_DISCOUNT;

  const navigate = useNavigate();

  const handleOnSaveCreditCardHandler = () => {
    setBillingFilled(true);
    setCreditCardInfo(null);
    handleGetDefaultPaymentMethod();
  };

  const handleOnEditCreditCardHandler = () => {
    setBillingFilled(false);
  };

  const handleGetDefaultPaymentMethod = async () => {
    if (stripeCustomerId) {
      try {
        const response = await api.Payment.getDefaultPaymentMethod(stripeCustomerId);
        const responseData = response.data;

        if (responseData.success === true && responseData) {
          setCreditCardInfo(responseData.data);
        } else {
          console.error(responseData.errors);
        }
      } catch (error) {
        if (isAxiosError(error)) {
          console.error(error.response?.data);
        } else {
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    if (stripeCustomerId) {
      setBillingFilled(true);
    }

    handleGetDefaultPaymentMethod();
  }, [stripeCustomerId]);

  const table = useReactTable({
    data: transactionsData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // console.log(table.options.data);

  const handleOnSimulateSavingsChange = (state) => {
    setSimulateSavings(state);

    table.setColumnVisibility({
      boostedAccountCharge: state,
      discount: state,
    });
  };

  useEffect(() => {
    // TEST
    // console.log(table.options.data[0].paymentFailed);
    // console.log(table.options.columns[3].id);
    table.getCoreRowModel().rows.forEach((row) => {
      const paymentFailed = table.options.data[row.id];
      // console.log(table.options.data);
    });
    // TEST END

    table.setColumnVisibility({
      boostedAccountCharge: false,
      discount: false,
    });

    const handleGetClientTransactions = async () => {
      if (stripeCustomerId) {
        try {
          const response = await api.Payment.getClientTransactions(stripeCustomerId);
          const responseData = response.data;

          if (responseData.success === true && responseData.data) {
            setTransactionsData(responseData.data);
          } else {
            console.error(responseData.errors);
          }
        } catch (error) {
          if (isAxiosError(error)) {
            console.error(error.response?.data);
          } else {
            console.error(error);
          }
        }
      }
    };

    handleGetClientTransactions();
  }, []);

  return (
    <div className={styles['billing']}>
      {stripeCustomerId && billingFilled === false && (
        <NavigationLink
          className={styles['billing__back-link']}
          path=""
          iconLeft={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
          withBorder={false}
          onClick={handleOnSaveCreditCardHandler}
        >
          Back
        </NavigationLink>
      )}
      <div className={styles['card-section']}>
        <div className={styles['title-container']}>
          <TitleWithIcon
            className={styles['title-container__text']}
            icon={<CreditCardIcon style={{ fill: 'var(--color-main)', width: '26px' }} />}
          >
            <h3 className={'text text--h3'}>Billing</h3>
          </TitleWithIcon>

          {billingFilled === true && (
            <MainButton
              sizeType="medium"
              visualType="white"
              iconLeft={<EditIcon style={{ fill: 'var(--color-main)', width: '13px' }} />}
              onClick={handleOnEditCreditCardHandler}
            >
              Edit
            </MainButton>
          )}
        </div>

        <p className={classNames(styles['text'], 'text text--body-1')}>
          We would like to emphasize that <b>we do not charge anything without the explicit consent of our clients</b>.
          This means that you have complete control over the amount you spend on our services.
        </p>

        {billingFilled === false ? (
          <>
            <div className={styles['card-section__form']}>
              <Elements
                stripe={stripePromise}
                options={{
                  appearance: {
                    rules: {
                      '.Input': {
                        border: '1px solid red',
                      },
                    },
                  },
                }}
              >
                <CreditCardForm saveCreditCard={handleOnSaveCreditCardHandler} />
              </Elements>
            </div>

            <ShowOnlyInSomeEnv>
              <MainButton
                className={styles['card-section__submit']}
                sizeType="large"
                visualType="main"
                onClick={handleOnSaveCreditCardHandler}
              >
                Change billing view state
              </MainButton>
            </ShowOnlyInSomeEnv>
          </>
        ) : creditCardInfo ? (
          <div className={styles['card-section__filled-data']}>
            <CreditCardInfo
              name={`${creditCardInfo?.name}`}
              cardNumber={`•••• •••• •••• ${creditCardInfo?.last4}`}
              expiry={`${creditCardInfo?.expirationMonth}/${creditCardInfo?.expirationYear}`}
              creditCardCompanyIcon={
                creditCardInfo.brand === 'visa' ? (
                  <VisaIcon />
                ) : (
                  <>
                    <b>*Brand icon*</b>
                  </>
                )
              }
            />
          </div>
        ) : (
          <div className={styles['card-section__filled-data']}>
            <CreditCardInfoSkeleton />
          </div>
        )}
      </div>

      {billingFilled === true && (
        <div className={styles['recent-transactions']}>
          <div className={styles['title-container']}>
            <TitleWithIcon className={styles['title-container__text']} icon={<TimeIcon />}>
              <h3 className={'text text--h3'}>Recent Transactions</h3>
            </TitleWithIcon>
          </div>

          <div className={styles['recent-transactions__simulation']}>
            <HorizontalCard
              className={styles['recent-transactions__simulation__card']}
              textColor="var(--color-white)"
              backgroundColor="var(--Gradient-Orange, linear-gradient(90deg, #EA6B3D 0.15%, #C3351D 99.85%))"
              backgroundIllustration={
                <RecentTransactionsBg className={styles['recent-transactions__simulation__bg-el']} />
              }
              footer={
                <div className={styles['recent-transactions__simulation__footer']}>
                  <div className={styles['recent-transactions__simulation__footer__left']}>
                    <div className={styles['recent-transactions__simulation__switch-toggle-container']}>
                      <ToggleSwitch
                        className={styles['recent-transactions__simulation__switch-toggle-container__input']}
                        size="small"
                        color="dark-orange"
                        checked={simulateSavings}
                        onChange={handleOnSimulateSavingsChange}
                      />

                      <p
                        className={classNames(
                          styles['recent-transactions__simulation__switch-toggle-container__label'],
                          'text text--caption'
                        )}
                      >
                        Simulate savings
                      </p>
                    </div>
                  </div>

                  <div className={styles['recent-transactions__simulation__footer__right']}>
                    <div className={styles['recent-transactions__simulation__footer__button-list']}>
                      <MainButton
                        visualType="blank"
                        sizeType="medium"
                        onClick={() => navigate(`/founder/${RoutesEnum.PRICING}`)}
                      >
                        Learn more
                      </MainButton>
                    </div>
                  </div>
                </div>
              }
            >
              <p className={classNames(styles['recent-transactions__simulation__title'], 'text text--h3')}>
                You could have saved ${potentialSavings}
              </p>

              <p className={classNames(styles['recent-transactions__simulation__text'], 'text text--body-2')}>
                Prepaying for Rocket Fuel or granting equity to Astronomic can result in substantial savings.
              </p>
            </HorizontalCard>

            {/* <ComingSoonMessage type="overlay" /> */}
          </div>

          <div className={styles['recent-transactions__table']}>
            <div className={styles['table-container']}>
              <table className={styles['table']}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th className="text text--body-2 text--bold" key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <div
                                className={classNames(
                                  styles['table__th-inner'],
                                  header.column.getCanSort() ? styles['table__th-inner--can-sort'] : ''
                                )}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}

                                <SortForTableIcon
                                  className={classNames(
                                    styles['table__sort-icon'],
                                    header.column.getIsSorted() === 'asc' && styles['table__sort-icon--asc'],
                                    header.column.getIsSorted() === 'desc' && styles['table__sort-icon--desc']
                                  )}
                                />
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          className={classNames(
                            styles['table__cell'],
                            styles[`table__cell--alert-state-${table.options.data[row.id].paymentFailed}`],
                            'text text--body-2'
                          )}
                          key={cell.id}
                        >
                          {table.options.data[row.id].paymentFailed && (cell.column.id === 'charge') === true && (
                            <AlertIcon
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'center',
                                marginRight: '8px',
                                position: 'relative',
                                top: '2px',
                              }}
                            />
                          )}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              {transactionsData ? <></> : <AstronomicLoader />}

              {transactionsData?.length <= 0 && (
                <p style={{ textAlign: 'center', padding: '40px 24px' }}>There are no recent transactions</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountBilling;
