import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import styles from './CSSTransitionWrapper.module.scss';

interface CSSTransitionWrapperProps extends PropsWithChildren {
  onEnter: boolean;
  customTransitionTimeout?: number;
  styleVariation?: 'default' | 'onForeground';
  className?: string;
}

const transitionTimeout = 300;

const CSSTransitionWrapper: FunctionComponent<CSSTransitionWrapperProps> = ({
  onEnter,
  customTransitionTimeout,
  styleVariation = 'default',
  className,
  children,
}) => {
  return (
    <CSSTransition
      in={onEnter}
      timeout={customTransitionTimeout ? customTransitionTimeout : transitionTimeout}
      classNames={{
        enter: styles['container--anim-on-enter'],
        enterActive: styles['container--anim-on-enter-active'],
        enterDone: styles['container--anim-on-enter-done'],
        exit: styles['container--anim-on-exit'],
      }}
      unmountOnExit
    >
      <div className={classNames(styles['container'], styles[`container--style-${styleVariation}`], className)}>
        {children}
      </div>
    </CSSTransition>
  );
};

export default CSSTransitionWrapper;
